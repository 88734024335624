import React, { useState, useRef } from "react";
import { PlayIcon, PauseIcon, ArrowDownTrayIcon } from "@heroicons/react/24/solid";

interface AudioPlayerProps {
	recordingUrl: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ recordingUrl }) => {
	const [isPlaying, setIsPlaying] = useState(false);
	const [progress, setProgress] = useState(0);
	const audioRef = useRef<HTMLAudioElement | null>(null);
	const progressBarRef = useRef<HTMLDivElement>(null);

	const togglePlay = () => {
		if (audioRef.current) {
			if (isPlaying) {
				audioRef.current.pause();
				setIsPlaying(false);
			} else {
				audioRef.current.play();
				setIsPlaying(true);
			}
		}
	};

	const handleTimeUpdate = () => {
		if (audioRef.current) {
			const percentage = (audioRef.current.currentTime / audioRef.current.duration) * 100;
			setProgress(percentage);
		}
	};

	const handleDownload = () => {
		if (recordingUrl) {
			window.open(recordingUrl, "_blank");
		}
	};

	const handleProgressBarClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if (audioRef.current && progressBarRef.current) {
			const rect = progressBarRef.current.getBoundingClientRect();
			const clickPosition = (event.clientX - rect.left) / rect.width;
			const newTime = clickPosition * audioRef.current.duration;
			audioRef.current.currentTime = newTime;
			setProgress(clickPosition * 100);
		}
	};

	return (
		<div className="flex items-center space-x-2">
			<button
				onClick={togglePlay}
				className="p-2 rounded-full bg-[#00a9af] text-white hover:bg-[#008c91] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00a9af]"
			>
				{isPlaying ? <PauseIcon className="h-5 w-5" /> : <PlayIcon className="h-5 w-5" />}
			</button>
			<div ref={progressBarRef} className="w-48 h-2 bg-gray-200 rounded-full cursor-pointer" onClick={handleProgressBarClick}>
				<div className="h-full bg-[#00a9af] rounded-full" style={{ width: `${progress}%` }}></div>
			</div>
			<button
				onClick={handleDownload}
				className="p-2 rounded-full bg-gray-100 text-gray-600 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
			>
				<ArrowDownTrayIcon className="h-5 w-5" />
			</button>
			<audio
				ref={audioRef}
				src={recordingUrl}
				onTimeUpdate={handleTimeUpdate}
				onEnded={() => setIsPlaying(false)}
				style={{ display: "none" }}
			/>
		</div>
	);
};

export default AudioPlayer;
