import React, { useState, useEffect, useCallback } from "react";
import { useAppContext } from "../../contexts/AppContext";
import { useParams } from "react-router-dom";
import { StopIcon } from "@heroicons/react/24/solid";
import { AlertCircle, Mic, RefreshCw } from "lucide-react";
import AudioVisualizer from "../Utility/AudioVisualizer";
import VapiMicrophoneVisualizer from "../Utility/VapiMicrophoneVisualizer";
import { SessionService } from "../../services/SessionService";
import { SessionView } from "../../types/SessionView";
import { Button } from "../../components/shadcn-ui/Button";
import { NavigateService } from "../../services/NavigateService";
import Spinner from "../Utility/Spinner";
import posthog from "posthog-js";

const Activity: React.FC = () => {
	const { vapi, on, off, startCall, stopCall, isCallActive, toggleMute, isInitialSpeech, assistantVolume, callStatus, status, userId } =
		useAppContext();
	const { sessionId } = useParams();
	const [currentSession, setCurrentSession] = useState<SessionView | null>(null);
	const [isPauseModalVisible, setIsPauseModalVisible] = useState(false);
	const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
	const [micPermission, setMicPermission] = useState<"granted" | "denied" | "prompt">("prompt");

	const checkMicrophonePermission = useCallback(async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			// If we get here, permission was granted
			stream.getTracks().forEach((track) => track.stop()); // Clean up
			setMicPermission("granted");
			return true;
		} catch (err) {
			console.error("Microphone permission error:", err);
			// Check if permission was denied
			const permission = await navigator.permissions.query({ name: "microphone" as PermissionName });
			setMicPermission(permission.state as "granted" | "denied" | "prompt");

			// Set up permission change listener
			permission.addEventListener("change", (e) => {
				setMicPermission((e.target as PermissionStatus).state as "granted" | "denied" | "prompt");

				try {
					posthog.capture(`MINOR: Microphone Permission Changed to ${permission.state}`, {
						userId,
						timestamp: new Date().toISOString(),
						permission: permission.state
					});
				} catch (err) {
					console.error("Error capturing event 'MINOR: Microphone Permission Changed':", err);
				}
			});

			return false;
		}
	}, []);

	const handleStartCall = useCallback(
		async (session: SessionView) => {
			if (!sessionId || !vapi || !userId || status !== "connected") return;

			const hasMicAccess = await checkMicrophonePermission();
			if (!hasMicAccess) {
				return;
			}

			startCall({
				sessionId,
				session
			}).then(() => {
				// Additional logic if needed
			});
		},
		[sessionId, startCall, vapi, status, userId, checkMicrophonePermission]
	);

	useEffect(() => {
		checkMicrophonePermission();
	}, [checkMicrophonePermission]);

	useEffect(() => {
		if (!sessionId || micPermission !== "granted") return;

		SessionService.getSession(sessionId).then((session) => {
			if (session) {
				setCurrentSession(session);
				handleStartCall(session);
			}
		});
	}, [handleStartCall, sessionId, micPermission]);

	useEffect(() => {
		if (callStatus === "ended") {
			setIsGeneratingSummary(true);
		}
	}, [callStatus]);

	useEffect(() => {
		const handleSummaryGenerated = (data: { assignmentId: string; scheduleId: string }) => {
			if (currentSession?.activityType === "onboarding") {
				NavigateService.navToUser();
			} else if (data.assignmentId && userId && data.scheduleId) {
				NavigateService.navToUserAssignment(data.assignmentId, userId, data.scheduleId);
			}
		};

		on("summaryGenerated", handleSummaryGenerated);

		return () => {
			off("summaryGenerated", handleSummaryGenerated);
		};
	}, [on, off, currentSession, userId]);

	const handlePause = () => {
		toggleMute();
		setIsPauseModalVisible(true);
	};

	const handleResume = () => {
		toggleMute();
		setIsPauseModalVisible(false);
	};

	const handleEndCallFromPause = () => {
		setIsPauseModalVisible(false);
		stopCall();
	};

	const MicrophonePermission = ({
		micPermission,
		onCheckPermission,
		className = ""
	}: {
		micPermission: "granted" | "denied" | "prompt";
		onCheckPermission: () => void;
		className?: string;
	}) => {
		const isDenied = micPermission === "denied";

		return (
			<div className="flex flex-col items-center justify-center w-full max-w-md mx-auto">
				<div className="w-full rounded-lg border bg-card text-card-foreground p-4">
					<div className="flex items-center gap-3 mb-3">
						<AlertCircle className="h-5 w-5 text-gray-700 flex-shrink-0" />
						<h2 className="text-lg font-semibold text-gray-900">{isDenied ? "Microphone Access Needed" : "Enable Your Microphone"}</h2>
					</div>
					<p className="text-gray-600">
						{isDenied
							? "This app needs microphone access to work properly. Please enable it in your browser settings."
							: "Please allow microphone access when your browser shows the permission prompt."}
					</p>
					{isDenied ? (
						<div className="space-y-6 w-full">
							<div className="mt-6">
								<h3 className="font-medium mb-3">To enable your microphone:</h3>
								<ol className="space-y-2 text-sm text-gray-600">
									<li className="flex items-start">
										<span className="font-medium mr-2">1.</span>
										Check your browser settings/permissions
									</li>
									<li className="flex items-start">
										<span className="font-medium mr-2">2.</span>
										Select "Allow" for microphone access
									</li>
									<li className="flex items-start">
										<span className="font-medium mr-2">3.</span>
										Click the Try Again button below
									</li>
								</ol>
							</div>

							<Button onClick={() => window.location.reload()} className="w-full" size="lg">
								<RefreshCw className="h-4 w-4 mr-2" />
								Try Again
							</Button>
						</div>
					) : (
						<Button onClick={onCheckPermission} className="w-full mt-6" size="lg">
							<Mic className="h-4 w-4 mr-2" />
							Enable Microphone
						</Button>
					)}
				</div>
			</div>
		);
	};

	if (micPermission !== "granted") {
		return <MicrophonePermission micPermission={micPermission} onCheckPermission={checkMicrophonePermission} />;
	}

	if (isInitialSpeech) {
		return (
			<div className="flex items-center justify-center h-full min-h-[calc(100vh-4rem-4rem)]">
				<div className="relative w-80 h-80">
					{/* Expanding rings */}
					{[...Array(3)].map((_, index) => (
						<div
							key={index}
							className="absolute inset-0 rounded-full border border-[#00a9af] animate-ripple-fade"
							style={{
								animationDelay: `${index * 1333}ms`
							}}
						/>
					))}

					{/* Center text */}
					<div className="absolute inset-0 flex items-center justify-center">
						<span className="text-xl font-bold text-[#00a9af] whitespace-nowrap">Connecting to your guide...</span>
					</div>
				</div>
			</div>
		);
	}

	if (isGeneratingSummary) {
		return (
			<div className="flex flex-col items-center justify-center h-full min-h-[calc(100vh-4rem-4rem)]">
				<Spinner />
				<span className="mt-4 text-xl font-bold text-[#00a9af]">Generating summary...</span>
			</div>
		);
	}

	return (
		<div className="h-full">
			{/* Main content */}
			<div className="flex-grow overflow-y-auto">
				<div className="w-full max-w-3xl mx-auto flex flex-col gap-20 p-4">
					<h2 className="text-2xl font-semibold text-gray-800 text-center">
						{`Live: ${
							currentSession?.scheduleDescription
								? `"${currentSession.scheduleDescription}"`
								: currentSession?.activityType === "onboarding"
								? "Onboarding Session"
								: "Reflection Session"
						}`}
					</h2>
					{isCallActive && (
						<div className="h-48">
							<VapiMicrophoneVisualizer />
						</div>
					)}
					<AudioVisualizer isVapiActive={isCallActive} assistantVolume={assistantVolume} />
				</div>
			</div>

			{/* Footer */}
			<div className="fixed bottom-0 left-0 right-0 border-t border-gray-200 bg-[#f6f3ed]">
				<div className="container max-w-7xl mx-auto px-4 py-6">
					<div className="flex justify-center items-center">
						{isCallActive && (
							<Button onClick={handlePause} className="h-12 w-12 rounded-full bg-[#262626] flex items-center justify-center">
								<StopIcon className="h-6 w-6 text-white" />
							</Button>
						)}
					</div>
				</div>
			</div>

			{/* Pause Modal */}
			{isPauseModalVisible && (
				<div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center">
					<div className="bg-white rounded-lg p-6 w-11/12 max-w-md">
						<h2 className="text-2xl font-semibold text-center mb-6">Activity Paused</h2>
						<div className="flex gap-4 justify-between">
							<Button className="flex-1" size="lg" onClick={handleResume}>
								Resume
							</Button>
							<Button className="flex-1" size="lg" variant="warning" onClick={handleEndCallFromPause}>
								End Call
							</Button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Activity;
