import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import BasicInformation from "./Steps/BasicInformation";
import ReflectionDates from "./Steps/ReflectionDates";
import AssignmentDistribution from "./Steps/AssignmentDistribution";
import AdminSummaryOptions from "./Steps/AdminSummaryOptions";
import ReviewAssignment from "./Steps/ReviewAssignment";
import StepNavigation from "./StepNavigation";
import { FormProvider, useFormContext } from "./FormContext";
import { AssignmentPayload } from "../../../types/AssignmentPayload";
import { AssignmentService } from "../../../services/AssignmentService";
import { AdminAssignmentView } from "../../../types/AdminAssignmentView";
import { NavigateService } from "../../../services/NavigateService";
import { DocumentTextIcon, ClockIcon, UserGroupIcon, CogIcon, ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import BackButton from "../../Utility/BackButton";
import { Button } from "../../shadcn-ui/Button";

// Define the props for the CreateAssignment component
interface CreateAssignmentProps {
	mode: "create" | "edit";
	existingAssignment?: AssignmentPayload;
}

// Define the props for each step component
type StepProps = {
	values: any;
	errors: any;
	touched: any;
	setFieldValue: (field: string, value: any) => void;
	setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
	onNext: () => void;
	onPrevious: () => void;
	onEdit: (stepIndex: number) => void;
	updateFormValues: (values: any) => void;
	isValid: boolean;
	dirty: boolean;
	isEditMode: boolean;
	onSubmit: (assignmentPayload: AdminAssignmentView) => void;
};

// Define the structure for each step
interface Step {
	component: React.ComponentType<StepProps>;
	label: string;
	icon: React.ComponentType<any>;
}

// Main CreateAssignment component
const CreateAssignment: React.FC<CreateAssignmentProps> = ({ mode, existingAssignment }) => {
	// Initialize the form with default values
	const initialValues: AssignmentPayload = new AssignmentPayload({});

	const { step, assignmentId } = useParams<{ step?: string; assignmentId?: string }>();
	const [currentStep, setCurrentStep] = useState(0);
	const { updateFormValues } = useFormContext();
	const [formInitialValues, setFormInitialValues] = useState<AssignmentPayload>(initialValues);
	const [loading, setLoading] = useState(true);

	const isEditMode = mode === "edit";

	// Fetch assignment data if in edit mode
	useEffect(() => {
		const fetchAssignmentPayload = async () => {
			if (isEditMode && assignmentId) {
				setLoading(true);
				const payload = await AssignmentService.getAssignmentPayloadById(assignmentId);
				console.log("payload", payload);
				setFormInitialValues({ ...initialValues, ...payload });

				updateFormValues(payload);
			}
			setLoading(false);
		};

		fetchAssignmentPayload();
	}, [isEditMode, assignmentId]);

	// Define the steps for creating/editing an assignment
	const steps: Step[] = [
		{ component: BasicInformation, label: "Basic Information", icon: DocumentTextIcon },
		{ component: ReflectionDates, label: "Reflection Dates", icon: ClockIcon },
		{ component: AssignmentDistribution, label: "Assignment Distribution", icon: UserGroupIcon },
		{ component: AdminSummaryOptions, label: "Admin Summary", icon: CogIcon },
		{ component: ReviewAssignment, label: "Review", icon: ClipboardDocumentListIcon }
	];

	// Set the current step based on the URL parameter
	useEffect(() => {
		if (step) {
			const stepIndex = parseInt(step, 10);
			if (!isNaN(stepIndex) && stepIndex >= 0 && stepIndex < steps.length) {
				setCurrentStep(stepIndex);
			}
		}
	}, [step, steps.length]);

	// Handle form submission
	const handleSubmit = (assignmentPayload: AdminAssignmentView) => {
		console.log("Form submitted with values:", assignmentPayload);
		NavigateService.navToAdminAssignment(assignmentPayload.assignmentId);
	};

	// Handle navigation to the next step
	const handleNext = (values: any) => {
		updateFormValues(values);
		const nextStep = Math.min(currentStep + 1, steps.length - 1);
		setCurrentStep(nextStep);
		if (!assignmentId) {
			return;
		}
		if (isEditMode) {
			NavigateService.navToEditAssignmentStep(assignmentId, nextStep);
		} else {
			NavigateService.navToCreateAssignmentStep(nextStep);
		}
	};

	// Handle navigation to the previous step
	const handlePrevious = (values: any) => {
		updateFormValues(values);
		const prevStep = Math.max(currentStep - 1, 0);
		setCurrentStep(prevStep);
		if (!assignmentId) {
			return;
		}
		if (isEditMode) {
			NavigateService.navToEditAssignmentStep(assignmentId, prevStep);
		} else {
			NavigateService.navToCreateAssignmentStep(prevStep);
		}
	};

	// Handle editing a specific step
	const handleEdit = (stepIndex: number) => {
		setCurrentStep(stepIndex);
		NavigateService.navToCreateAssignmentStep(stepIndex);
	};

	// Define the validation schema for the form
	const validationSchema = Yup.object().shape({
		title: Yup.string().required("Title is required"),
		description: Yup.string().required("Description is required"),
		courseName: Yup.string().required("Course Name is required"),
		courseCode: Yup.string().required("Course Code is required"),
		courseTerm: Yup.string().required("Course Term is required"),
		sessions: Yup.array().of(
			Yup.object().shape({
				date: Yup.date().nullable().required("Session date is required")
			})
		),
		reportFrequency: Yup.string().required("Report frequency is required")
	});

	return (
		<div className="h-full">
			{/* Gradient background */}
			<div className=" h-40 -mt-8"></div>
			<div className="max-w-7xl mx-auto -mt-32">
				<BackButton />
				{!loading && (
					<Formik initialValues={formInitialValues} onSubmit={() => {}} validationSchema={validationSchema} enableReinitialize>
						{({ values, errors, touched, setFieldValue, setFieldTouched, isValid, dirty }) => (
							<Form className="space-y-8">
								<div className="flex flex-col md:flex-row">
									{/* Sidebar with step navigation */}
									<div className="w-full md:w-1/4 md:pr-8 mb-8 md:mb-0">
										<div className="bg-white rounded-lg p-6 shadow-sm">
											<h1 className="text-2xl font-bold text-gray-900 mb-2">
												{isEditMode ? "Edit Assignment" : "Create Assignment"}
											</h1>
											<p className="text-gray-500 mb-6">{steps[currentStep].label}</p>
											{/* Progress bar */}
											<div className="mb-6">
												<div className="h-1 w-full bg-gray-200 rounded-full">
													<div
														className="h-1 bg-[#00a9af] rounded-full"
														style={{ width: `${((currentStep + 1) / steps.length) * 100}%` }}
													></div>
												</div>
											</div>
											<StepNavigation steps={steps} currentStep={currentStep} />
										</div>
									</div>
									{/* Main content area */}
									<div className="w-full md:w-3/4">
										<div>
											{React.createElement(steps[currentStep].component, {
												values,
												errors,
												touched,
												setFieldValue,
												setFieldTouched,
												onNext: () => handleNext(values),
												onPrevious: () => handlePrevious(values),
												onEdit: handleEdit,
												updateFormValues,
												onSubmit: handleSubmit,
												isValid,
												dirty,
												isEditMode
											} as StepProps)}
										</div>
										{/* Hide navigation buttons on the final review step */}
										{currentStep !== steps.length - 1 && (
											<div className="mt-8 flex gap-2 md:hidden">
												<Button variant="secondary" onClick={() => handlePrevious(values)} disabled={currentStep === 0}>
													Previous
												</Button>
												<Button onClick={() => handleNext(values)} disabled={currentStep === steps.length - 1 || !isValid}>
													{currentStep === steps.length - 1 ? "Submit" : "Next"}
												</Button>
											</div>
										)}
									</div>
								</div>
							</Form>
						)}
					</Formik>
				)}
			</div>
		</div>
	);
};

// Wrap the CreateAssignment component with FormProvider
const WrappedCreateAssignment: React.FC<CreateAssignmentProps> = (props) => (
	<FormProvider>
		<CreateAssignment {...props} />
	</FormProvider>
);

export default WrappedCreateAssignment;
