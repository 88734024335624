import axios from "axios";
import { LogAxiosError } from "./AxiosUtility";

export class PromptService {
	private static baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/prompts`;

	public static async refreshPrompts(): Promise<boolean> {
		try {
			const response = await axios.post(`${this.baseUrl}/refresh`);
			return response.data.success;
		} catch (error) {
			const errorMessage = LogAxiosError(error, "refresh prompts");
			throw new Error(errorMessage);
		}
	}
}
