import React from "react";
import { Card, CardContent } from "../shadcn-ui/Card";
import callingScreen from "../../assets/images/calling_screen.png";
import { PhoneCallIcon } from "lucide-react";

const UserCallScheduled: React.FC = () => {
	return (
		<div className="container mx-auto px-4 py-8">
			<Card className="max-w-md mx-auto p-4">
				<CardContent className="space-y-4 p-0">
					<div>
						<p className="text-center mb-2 text-[#101828] text-xl font-bold flex items-center justify-center">
							<PhoneCallIcon className="w-6 h-6 mr-2 text-[#00A9AF]" />
							Phone Call Scheduled
						</p>
						<img src={callingScreen} alt="Calling Screen" className="w-full h-auto mb-4" />
						<p className="text-center text-[#475467] mb-4">You will receive a call at the scheduled time.</p>
					</div>
				</CardContent>
			</Card>
		</div>
	);
};

export default UserCallScheduled;
