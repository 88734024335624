import React, { createContext, useState, useContext, ReactNode } from "react";

// Define the shape of the form context
interface FormContextType {
	formValues: any;
	updateFormValues: (values: any) => void;
}

// Create a context for the form
const FormContext = createContext<FormContextType | undefined>(undefined);

// FormProvider component to wrap the application and provide form context
export const FormProvider: React.FC<{ children: ReactNode; initialValues?: any }> = ({ children, initialValues = {} }) => {
	// State to hold the form values
	const [formValues, setFormValues] = useState<any>(initialValues);

	// Function to update form values
	const updateFormValues = (values: any) => {
		setFormValues((prevValues: any) => ({ ...prevValues, ...values }));
	};

	// Provide the context to children components
	return <FormContext.Provider value={{ formValues, updateFormValues }}>{children}</FormContext.Provider>;
};

// Custom hook to use the form context
export const useFormContext = () => {
	const context = useContext(FormContext);
	if (context === undefined) {
		throw new Error("useFormContext must be used within a FormProvider");
	}
	return context;
};
