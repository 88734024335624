import axios from "axios";
import { SentEmailsView } from "../types/SentEmailsView";
import { LogAxiosError } from "./AxiosUtility";
import { plainToInstance } from "class-transformer";

export class SentEmailService {
	private static baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/sent-emails`;

	static async getAll(): Promise<SentEmailsView[]> {
		try {
			return this.fetchSentEmails(`${this.baseUrl}/getAll`);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get all sent emails`);
			throw new Error(errorMessage);
		}
	}

	private static async fetchSentEmails(url: string): Promise<SentEmailsView[]> {
		try {
			const response = await axios.get(url);
			return response.data.map((send_email: object) => plainToInstance(SentEmailsView, send_email));
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get all sent emails ${url}`);
			throw new Error(errorMessage);
		}
	}

	static async resendReflectionReport(isResend: boolean, assignmentId: string, recipients: string): Promise<void> {
		try {
			await axios.post(`${this.baseUrl}/resendReflectionReport`, { isResend: isResend, assignmentId: assignmentId, recipients: recipients });
		} catch (error) {
			const errorMessage = LogAxiosError(error, `resend reflection report ${assignmentId} ${recipients}`);
			throw new Error(errorMessage);
		}
	}

	static async resendInviteUserToCourse(isResend: boolean, assignmentId: string, recipients: string): Promise<void> {
		try {
			await axios.post(`${this.baseUrl}/resendInviteToCourseEmail`, { isResend: isResend, assignmentId: assignmentId, recipients: recipients });
		} catch (error) {
			const errorMessage = LogAxiosError(error, `resend invite to course ${assignmentId} ${recipients}`);
			throw new Error(errorMessage);
		}
	}

	static async resendSessionSummary(isResend: boolean, dynamicTemplateData: string, assignmentId: string, recipients: string): Promise<void> {
		try {
			await axios.post(`${this.baseUrl}/resendSessionSummary`, {
				isResend: isResend,
				dynamicTemplateData: dynamicTemplateData,
				assignmentId: assignmentId,
				recipients: recipients
			});
		} catch (error) {
			const errorMessage = LogAxiosError(error, `resend session summary ${assignmentId} ${recipients}`);
			throw new Error(errorMessage);
		}
	}

	static async testSendPostSessionSummary(): Promise<void> {
		try {
			await axios.post(`${this.baseUrl}/testSendPostSessionSummaryEmail`, {});
		} catch (error) {
			const errorMessage = LogAxiosError(error, `test send post session summary`);
			throw new Error(errorMessage);
		}
	}
}
