import { Expose, Transform } from "class-transformer";
import { IsUUID, IsDateString, IsString, IsBoolean, IsEnum } from "class-validator";
import { ViewColumn } from "./typeorm-mocks";
import { dateTransformer } from "./dateTransformer";
import { EmailStatus } from "../schemas/EmailStatus";

export class SentEmailsView {
	@Expose()
	@ViewColumn()
	@IsUUID()
	id!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	userId!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	scheduleId!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	emailType!: string;

	@Expose()
	@ViewColumn()
	@IsDateString()
	@Transform(({ value }) => dateTransformer(value))
	sentAt!: Date;

	@Expose()
	@ViewColumn()
	@IsString()
	assignedSessionId!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	recipients?: string;

	@Expose()
	@ViewColumn()
	@IsString()
	title?: string;

	@Expose()
	@ViewColumn()
	@IsString()
	assignmentId?: string;

	@Expose()
	@ViewColumn()
	@IsString()
	templateData!: string;

	@Expose()
	@ViewColumn()
	@IsBoolean()
	isSent!: boolean;

	@Expose()
	@ViewColumn()
	@IsEnum(EmailStatus)
	emailStatus!: EmailStatus;
}
