import React from "react";
import { Table } from "@tanstack/react-table";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./Select";

interface DataTableSelectFilterProps<TData> {
	table: Table<TData>;
	columnId: string;
	title: string;
	options: { label: string; value: string }[];
}

export function DataTableSelectFilter<TData>({ table, columnId, title, options }: DataTableSelectFilterProps<TData>) {
	const verifiedOptions = options.filter((option) => option.value !== "");
	return (
		<Select
			value={(table.getColumn(columnId)?.getFilterValue() as string) ?? ""}
			onValueChange={(value) => table.getColumn(columnId)?.setFilterValue(value === "all" ? "" : value)}
		>
			<SelectTrigger className="h-9 w-[150px]">
				<SelectValue placeholder={title} />
			</SelectTrigger>
			<SelectContent>
				<SelectItem value="all">All</SelectItem>
				{verifiedOptions.map((option) => (
					<SelectItem key={option.value} value={option.value}>
						{option.label}
					</SelectItem>
				))}
			</SelectContent>
		</Select>
	);
}
