import { ExperienceType } from "../schemas/ExperienceType";

/**
 * Groups experience types into either "co-op" or "course" categories
 * @param experienceType - The type of experience from ExperienceType enum
 * @returns "co-op" for work-related experiences or "course" for academic experiences
 */
export function getExperienceGroup(experienceType: ExperienceType): "co-op" | "course" {
	switch (experienceType) {
		case ExperienceType.CO_OP_EXPERIENCE:
		case ExperienceType.INTERNSHIP:
		case ExperienceType.WORK_PLACEMENT:
		case ExperienceType.CAPSTONE_PROJECT:
		case ExperienceType.WEEKLY_SPRINT:
		case ExperienceType.MONTHLY_PROJECT:
			return "co-op";
		case ExperienceType.COURSE:
			return "course";
		default:
			return "co-op";
	}
}
