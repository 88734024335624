import React, { useEffect, useRef, useState } from "react";
import { SessionService, VapiCallType } from "../../services/SessionService";
import { useAppContext } from "../../contexts/AppContext";
import { Button, buttonVariants } from "../../components/shadcn-ui/Button";
import { NavigateService } from "../../services/NavigateService";
import { UserCircleIcon, VideoCameraIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { assistants } from "../../constants/Assistants";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../shadcn-ui/Select";
import { cn } from "../../lib/utils";
import { Loader2 } from "lucide-react";

const OnboardingStart: React.FC = () => {
	const { userId, checkIfAvailable, activeAssistant, setActiveAssistant } = useAppContext();
	const audioRef = useRef<HTMLAudioElement | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const storedAssistantId = localStorage.getItem("selectedAssistantId");
		if (storedAssistantId) {
			const assistant = assistants.find((a) => a.id === storedAssistantId);
			if (assistant) {
				setActiveAssistant(assistant);
			}
		}
	}, [setActiveAssistant]);

	const handleStartOnboarding = async (mode: "phone" | "web") => {
		if (!userId) {
			console.error("User ID is missing");
			return;
		}

		if (mode === "phone") {
			NavigateService.navToPhoneCall();
			return;
		}

		setIsLoading(true);
		try {
			const result = await SessionService.startSession(
				userId,
				VapiCallType.WebCall,
				undefined,
				undefined,
				activeAssistant.voice_provider,
				activeAssistant.voice_id,
				activeAssistant.name
			);
			const isAvailable = await checkIfAvailable(userId);
			if (isAvailable) {
				NavigateService.navToOnboardingSession(userId, result.sessionId);
			} else {
				NavigateService.navToQueuePage("onboarding", userId, result.sessionId);
			}
		} catch (error) {
			console.error("Error starting onboarding session:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleAssistantChange = (value: string) => {
		const assistant = assistants.find((a) => a.id === value);
		if (assistant) {
			setActiveAssistant(assistant);
			localStorage.setItem("selectedAssistantId", assistant.id);

			// Play the assistant's audio
			if (audioRef.current) {
				audioRef.current.src = assistant.audio;
				audioRef.current.play().catch((error) => console.error("Error playing audio:", error));
			}
		}
	};

	return (
		<div className="flex items-center justify-center h-full min-h-[calc(100vh-4rem-4rem)]">
			<div className="text-center max-w-xl">
				<h1 className="text-3xl font-semibold text-gray-800 mb-4">Welcome to AI-Guided Reflection</h1>
				<p className="text-lg text-gray-600 mb-6">Let's go through a brief introduction to help you get the most out of your sessions.</p>
				<div className="flex flex-col items-center justify-center p-6 rounded-lg gap-6">
					<div className="w-1/2 mb-4">
						<label htmlFor="assistant-select" className="block text-sm font-medium text-gray-700 mb-2">
							Assistant Voice
						</label>
						<Select onValueChange={handleAssistantChange} defaultValue={activeAssistant.id}>
							<SelectTrigger
								id="assistant-select"
								className={cn(buttonVariants({ variant: "outline", size: "lg" }), "w-full justify-between")}
							>
								<SelectValue placeholder="Select an assistant" />
							</SelectTrigger>
							<SelectContent>
								{assistants.map((assistant) => (
									<SelectItem key={assistant.id} value={assistant.id}>
										<div className="flex items-center">
											<UserCircleIcon className="mr-2 h-5 w-5" />
											{assistant.name}
										</div>
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					</div>
					<Button className="w-1/2" variant="default" size="lg" onClick={() => handleStartOnboarding("web")} disabled={isLoading}>
						{isLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : <VideoCameraIcon className="mr-2 h-5 w-5" />}
						{isLoading ? "Starting..." : "Start web call now"}
					</Button>
					<Button className="w-1/2" variant="default" size="lg" onClick={() => handleStartOnboarding("phone")}>
						<PhoneIcon className="mr-2 h-5 w-5" /> Call me now
					</Button>
				</div>
			</div>
			<audio ref={audioRef} style={{ display: "none" }} />
		</div>
	);
};

export default OnboardingStart;
