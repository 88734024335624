import axios from "axios";
import { ScheduleView } from "../types/ScheduleView";

import { AssignedSessionView } from "../types/AssignedSessionView";
import { plainToInstance } from "class-transformer";
import { LogAxiosError } from "./AxiosUtility";

export class ScheduleService {
	private static baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/schedules`;
	private static baseUrl2 = `${process.env.REACT_APP_BACKEND_URL}/api/user-assignments`;

	static async getByAssignmentId(assignmentId: string): Promise<ScheduleView[]> {
		// console.log(`${this.baseUrl}/assignment/${assignmentId}`);
		try {
			return this.fetchSchedules(`${this.baseUrl}/assignment/${assignmentId}`);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get schedules by assignment ${assignmentId}`);
			throw new Error(errorMessage);
		}
	}
	static async getByScheduleId(scheduleId: string): Promise<ScheduleView> {
		try {
			return this.fetchSchedule(`${this.baseUrl}/schedule/${scheduleId}`);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get schedule by id ${scheduleId}`);
			throw new Error(errorMessage);
		}
	}

	// static async getByScheduleIdEmail(authToken: string): Promise<ScheduleView> {
	// 	return this.fetchSchedule(`${this.baseUrl}/schedule-email/${authToken}`);
	// }

	static async getByScheduleIdEmail(authToken: string): Promise<ScheduleView> {
		try {
			console.log("getByScheduleIdEmail authToken", authToken);

			const response = await axios.get<ScheduleView>(`${this.baseUrl}/schedule-email`, {
				headers: { Authorization: `Bearer ${authToken}` } // Added authorization header
			});
			return plainToInstance(ScheduleView, response.data);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get schedule by id email ${authToken}`);
			throw new Error(errorMessage);
		}
	}

	static async generateFirstAggregateData(assignmentId: string, scheduleId: string): Promise<ScheduleView> {
		return this.generateAggregateData(`${this.baseUrl}/generate-first-aggregate`, assignmentId, scheduleId);
	}

	static async generateMiddleAggregateData(assignmentId: string, scheduleId: string): Promise<ScheduleView> {
		return this.generateAggregateData(`${this.baseUrl}/generate-middle-aggregate`, assignmentId, scheduleId);
	}

	static async generateLastAggregateData(assignmentId: string, scheduleId: string): Promise<ScheduleView> {
		return this.generateAggregateData(`${this.baseUrl}/generate-last-aggregate`, assignmentId, scheduleId);
	}

	private static async generateAggregateData(url: string, assignmentId: string, scheduleId: string): Promise<ScheduleView> {
		try {
			const response = await axios.post(
				url,
				{ assignmentId, scheduleId },
				{
					headers: {
						"Content-Type": "application/json"
					}
				}
			);

			const data = response.data;
			return plainToInstance(ScheduleView, data);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `generate aggregate data ${url} ${assignmentId} ${scheduleId}`);
			throw new Error(errorMessage);
		}
	}

	static async downloadAggregateTemplate(schedule: ScheduleView): Promise<void> {
		try {
			const response = await axios.post(`${this.baseUrl2}/generate-report-template`, { payload: { schedule } }, { responseType: "blob" });

			const url = window.URL.createObjectURL(new Blob([response.data]));
			const a = document.createElement("a");
			a.href = url;
			a.download = "Reflection_Report.docx";
			document.body.appendChild(a);
			a.click();
			a.remove();
			window.URL.revokeObjectURL(url);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `download aggregate docx ${schedule.scheduleId}`);
			throw new Error(errorMessage);
		}
	}

	static async downloadAggregatePDF(scheduleId: string): Promise<void> {
		try {
			const response = await axios.post(`${this.baseUrl2}/generate-report-template`, { payload: { scheduleId } }, { responseType: "blob" });

			const url = window.URL.createObjectURL(new Blob([response.data]));
			const a = document.createElement("a");
			a.href = url;
			a.download = "Reflection_Report.pdf";
			document.body.appendChild(a);
			a.click();
			a.remove();
			window.URL.revokeObjectURL(url);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `download aggregate pdf ${scheduleId}`);
			throw new Error(errorMessage);
		}
	}

	static async downloadAssignedSessionsCSV(assignedSessions: AssignedSessionView[]): Promise<void> {
		try {
			const response = await axios.post(
				`${this.baseUrl2}/generate-assigned-sessions-csv`,
				{ payload: { assignedSessions } },
				{ responseType: "blob", headers: { "Content-Type": "application/json" } }
			);

			const url = window.URL.createObjectURL(new Blob([response.data]));
			const a = document.createElement("a");
			a.href = url;
			a.download = "Assigned_Sessions.csv";
			document.body.appendChild(a);
			a.click();
			a.remove();
			window.URL.revokeObjectURL(url);
		} catch (error) {
			console.error("Error generating aggregate data:", error);
			throw error;
		}
	}

	private static async fetchSchedules(url: string): Promise<ScheduleView[]> {
		try {
			const response = await axios.get(url);
			return response.data.map((schedule: object) => plainToInstance(ScheduleView, schedule));
		} catch (error) {
			console.error("Error fetching schedules:", error);
			throw error;
		}
	}

	private static async fetchSchedule(url: string): Promise<ScheduleView> {
		try {
			const response = await axios.get(url);
			return plainToInstance(ScheduleView, response.data);
		} catch (error) {
			console.error("Error fetching schedule:", error);
			throw error;
		}
	}
}
