import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { SentEmailsView } from "../../types/SentEmailsView";
import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";

import { DataTableActionMenu } from "../../components/shadcn-ui/DataTableActionMenu";
import { DataTableColumnHeader } from "../../components/shadcn-ui/DataTableColumnHeader";
import { Badge } from "../../components/shadcn-ui/Badge";
import { Button } from "../../components/shadcn-ui/Button";

import { useAppContext } from "../../contexts/AppContext";
import { SentEmailService } from "../../services/SentEmailService";
import { Filter, DataTable } from "../../components/shadcn-ui/DataTable";
import { ExtendedColumnDef } from "../shadcn-ui/DataTable";

const SendEmailsPage: React.FC = () => {
	const navigate = useNavigate();
	const { debugMode } = useAppContext();
	const [sentEmails, setSentEmails] = useState<SentEmailsView[]>([]);

	async function fetchSentEmails() {
		try {
			const data = await SentEmailService.getAll();
			setSentEmails(data);
		} catch (error) {
			console.error("Error fetching sent emails:", error);
		}
	}

	useEffect(() => {
		fetchSentEmails();
	}, []);

	const columns = useMemo<ExtendedColumnDef<SentEmailsView, any>[]>(
		() => [
			{
				accessorKey: "title",
				header: ({ column }) => <DataTableColumnHeader column={column} title="Title" />,
				cell: ({ row }) => <span className="font-mono text-xs">{row.original.title}</span>
			},
			{
				accessorKey: "id",
				header: ({ column }) => <DataTableColumnHeader column={column} title="Email ID" />,
				cell: ({ row }) => <span className="font-mono text-xs">{row.original.id}</span>
			},
			{
				accessorKey: "emailType",
				header: ({ column }) => <DataTableColumnHeader column={column} title="Email Type" />
			},
			{
				accessorKey: "sentAt",
				header: ({ column }) => <DataTableColumnHeader column={column} title="Sent At" />,
				cell: ({ row }) => new Date(row.original.sentAt).toLocaleString()
			},
			{
				accessorKey: "recipients",
				header: ({ column }) => <DataTableColumnHeader column={column} title="Recipients" />
			},
			{
				accessorKey: "assignmentId",
				header: ({ column }) => <DataTableColumnHeader column={column} title="Assignment ID" />
			},
			{
				accessorKey: "emailStatus",
				header: ({ column }) => <DataTableColumnHeader column={column} title="Email Status" />
			},
			// {
			// 	accessorKey: "status",
			// 	header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
			// 	cell: ({ getValue }) => {
			// 		const value = getValue() as string;
			// 		const colors: { [key: string]: string } = {
			// 			sent: "bg-green-100 text-green-800",
			// 			failed: "bg-red-100 text-red-800",
			// 			pending: "bg-yellow-100 text-yellow-800"
			// 		};
			// 		return <Badge className={`${colors[value] || "bg-gray-100 text-gray-800"}`}>{value}</Badge>;
			// 	}
			// },
			{
				id: "actions",
				cell: ({ row }) => {
					const email = row.original;
					const { assignmentId } = row.original;
					const { emailType } = row.original;
					const { recipients } = row.original;
					const { assignedSessionId } = row.original;
					const { templateData } = row.original;
					console.log(assignmentId);
					return (
						<DataTableActionMenu
							label="Actions"
							copyId={debugMode ? email.id : undefined}
							menuItems={[
								{ label: "View Details", onClick: () => handleViewEmailDetails(email.id) },
								{
									label: "Resend",
									onClick: () => handleResendEmail(emailType, templateData, assignmentId || "", recipients || "")
								}
							]}
						/>
					);
				}
			}
		],
		[debugMode]
	);

	const filters: Filter[] = [
		{
			columnId: "emailType",
			type: "input",
			placeholder: "Filter Email Type..."
		},
		// {
		// 	columnId: "status",
		// 	type: "select",
		// 	placeholder: "Status",
		// 	options: [
		// 		{ label: "Sent", value: "sent" },
		// 		{ label: "Failed", value: "failed" },
		// 		{ label: "Pending", value: "pending" }
		// 	]
		// },
		{
			columnId: "sentAt",
			type: "dateRange",
			placeholder: "Sent Date"
		}
	];

	const handleViewEmailDetails = (emailId: string) => {
		// Implement view email details logic
		console.log(`View details for email ${emailId}`);
	};

	const handleResendEmail = (type: string, dynamicTemplateData: string, assignmentId: string, recipients: string) => {
		// Implement resend email logic
		if (type === "postAssignmentOverview") {
			console.log(`Resend email ${type}`);
			SentEmailService.resendReflectionReport(true, assignmentId, recipients);
		} else if (type === "inviteUserToCourse") {
			console.log(`Resend email ${type}`);

			SentEmailService.resendInviteUserToCourse(true, assignmentId, recipients);
		} else if (type === "postSessionSummary") {
			console.log(`Resend email ${type}`);
			SentEmailService.resendSessionSummary(true, dynamicTemplateData, assignmentId, recipients);
		} else {
			return;
		}
	};

	const downloadCSV = () => {
		// Implement CSV download logic
		console.log("Downloading CSV");
	};

	return (
		<div className="h-full">
			<div className="flex justify-between items-center mb-6">
				<h2 className="text-2xl md:text-3xl font-bold text-[#16013e]">Sent Emails</h2>
				<div className="flex items-center gap-4">
					<Button onClick={downloadCSV} variant="outline" size="icon" title="Download CSV">
						<ArrowDownTrayIcon className="h-4 w-4" />
					</Button>
				</div>
			</div>

			<DataTable filters={filters} columns={columns} data={sentEmails} />
		</div>
	);
};

export default SendEmailsPage;
