import axios, { AxiosError } from "axios";
import posthog from "posthog-js";

interface instageApiError {
	error: string;
}

/**
 * Logs and formats axios errors in a consistent way
 * @param error - The error object to process, typically from a catch block
 * @param context - Optional context string to add additional details about where the error occurred
 * @returns A formatted error message string that includes the error details
 */
export function LogAxiosError(error: unknown, context?: string): string {
	const backendUrl = process.env.REACT_APP_BACKEND_URL || "";

	if (axios.isAxiosError(error)) {
		const axiosError = error as AxiosError;
		const contextInfo = context ? ` for ${context}` : "";
		const endpoint = axiosError.response?.config?.url?.replace(backendUrl, "");
		if (axiosError.response?.data) {
			const errorData = axiosError.response.data as instageApiError;
			const data = axiosError.config?.data; // Request body
			const params = axiosError.config?.params; // Query parameters
			// post hog send here
			posthog?.capture(`API Error: ${endpoint}${contextInfo}`, {
				data: data,
				params: params,
				error: true
			});
			console.error(`API Error at ${endpoint}${contextInfo}:`, axiosError.response.status, errorData.error, axiosError.message, data, params);

			return ` ${axiosError.message} ${errorData.error}`;
		} else if (axiosError.request) {
			console.error(`Network Error at ${endpoint}${contextInfo}:`, {
				message: axiosError.message
			});
			return ` ${axiosError.message}`;
		} else {
			console.error(`Request Error at ${endpoint}${contextInfo}:`, {
				message: axiosError.message
			});
			return ` ${axiosError.message}`;
		}
	} else {
		console.error(`Unknown Error :`, error);
		return `Unknown Error ${error}`;
	}
}
