import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./Select";

interface DataTablePaginationProps<TData> {
	table: Table<TData>;
}

export function DataTablePagination<TData>({ table }: DataTablePaginationProps<TData>) {
	const { getState, setPageSize, previousPage, nextPage, getPageCount, getCanNextPage, getCanPreviousPage } = table;

	const { pagination } = getState();
	const pageCount = table.getPageCount();

	// console.log("DataTablePagination", pagination);
	return (
		<div className="flex items-center justify-between px-2">
			<div className="flex items-center space-x-6 lg:space-x-8">
				<div className="flex items-center space-x-2">
					<p className="text-sm font-medium">Rows per page</p>
					<Select
						value={`${pagination.pageSize}`}
						onValueChange={(value) => {
							setPageSize(Number(value));
						}}
					>
						<SelectTrigger className="h-8 w-[70px]">
							<SelectValue placeholder={pagination.pageSize} />
						</SelectTrigger>
						<SelectContent side="top">
							{[10, 20, 30, 40, 50].map((pageSize) => (
								<SelectItem key={pageSize} value={`${pageSize}`}>
									{pageSize}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
				</div>
				<div className="flex w-[100px] items-center justify-center text-sm font-medium">
					Page {pagination.pageIndex + 1} {pageCount > 0 ? ` of ${pageCount}` : null}
				</div>
				<div className="flex items-center space-x-2">
					<ChevronLeftIcon
						className={`h-4 w-4 ${!getCanPreviousPage() ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
						onClick={() => getCanPreviousPage() && previousPage()}
					/>
					<ChevronRightIcon
						className={`h-4 w-4 ${!getCanNextPage() ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
						onClick={() => getCanNextPage() && nextPage()}
					/>
				</div>
			</div>
		</div>
	);
}
