import axios from "axios";
import { SessionService, VapiCallType } from "./SessionService";

export class VapiService {
	private static baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/vapi`;

	public static async createPhoneCall(
		userId: string,
		name: string,
		voiceProvider: string,
		voiceId: string,
		assistantName: string,
		assignmentId?: string,
		scheduleId?: string
	): Promise<void> {
		const result = await SessionService.startSession(
			userId,
			VapiCallType.OutboundPhoneCall,
			assignmentId,
			scheduleId,
			voiceProvider,
			voiceId,
			assistantName
		);

		console.log("session started", result);
		return axios.post(`${this.baseUrl}/create-phone-callV2`, { userId, name, sessionId: result.sessionId });
	}

	public static async createPhoneCallV3(userId: string, sessionId: string): Promise<void> {
		console.log("session started", sessionId);
		return axios.post(`${this.baseUrl}/create-phone-callV3`, { userId, sessionId: sessionId });
	}
}
