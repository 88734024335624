import { ViewColumn } from "./typeorm-mocks";
import { IsUUID, IsString, IsNumber, IsOptional } from "class-validator";
import { Expose } from "class-transformer";
export class CompetencyView {
	@ViewColumn()
	@Expose()
	@IsUUID()
	id!: string;

	@ViewColumn()
	@Expose()
	@IsString()
	name!: string;

	@ViewColumn()
	@Expose()
	@IsString()
	@IsOptional()
	description?: string;

	@ViewColumn()
	@Expose()
	@IsString()
	@IsOptional()
	examples?: string;

	@ViewColumn()
	@Expose()
	@IsString()
	@IsOptional()
	category?: string;

	@ViewColumn()
	@Expose()
	@IsString()
	@IsOptional()
	clientName?: string;

	@ViewColumn()
	@Expose()
	@IsUUID()
	clientId!: string;

	@ViewColumn()
	@Expose()
	@IsUUID()
	tenantId!: string;

	@ViewColumn()
	@Expose()
	@IsNumber()
	assignmentCount!: number;
}
