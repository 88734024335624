import React, { useState } from "react";
import { Table } from "@tanstack/react-table";
import { CalendarIcon } from "lucide-react";
import { DateRange } from "react-day-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface DataTableDateRangeFilterProps<TData> {
	table: Table<TData>;
	columnId: string;
	title: string;
}

export function DataTableDateRangeFilter<TData>({ table, columnId, title }: DataTableDateRangeFilterProps<TData>) {
	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
	const [startDate, endDate] = dateRange;

	return (
		<div className="flex items-center space-x-2">
			<DatePicker
				selectsRange={true}
				startDate={startDate || undefined}
				endDate={endDate || undefined}
				onChange={(update: [Date | null, Date | null]) => {
					setDateRange(update);
					table.getColumn(columnId)?.setFilterValue(update[0] ? update[0].toISOString().split("T")[0] : undefined);
					table.getColumn(columnId)?.setFilterValue(update[1] ? update[1].toISOString().split("T")[0] : undefined);
				}}
				isClearable={true}
				className="flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm transition-colors placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
				placeholderText="Select date range"
			/>
		</div>
	);
}
