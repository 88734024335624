import React, { useState } from "react";
import { AssignedSessionService } from "../../services/AssignedSessionService";
import { Button } from "../shadcn-ui/Button";
import { useAppContext } from "../../contexts/AppContext";
import { useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { AssignedSessionView, ActivityTypes } from "../../types/AssignedSessionView";
import { plainToInstance } from "class-transformer";
import { isDateNull } from "../Utility/isDateNull";

const UserReportEmailConfirm: React.FC = () => {
	const { token } = useParams<{ token: string }>();
	let sessionData: Partial<AssignedSessionView> | null = null;
	// console.log(token);
	if (token) {
		sessionData = plainToInstance(AssignedSessionView, jwtDecode(token)) as Partial<AssignedSessionView>;
		console.log(sessionData);
	}
	const mockSessionData: Partial<AssignedSessionView> = {
		scheduleId: "456",
		date: new Date(),
		scheduleDescription: "Schedule description",
		scheduleNumber: 3,
		scheduleCount: 4,
		assignmentTitle: "Reflection for Demo development",

		assignmentDescription: "This is a demo assignment for reflection.",

		email: "john.doe@example.com",
		fullName: "John Doe",
		firstName: "John",
		lastName: "Doe",

		courseName: "Demo Course",
		activityType: ActivityTypes.LAST,

		assignmentId: "123",

		userId: "user123",

		sessionId: "session123",
		submittedAt: new Date()
	};

	if (!sessionData) {
		sessionData = mockSessionData;
	}

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(!isDateNull(sessionData.submittedAt));

	const [submissionTime, setSubmissionTime] = useState<string | null>(
		sessionData.submittedAt
			? sessionData.submittedAt.toLocaleString("en-US", {
					timeZone: "EST",
					weekday: "long",
					year: "numeric",
					month: "long",
					day: "numeric",
					hour: "numeric",
					minute: "numeric",
					hour12: true
			  })
			: null
	);

	const handleSubmit = async () => {
		setIsSubmitting(true);
		try {
			if (!sessionData || !sessionData.sessionId || !sessionData.userId) {
				console.log(sessionData?.sessionId, sessionData?.userId);
				throw new Error("Session ID or User ID is missing");
			}
			const response = await AssignedSessionService.submitSession(sessionData.sessionId, sessionData.userId, 100, true, token);
			setSubmissionTime(new Date().toLocaleString("en-US", { timeZone: "EST", hour12: true }));
			setIsSubmitted(true);
		} catch (error) {
			console.error("Submission failed", error);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<div style={{ textAlign: "center", marginTop: "20px" }}>
			{!isSubmitted ? (
				<>
					<Button onClick={handleSubmit} disabled={isSubmitting}>
						{isSubmitting ? "Submitting..." : "Submit Report"}
					</Button>
					{isSubmitting && <div className="spinner" />}
				</>
			) : (
				<div>
					<p>Thanks</p>
					<p>Your summary has been submitted</p>

					<p>At {submissionTime} EST time</p>
				</div>
			)}
		</div>
	);
};

export default UserReportEmailConfirm;
