import React, { useMemo, useState } from "react";
import { format } from "date-fns";
import { AssignedSessionView } from "../../types/AssignedSessionView";
import { DataTable, ExtendedColumnDef, Filter } from "../shadcn-ui/DataTable";
import { DataTableActionMenu } from "../shadcn-ui/DataTableActionMenu";
import { Button } from "../shadcn-ui/Button";
import { ArrowDownTrayIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { Input } from "../shadcn-ui/Input";
import { Popover, PopoverContent, PopoverTrigger } from "../shadcn-ui/Popover";
import { NavigateService } from "../../services/NavigateService";
import { DocumentIcon } from "@heroicons/react/24/solid";
import { AssignedSessionStatus } from "../../types/AssignedSessionStatus";
import { useAppContext } from "../../contexts/AppContext";

interface AssignedSessionsTableProps {
	assignedSessions: AssignedSessionView[];
	onDownloadCSV: () => void;
	onAddUser: (email: string) => void;
	onEditUser: (userId: string) => void;
	onRemoveUser: (userId: string) => void;
	onAddExtension: (assignedSessionId: string, assignmentId: string, scheduleId: string, userId: string, daysToAdd: number) => void;
	length: number;
}

const AssignedSessionsTable: React.FC<AssignedSessionsTableProps> = ({
	length,
	assignedSessions,
	onDownloadCSV,
	onAddUser,
	onEditUser,
	onRemoveUser,
	onAddExtension
}) => {
	const [newUserEmail, setNewUserEmail] = useState("");

	const navigateToUserReport = (assignmentId: string, userId: string, scheduleId: string) => {
		NavigateService.navToSessionSummary(assignmentId, userId, scheduleId);
	};
	const hasExtension = assignedSessions.some((session) => session.daysToAdd !== 0);
	const { debugMode } = useAppContext();

	const columns: ExtendedColumnDef<AssignedSessionView, any>[] = [
		{
			accessorKey: "fullName",
			header: "Student",
			cell: ({ row }) => (
				<div className="w-[125px]">
					<div className="truncate">
						{row.original.firstName} {row.original.lastName}
					</div>
				</div>
			)
		},
		{
			accessorKey: "email",
			header: "Email",
			cell: ({ row }) => <span className="text-muted-foreground">{row.original.email}</span>
		},
		{
			accessorKey: "report",
			header: "Report",
			cell: ({ row }) =>
				row.original.status === AssignedSessionStatus.SUBMITTED || row.original.status === AssignedSessionStatus.SUBMITTED_LATE ? (
					<Button
						variant="link"
						onClick={() => navigateToUserReport(row.original.assignmentId, row.original.userId, row.original.scheduleId)}
						className="flex space-x-1 p-0"
					>
						<DocumentIcon className="h-4 w-4" />
						<span>View</span>
					</Button>
				) : null
		},
		{
			accessorKey: "status",
			header: "Status",
			cell: ({ row }) => (
				<span
					className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
						row.original.status === AssignedSessionStatus.SUBMITTED
							? "bg-[#00a9af] text-[#fff]"
							: row.original.status === AssignedSessionStatus.NOT_ATTEMPTED
							? "bg-red-100 text-red-800"
							: row.original.status === AssignedSessionStatus.PENDING || row.original.status === AssignedSessionStatus.IN_PROGRESS
							? "bg-yellow-100 text-yellow-600"
							: row.original.status === AssignedSessionStatus.AVAILABLE
							? "bg-green-100 text-green-800"
							: "bg-[#e6e5ff] text-[#16013e]"
					}`}
				>
					{row.original.status === AssignedSessionStatus.PENDING
						? "Upcoming"
						: row.original.status.charAt(0).toUpperCase() + row.original.status.slice(1)}
				</span>
			)
		},
		{
			accessorKey: "daysToAdd",
			header: "Extension",

			initiallyHidden: true
		},
		{
			accessorKey: "date",
			header: "Due Date",

			cell: ({ row }) => {
				const date = new Date(row.original.date + "Z"); // Append 'Z' to indicate UTC time
				date.setDate(date.getDate() + (row.original.daysToAdd || 0)); // Manually add days to the date
				if (date && date.getTime() !== 0) {
					if (debugMode) {
						return date.toLocaleString(); // Format to local time
					} else {
						return format(date, "yyyy-MM-dd");
					}
				}
				return "No due date";
			}
		},
		{
			accessorKey: "submittedAt",
			header: "Submitted At",
			initiallyHidden: true,
			cell: ({ row }) => {
				const value = row.original.submittedAt;
				if (value && value.getTime() !== 0) {
					return format(value, "yyyy-MM-dd HH:mm:ss");
				}
				return "Not submitted";
			}
		},
		// {
		// 	accessorKey: "grade",
		// 	header: "Grade",
		// 	cell: ({ row }) => (row.original.grade !== null ? row.original.grade : "Not graded")
		// },
		{
			id: "actions",
			cell: ({ row }) => (
				<DataTableActionMenu
					label="Actions"
					menuItems={[
						// {
						// 	label: "Edit User",
						// 	onClick: () => onEditUser(row.original.userId)
						// },
						{
							label: "Add 1 day extension",
							onClick: () => {
								if (row.original.assignedSessionId) {
									onAddExtension(
										row.original.assignedSessionId,
										row.original.assignmentId,
										row.original.scheduleId,
										row.original.userId,
										1
									);
								} else {
									console.error("Assigned session ID is null");
								}
							}
						},
						{
							label: "Add 3 day extension",
							onClick: () => {
								if (row.original.assignedSessionId) {
									onAddExtension(
										row.original.assignedSessionId,
										row.original.assignmentId,
										row.original.scheduleId,
										row.original.userId,
										3
									);
								} else {
									console.error("Assigned session ID is null");
								}
							}
						},
						{
							label: "Add 7 day extension",
							onClick: () => {
								if (row.original.assignedSessionId) {
									onAddExtension(
										row.original.assignedSessionId,
										row.original.assignmentId,
										row.original.scheduleId,
										row.original.userId,
										7
									);
								} else {
									console.error("Assigned session ID is null");
								}
							}
						},
						{
							label: "Remove User",
							onClick: () => onRemoveUser(row.original.userId)
						}
					]}
				/>
			)
		}
	];
	const dataTableKey = useMemo(
		() => assignedSessions.map((session) => session.assignmentId.split("-")[0]).join("-") + hasExtension,
		[assignedSessions, hasExtension]
	);

	const filters: Filter[] = [
		{
			columnId: "email",
			type: "input",
			placeholder: "Search"
		},
		{
			columnId: "status",
			type: "select",
			placeholder: "Status",
			options: [
				{ label: "Submitted", value: AssignedSessionStatus.SUBMITTED },
				{ label: "Past Due", value: AssignedSessionStatus.NOT_ATTEMPTED },
				{ label: "Pending", value: AssignedSessionStatus.PENDING },
				{ label: "Available", value: AssignedSessionStatus.AVAILABLE },
				{ label: "In Progress", value: AssignedSessionStatus.IN_PROGRESS },
				{ label: "Not Onboarded", value: AssignedSessionStatus.NOT_CONFIRMED },
				{ label: "Bounced", value: AssignedSessionStatus.BOUNCED }
			]
		},
		{
			columnId: "submittedAt",
			type: "dateRange",
			placeholder: "Submitted At"
		}
	];

	const handleAddUser = () => {
		if (newUserEmail.trim()) {
			onAddUser(newUserEmail.trim());
			setNewUserEmail("");
		}
	};

	return (
		<div key={length}>
			<div className="flex justify-between items-center mb-4">
				<h2 className="text-xl font-semibold">Assigned Sessions</h2>
				<div className="flex space-x-2">
					<Button onClick={onDownloadCSV} variant="outline" size="icon" title="Download CSV">
						<ArrowDownTrayIcon className="h-4 w-4" />
					</Button>
					<Popover>
						<PopoverTrigger asChild>
							<Button variant="outline" className="flex items-center space-x-2" title="Add User">
								<UserPlusIcon className="h-4 w-4" />
								<span>Add Student</span>
							</Button>
						</PopoverTrigger>
						<PopoverContent className="w-80">
							<div className="space-y-4">
								<h4 className="font-medium leading-none">Add User</h4>
								<Input
									type="email"
									placeholder="Enter email"
									value={newUserEmail}
									onChange={(e) => setNewUserEmail(e.target.value)}
								/>
								<Button onClick={handleAddUser} className="w-full">
									Add User
								</Button>
							</div>
						</PopoverContent>
					</Popover>
				</div>
			</div>

			<DataTable columns={columns} data={assignedSessions} filters={filters} key={dataTableKey} />
		</div>
	);
};

export default AssignedSessionsTable;
