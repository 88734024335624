import { Expose, Transform } from "class-transformer";

import { IsUUID, IsString, IsNumber, IsBoolean } from "class-validator";
import { ViewColumn } from "./typeorm-mocks";
import { numberTransformer } from "./numberTransformer";

export class UserAssignmentView {
	@Expose()
	@ViewColumn()
	@IsUUID()
	assignmentId!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	assignmentTitle!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	assignmentDescription!: string;

	@Expose()
	@ViewColumn()
	@IsUUID()
	courseId!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	courseName!: string;

	@Expose()
	@ViewColumn()
	@IsUUID()
	userId!: string;

	@Expose()
	@ViewColumn()
	@IsString()
	userEmail!: string;

	@Expose()
	@ViewColumn()
	@IsNumber()
	@Transform(({ value }) => numberTransformer(value))
	completedSessionsCount!: number;

	@Expose()
	@ViewColumn()
	@IsNumber()
	@Transform(({ value }) => numberTransformer(value))
	totalSessionsCount!: number;

	@ViewColumn()
	@IsString()
	experienceType!: "Co-op Experience" | "Internship" | "Work Placement" | "Capstone Project" | "Weekly Sprint" | "Monthly Project" | "Course";

	@IsBoolean()
	@ViewColumn()
	includeTranscript!: boolean;

	@IsBoolean()
	@ViewColumn()
	includeAudio!: boolean;
}
