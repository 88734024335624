import { useEffect, useState, useRef } from "react";
import Vapi from "@vapi-ai/web";

export enum VapiFirstMessageMode {
	AssistantSpeaksFirst = "assistant-speaks-first",
	AssistantSpeaksFirstWithModelGeneratedMessage = "assistant-speaks-first-with-model-generated-message",
	AssistantWaitsForUser = "assistant-waits-for-user"
}

interface VapiEventData {
	callStatus: "not_started" | "in_progress" | "ended";
	error: Error | null;
	assistantSpeechStarted: boolean;
	assistantSpeechStopped: boolean;
	userSpeechStarted: boolean;
	userSpeechStopped: boolean;
}
interface Assistant {
	name: string;
	transcriber: {
		provider: string;
		model: string;
		language: string;
	};
	model: {
		model: string;
		temperature: number;
		maxTokens: number;
		numFastTurns: number;
		provider: string;
		url: string;
	};
	voice: {
		provider: string;
		voiceId: string;
	};
	firstMessage: string;
	firstMessageMode: string;
	backgroundDenoisingEnabled: boolean;
}

interface Call {
	id: string;
	orgId: string;
	createdAt: string;
	updatedAt: string;
	type: string;
	status: string;
	assistant: Assistant;
	webCallUrl: string;
}
export interface VapiInterface {
	on(event: VapiEventNames, callback: VapiEventListeners[VapiEventNames]): this;
	once(event: VapiEventNames, callback: VapiEventListeners[VapiEventNames]): this;
	emit(event: VapiEventNames, ...args: any[]): boolean;
	removeListener(event: VapiEventNames, listener: VapiEventListeners[VapiEventNames]): this;
	removeAllListeners(event?: VapiEventNames): this;
	start(config: any): Promise<Call | null>;
	stop(): void;
	send(message: VapiClientToServerMessage): void;
	setMuted(mute: boolean): void;
	isMuted(): boolean;
	say(message: string, endCallAfterSpoken?: boolean): void;
}

// Add these new types
type VapiEventNames = "call-end" | "call-start" | "volume-level" | "speech-start" | "speech-end" | "message" | "error";

type VapiEventListeners = {
	"call-end": () => void;
	"call-start": () => void;
	"volume-level": (volume: number) => void;
	"speech-start": () => void;
	"speech-end": () => void;
	message: (message: any) => void;
	error: (error: any) => void;
};

type VapiClientToServerMessage = AddMessage | ControlMessage | SayMessage;

interface AddMessage {
	type: "add-message";
	message: {
		role: "user" | "assistant" | "system";
		content: string;
	};
}

interface ControlMessage {
	type: "control";
	control: "mute-assistant" | "unmute-assistant" | "say-first-message";
}

interface SayMessage {
	type: "say";
	message: string;
	endCallAfterSpoken?: boolean;
}

export interface vapiTranscriptMessage {
	role: "system" | "user" | "assistant";
	transcript: string;
	transcriptType: "partial" | "final";
}

const useVapi = () => {
	const [vapi, setVapi] = useState<VapiInterface | null>(null);
	const [eventData, setEventData] = useState<VapiEventData>({
		callStatus: "not_started",
		error: null,
		assistantSpeechStarted: false,
		assistantSpeechStopped: false,
		userSpeechStarted: false,
		userSpeechStopped: false
	});
	const [isInitialSpeech, setIsInitialSpeech] = useState(true);
	const isInitialSpeechRef = useRef(true);
	const [assistantVolume, setAssistantVolume] = useState(0);
	const [currentMessage, setCurrentMessage] = useState<string | null>(null);
	const [activityMessages, setActivityMessages] = useState<any[]>([]);
	const [transcriptMessages, setTranscriptMessages] = useState<vapiTranscriptMessage[]>([]);
	const [restartTrigger, setRestartTrigger] = useState(0);
	const activeCallAvatarRef = useRef<"coach" | "specialist" | null>(null);
	const [currentSpeaker, setCurrentSpeaker] = useState<"assistant" | "user" | null>(null);

	useEffect(() => {
		let isMounted = true;

		const vapiApiKey = process.env.REACT_APP_VAPI_PUBLIC_KEY;

		const initializeVapi = async () => {
			try {
				if (isMounted) {
					const vapiInstance = new Vapi(vapiApiKey || "");
					setVapi(vapiInstance as VapiInterface);

					// Set up event listeners
					vapiInstance.on("volume-level", (volume) => {
						// setAssistantVolume(volume);
					});

					vapiInstance.on("speech-start", () => {
						if (isInitialSpeechRef.current) {
							setIsInitialSpeech(false);
							isInitialSpeechRef.current = false;
						}
						setEventData((prev) => ({ ...prev, assistantSpeechStopped: false }));
					});

					vapiInstance.on("call-start", () => {
						setEventData((prev) => ({ ...prev, callStatus: "in_progress" }));
						setIsInitialSpeech(true);
						isInitialSpeechRef.current = true;
					});

					vapiInstance.on("call-end", () => {
						setEventData((prev) => ({ ...prev, callStatus: "ended" }));
					});

					vapiInstance.on("message", (message) => {
						// Handle conversation-update first
						if (message.type === "conversation-update") {
							setRestartTrigger(0);
							const lastAssistantMessage = message.conversation.filter((msg: any) => msg.role === "assistant").pop();
							if (lastAssistantMessage) {
								setCurrentMessage(lastAssistantMessage.content);
							}
							if (activeCallAvatarRef.current === "specialist") {
								setActivityMessages(message.conversation);
							}
							return; // Exit early to ensure this takes precedence
						}
						if (message.type === "transcript") {
							setTranscriptMessages((prevMessages) => [...prevMessages, message]);
						}
						// Handle other message types
						if (message.type === "transcript" && message.role === "assistant") {
							if (activeCallAvatarRef.current === "coach") {
								setCurrentMessage(message.transcript);
							} else if (activeCallAvatarRef.current === "specialist") {
								setActivityMessages((prevMessages) => {
									if (message.transcriptType === "partial") {
										const updatedMessages = [...prevMessages];
										if (updatedMessages.length === 0 || updatedMessages[updatedMessages.length - 1].role !== "assistant") {
											updatedMessages.push({ role: "assistant", content: message.transcript });
										} else {
											updatedMessages[updatedMessages.length - 1].content = message.transcript;
										}
										return updatedMessages;
									} else if (message.transcriptType === "final") {
										return [...prevMessages, { role: "assistant", content: message.transcript }];
									} else {
										return prevMessages;
									}
								});
							}
						} else if (message.type === "transcript" && message.role === "user") {
							setActivityMessages((prevMessages) => {
								if (message.transcriptType === "partial") {
									const updatedMessages = [...prevMessages];
									if (updatedMessages.length === 0 || updatedMessages[updatedMessages.length - 1].role !== "user") {
										updatedMessages.push({ role: "user", content: message.transcript });
									} else {
										updatedMessages[updatedMessages.length - 1].content = message.transcript;
									}
									return updatedMessages;
								} else if (message.transcriptType === "final") {
									return [...prevMessages, { role: "user", content: message.transcript }];
								} else {
									return prevMessages;
								}
							});
						} else if (message.type === "speech-update") {
							if (message.role === "assistant") {
								if (message.status === "started") {
									setCurrentSpeaker("assistant");
								} else if (message.status === "stopped") {
									setCurrentSpeaker(null);
								}
							} else if (message.role === "user") {
								if (message.status === "started") {
									setCurrentSpeaker("user");
								} else if (message.status === "stopped") {
									setCurrentSpeaker(null);
								}
							}
						}
					});
					vapiInstance.on("error", (error) => {
						console.error("Vapi error", error);
						setEventData((prev) => ({ ...prev, error }));
						if (error.errorMsg === "Meeting has ended") {
							setRestartTrigger((prev) => prev + 1);
						}
					});
				}
			} catch (error: any) {
				console.error("Error initializing Vapi:", error);
				if (isMounted) {
					setEventData((prev) => ({ ...prev, error }));
				}
			}
		};

		initializeVapi();

		return () => {
			isMounted = false;
		};
	}, []);

	const setActiveCallAvatarRef = (type: "coach" | "specialist" | null) => {
		activeCallAvatarRef.current = type;
	};

	return {
		vapi,
		isInitialSpeech,
		...eventData,
		currentMessage,
		activityMessages,
		setActivityMessages,
		setActiveCallAvatarRef,
		transcriptMessages,
		restartTrigger,
		assistantVolume,
		currentSpeaker
	};
};

export default useVapi;
