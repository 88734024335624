import React from "react";
import { MoreHorizontal } from "lucide-react";
import { Button } from "./Button";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuLabel, DropdownMenuItem, DropdownMenuSeparator } from "./Dropdown";

interface ActionMenuItem {
	label: string;
	onClick: () => void;
	hidden?: boolean;
}

interface DataTableActionMenuProps {
	label: string;
	copyId?: string;
	menuItems: ActionMenuItem[];
}

export function DataTableActionMenu({ label, copyId, menuItems }: DataTableActionMenuProps) {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" className="h-8 w-8 p-0">
					<span className="sr-only">Open menu</span>
					<MoreHorizontal className="h-4 w-4" />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end">
				<DropdownMenuLabel>{label}</DropdownMenuLabel>
				{copyId && <DropdownMenuItem onClick={() => navigator.clipboard.writeText(copyId)}>Copy ID</DropdownMenuItem>}
				{copyId && <DropdownMenuSeparator />}
				{menuItems.map((item, index) =>
					item.hidden ? null : (
						<DropdownMenuItem key={index} onClick={item.onClick}>
							{item.label}
						</DropdownMenuItem>
					)
				)}
			</DropdownMenuContent>
		</DropdownMenu>
	);
}
