import React from "react";
import { ArrowUpIcon, ArrowDownIcon } from "@heroicons/react/24/solid";
// import { VictoryLine, VictoryScatter, VictoryChart, VictoryAxis } from "victory";

interface ReportScoreProps {
	data: number[];
	color?: string;
	width?: number;
	height?: number;
	ariaLabel?: string;
	showScore?: boolean;
	dotSize?: number;
	lineColor?: string;
	currentScore: number;
}

const ReportScore: React.FC<ReportScoreProps> = ({
	data,
	color = "#00a9af",
	width = 80,
	height = 40,
	ariaLabel = "ReportScore",
	showScore = true,
	dotSize = 5,
	lineColor = "#d4d4d8",
	currentScore
}) => {
	if (!data || data.length === 0) return null;

	const getTrendIcon = () => {
		if (data.length < 2) return null;

		const currentValue = data[data.length - 1];
		const previousValue = data[data.length - 2];
		const difference = currentValue - previousValue;

		if (Math.abs(difference) === 0) {
			// return <MinusIcon className="h-4 w-4 text-gray-400" />;
		} else if (difference > 0) {
			return <ArrowUpIcon className="h-4 w-4 text-[#00a9af]" />;
		} else {
			return <ArrowDownIcon className="h-4 w-4 text-[#B42318]" />;
		}
	};

	return (
		<div className="relative flex items-center gap-2 min-w-fit" aria-label={ariaLabel}>
			{showScore && (
				<div className="flex items-baseline whitespace-nowrap space-x-1">
					{data && data.length > 1 && <div className="flex items-center">{getTrendIcon()}</div>}
					<span
						className={`text-2xl font-semibold ${
							currentScore >= 8 ? "text-[#00a9af]" : currentScore >= 6 ? "text-yellow-600" : "text-[#B42318]"
						}`}
					>
						{currentScore}
					</span>
					<span className="text-base text-[#16013e]">/ 10</span>
				</div>
			)}
			{/* Victory Chart commented out for now
			{data && data.length > 1 && (
				<VictoryChart
					width={width}
					height={height}
					padding={padding}
					domain={{
						x: [0, 100],
						y: [0, 10]
					}}
				>
					<VictoryAxis style={{ axis: { stroke: "transparent" }, ticks: { stroke: "transparent" }, tickLabels: { fill: "transparent" } }} />
					<VictoryLine
						data={dataPoints}
						interpolation="monotoneX"
						style={{
							data: { stroke: lineColor, strokeWidth: 2 }
						}}
					/>
					<VictoryScatter
						data={[dataPoints[dataPoints.length - 1]]}
						size={dotSize}
						style={{
							data: {
								fill: color,
								stroke: "white",
								strokeWidth: 2
							}
						}}
					/>
				</VictoryChart>
			)} */}
		</div>
	);
};

export default ReportScore;
