import React, { useState, useEffect } from "react";
import { Card, CardContent } from "../shadcn-ui/Card";
import { Button } from "../shadcn-ui/Button";
import { useAppContext } from "../../contexts/AppContext";
import { VapiService } from "../../services/VapiService";
import PhoneVerification from "../Shared/PhoneVerification";
import { useNavigate, useParams } from "react-router-dom";
import { UserService } from "../../services/UserService";
import { assistants } from "../../constants/Assistants";
import { NavigateService } from "../../services/NavigateService";
import { CheckIcon } from "lucide-react";
import callingScreen from "../../assets/images/calling_screen.png";
import { PhoneCallIcon } from "lucide-react";
import posthog from "posthog-js";

const SuccessMessage: React.FC = () => (
	<div className="w-full h-24 bg-gradient-to-r from-[#DB4F86] to-[#00A9AF] rounded-lg flex items-center justify-center mb-4">
		<div className="w-12 h-12 rounded-full bg-white flex items-center justify-center">
			<CheckIcon className="text-[#00A9AF] w-8 h-8" />
		</div>
	</div>
);

const UserCallMe: React.FC = () => {
	const navigate = useNavigate();
	const { assignmentId, scheduleId, sessionId } = useParams();
	const { instageUser, on, off } = useAppContext();

	const [isVerified, setIsVerified] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [currentAssistant, setCurrentAssistant] = useState(assistants[0]);

	useEffect(() => {
		const storedAssistantId = localStorage.getItem("selectedAssistantId");
		if (storedAssistantId) {
			const assistant = assistants.find((a) => a.id === storedAssistantId);
			if (assistant) {
				setCurrentAssistant(assistant);
			}
		}
	}, []);

	useEffect(() => {
		console.log("getting number");
		UserService.getPhoneNumber().then((number) => {
			console.log(number);
			if (number) {
				const formatted = number.number.replace(/^\+?(\d{1})(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4");
				setPhoneNumber(formatted);
				setIsVerified(number.isVerified);
			}
		});
	}, []);

	useEffect(() => {
		const handleSummaryGenerated = (data: { assignmentId: string; scheduleId: string }) => {
			// console.log("summaryGenerated", data);
			if (data.assignmentId && instageUser?.id && data.scheduleId) {
				NavigateService.navToUserAssignment(data.assignmentId, instageUser.id, data.scheduleId);
			}
		};

		on("summaryGenerated", handleSummaryGenerated);

		return () => {
			off("summaryGenerated", handleSummaryGenerated);
		};
	}, [on, off]);

	const handleVerificationComplete = React.useCallback((verifiedPhoneNumber: string) => {
		setIsVerified(true);
		setPhoneNumber(verifiedPhoneNumber);
		try {
			posthog.capture("MAJOR_EVENT: Verified Phone Number", { userId: instageUser?.id, timestamp: new Date().toISOString() });
		} catch (err) {
			console.error("Error capturing event 'MAJOR_EVENT: Verified Phone Number':", err);
		}
		console.log(verifiedPhoneNumber);
	}, []);

	const handleCallNow = async () => {
		if (!instageUser) return;
		setIsLoading(true);
		setError(null);

		try {
			if (!sessionId) {
				await VapiService.createPhoneCall(
					instageUser.id,
					instageUser.firstName,
					currentAssistant.voice_provider,
					currentAssistant.voice_id,
					currentAssistant.name,
					assignmentId,
					scheduleId
				);
			} else {
				await VapiService.createPhoneCallV3(instageUser.id, sessionId);
			}

			setIsLoading(false);
			setIsSuccess(true);
		} catch (error) {
			console.error("Failed to create phone call:", error);
			setIsLoading(false);
			setIsSuccess(false);
			setError("Failed to initiate the call. Please try again later.");
			try {
				posthog.capture("MAJOR_EVENT: Failed to Create Phone Call", { userId: instageUser?.id, timestamp: new Date().toISOString() });
			} catch (err) {
				console.error("Error capturing event 'MAJOR_EVENT: Failed to Create Phone Call':", err);
			}
		}
	};

	const handleChangeNumber = () => {
		setIsVerified(false);
		setPhoneNumber("");
	};

	const handleCancel = () => {
		navigate(-1);
	};

	return (
		<div className="container mx-auto px-4 py-8">
			<Card className="max-w-md mx-auto p-4">
				<CardContent className="space-y-4 p-0">
					{!isVerified ? (
						<PhoneVerification onVerificationComplete={handleVerificationComplete} onCancel={handleCancel} />
					) : !isSuccess ? (
						<div>
							<SuccessMessage />
							<p className="text-center mb-2 text-[#101828] text-xl font-bold">Number verified successfully!</p>
							<p className="text-center text-[#475467] mb-4">Click on "Call me now" to start the call</p>
							<p className="text-center mb-4">
								{phoneNumber}{" "}
								<Button
									variant="link"
									disabled={isLoading}
									onClick={handleChangeNumber}
									className="disabled:text-gray-400 disabled:bg-transparent"
								>
									change number
								</Button>
							</p>
							<div className="flex space-x-4">
								<Button variant="outline" className="w-1/2" onClick={handleCancel}>
									Cancel
								</Button>
								<Button className="w-1/2 bg-[#00A9AF] hover:bg-[#008C8F] text-white" onClick={handleCallNow} disabled={isLoading}>
									{isLoading ? "Calling..." : "Call me now"}
								</Button>
							</div>
							{error && <p className="text-red-500 text-sm mt-2">{error}</p>}
						</div>
					) : (
						<div>
							<p className="text-center mb-2 text-[#101828] text-xl font-bold flex items-center justify-center">
								<PhoneCallIcon className="w-6 h-6 mr-2 text-[#00A9AF]" />
								Call in progress...
							</p>
							<img src={callingScreen} alt="Calling Screen" className="w-full h-auto mb-4" />
							<p className="text-center text-[#475467] mb-4">You will receive a call shortly.</p>
						</div>
					)}
				</CardContent>
			</Card>
		</div>
	);
};

export default UserCallMe;
