import { IsDate, IsNotEmpty } from "class-validator";
import { ViewColumn } from "./typeorm-mocks";
import { IsUUID, IsString, IsNumber, IsOptional } from "class-validator";
import { Expose } from "class-transformer";

export class CommentView {
	@ViewColumn()
	@Expose()
	@IsUUID()
	id!: string;

	@ViewColumn()
	@Expose()
	@IsString()
	@IsNotEmpty()
	label!: string;

	@ViewColumn()
	@Expose()
	@IsString()
	@IsNotEmpty()
	content!: string;

	@ViewColumn()
	@Expose()
	@IsDate()
	createdAt!: Date;

	@ViewColumn()
	@Expose()
	@IsDate()
	updatedAt!: Date;

	@ViewColumn()
	@Expose()
	@IsUUID()
	userId!: string;

	@ViewColumn()
	@Expose()
	@IsString()
	username!: string;

	@ViewColumn()
	@Expose()
	@IsString()
	userEmail!: string;

	@ViewColumn()
	@Expose()
	@IsUUID()
	assignedSessionId!: string;

	@ViewColumn()
	@Expose()
	@IsUUID()
	assignmentId!: string;

	@ViewColumn()
	@Expose()
	@IsString()
	assignmentTitle!: string;
}
