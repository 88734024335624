import React from "react";
import { Link } from "react-router-dom";

const NoAccess: React.FC = () => {
	return (
		<div className="flex flex-col items-center justify-center h-full">
			<h1 className="text-6xl font-bold text-[#16013e] mb-4">No Access</h1>
			<p className="text-2xl text-[#16013e] mb-8">You do not have access to this page.</p>
			<Link to="/" className="px-6 py-3 bg-[#00a9af] text-white rounded-full hover:bg-[#008c91] transition-colors duration-300">
				Go Home
			</Link>
		</div>
	);
};

export default NoAccess;
