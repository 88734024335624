import React from "react";

const Spinner: React.FC<{ className?: string }> = ({ className }) => (
	<div className="flex justify-center items-center">
		<div className={`relative w-12 h-12 ${className}`}>
			<div className="absolute top-0 left-0 w-full h-full border-4 border-[#00a9af]/20 rounded-full"></div>
			<div className="absolute top-0 left-0 w-full h-full border-4 border-[#00a9af] rounded-full border-t-transparent animate-spin"></div>
		</div>
	</div>
);

export default Spinner;
