import React from "react";
// import { useAppContext } from "../../contexts/AppContext";
// import AtlassianStatusChart from "../../components/Utility/AtlassianStatusChart";
// import posthog from "posthog-js";
// import { SentEmailService } from "../../services/SentEmailService";

const ProviderStatusPage: React.FC = () => {
	// const { providers } = useAppContext();

	// useEffect(() => {
	// 	console.log("Providers: ", providers);
	// 	posthog.capture("my event", { property: "value" });
	// }, [providers]);

	return (
		<>
			{/* <div className="container mx-auto px-4 py-8">
				<h1 className="text-2xl font-bold mb-6">Provider Status</h1>
				<h4 className="text-lg mb-2">Current Status:</h4>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
					{providers.map((provider) => (
						<div key={provider.name} className="border rounded-lg p-4 flex flex-col items-center">
							<h2 className="text-lg font-semibold mb-1">{provider.label}</h2>
							<p className="text-sm text-gray-600 mb-2 text-center">{provider.impact}</p>
							<div className="relative w-5 h-5">
								<div
									className={`w-5 h-5 rounded-full absolute top-0 left-0 blur-sm ${
										provider.value === "stable"
											? "bg-green-500/50"
											: provider.value === "unstable"
											? "bg-yellow-500/50"
											: "bg-red-500/50"
									}`}
								></div>
								<div
									className={`w-4 h-4 rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
										provider.value === "stable" ? "bg-green-500" : provider.value === "unstable" ? "bg-yellow-500" : "bg-red-500"
									}`}
								></div>
							</div>
						</div>
					))}
				</div>
			</div>
			<h4 className="text-lg mb-2">Full Status History:</h4>
			<AtlassianStatusChart /> */}
		</>
	);
};

export default ProviderStatusPage;
