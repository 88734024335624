import axios from "axios";
import { LogAxiosError } from "./AxiosUtility";

const API_URL = `${process.env.REACT_APP_BACKEND_URL}/api/conferences`;

export interface ConferenceCreateDTO {
	name: string;
	description: string;
	city: string;
	startDate: number;
	endDate: number;
}

export interface ConferenceSession {
	id: string;
	userId: string;
	createdAt: string;
	activityType: string;
}

export interface Conference {
	id: string;
	name: string;
	description: string;
	city: string;
	startDate: Date;
	endDate: Date;
	createdAt: Date;
	updatedAt: Date;
	version: number;
	sessions: ConferenceSession[];
}

export class ConferenceService {
	static async getAllConferences(): Promise<Conference[]> {
		const response = await axios.get(`${API_URL}/get-all`);
		return response.data;
	}

	static async createConference(data: ConferenceCreateDTO): Promise<Conference> {
		const formattedData = {
			...data,
			startDate: new Date(data.startDate).toISOString(),
			endDate: new Date(data.endDate).toISOString()
		};
		try {
			const response = await axios.post(`${API_URL}/create`, formattedData);
			return response.data;
		} catch (error) {
			const errorMessage = LogAxiosError(error, `create conference ${data.name} ${data.startDate} ${data.endDate}`);
			throw new Error(errorMessage);
		}
	}

	static async updateConference(id: string, data: ConferenceCreateDTO): Promise<Conference> {
		const formattedData = {
			...data,
			startDate: new Date(data.startDate).toISOString(),
			endDate: new Date(data.endDate).toISOString()
		};
		try {
			const response = await axios.put(`${API_URL}/update/${id}`, formattedData);
			return response.data;
		} catch (error) {
			const errorMessage = LogAxiosError(error, `update conference ${id} ${data.name} ${data.startDate} ${data.endDate}`);
			throw new Error(errorMessage);
		}
	}

	static async deleteConference(id: string): Promise<void> {
		try {
			await axios.delete(`${API_URL}/delete/${id}`);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `delete conference ${id}`);
			throw new Error(errorMessage);
		}
	}

	static async getConferenceById(id: string): Promise<Conference> {
		try {
			const response = await axios.get(`${API_URL}/get-by-id/${id}`);
			return response.data;
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get conference by id ${id}`);
			throw new Error(errorMessage);
		}
	}

	static async getConferencesByDateRange(startDate: Date, endDate: Date): Promise<Conference[]> {
		try {
			const response = await axios.get(`${API_URL}/get-by-date-range`, {
				params: {
					startDate: startDate.toISOString(),
					endDate: endDate.toISOString()
				}
			});
			return response.data;
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get conferences by date range ${startDate} ${endDate}`);
			throw new Error(errorMessage);
		}
	}
}
