import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { Button } from "../../components/shadcn-ui/Button";

const BackButton: React.FC = () => {
	const navigate = useNavigate();

	return (
		<Button variant="ghost" className="text-gray-600 p-0 mb-4" onClick={() => navigate(-1)}>
			<ArrowLeftIcon className="h-5 w-5 mr-2" />
			<span>Back</span>
		</Button>
	);
};

export default BackButton;
