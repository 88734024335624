import React, { useEffect, useState, useRef } from "react";
import { useAppContext } from "../../contexts/AppContext";
import { SparklesIcon } from "@heroicons/react/24/solid";

const VapiMicrophoneVisualizer: React.FC = () => {
	const { vapi, callStatus, isCallActive } = useAppContext();
	const [volume, setVolume] = useState(0);
	const animationRef = useRef<number>();

	useEffect(() => {
		if (!vapi || !isCallActive || callStatus !== "in_progress") return;

		const volumeListener = (newVolume: number) => {
			setVolume(newVolume * 100);
		};

		vapi.on("volume-level", volumeListener);

		return () => {
			vapi.removeListener("volume-level", volumeListener);
			if (animationRef.current) {
				cancelAnimationFrame(animationRef.current);
			}
		};
	}, [vapi, callStatus, isCallActive]);

	if (!isCallActive || callStatus !== "in_progress") {
		return null;
	}

	const minShadowSize = 10;
	const maxShadowSize = 30;
	const shadowSize = minShadowSize + (maxShadowSize - minShadowSize) * (volume / 100);
	const animationDuration = 0.5 + (1 - volume / 100) * 1.5; // Faster animation for higher volume

	const pulseKeyframes = `
		@keyframes pulseShadow {
			from {
				box-shadow: 0 0 ${minShadowSize}px ${minShadowSize / 2}px rgba(219, 79, 134, 0.7);
			}
			to {
				box-shadow: 0 0 ${shadowSize}px ${shadowSize / 2}px rgba(219, 79, 134, 0.7);
			}
		}
	`;

	return (
		<div className="flex justify-center items-center h-full">
			<div
				className="w-48 h-48 bg-white rounded-full relative flex justify-center items-center"
				style={{
					animation: `pulseShadow ${animationDuration}s ease-in-out infinite alternate`
				}}
			>
				<SparklesIcon className="text-[#DB4F86] w-12 h-12" />
				<style>{pulseKeyframes}</style>
			</div>
		</div>
	);
};

export default VapiMicrophoneVisualizer;
