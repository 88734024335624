const SessionCount: React.FC<SessionCountProps> = ({ currentCount, totalCount }) => {
	return (
		<div className="flex items-center h-6">
			<div className="flex items-center">
				<div className="flex items-center">
					{Array.from({ length: currentCount }, (_, index) => (
						<div key={index} className="w-2 h-2 bg-[#00a9af] rounded-full mr-1"></div>
					))}
					{Array.from({ length: totalCount - currentCount }, (_, index) => (
						<div key={index} className="w-2 h-2 bg-gray-300 rounded-full mr-1"></div>
					))}
				</div>
			</div>
			<span className="text-gray-600 ml-2 text-sm">
				{currentCount} / {totalCount}
			</span>
		</div>
	);
};
interface SessionCountProps {
	currentCount: number;
	totalCount: number;
}
export default SessionCount;
