// Mock TypeORM decorators for frontend
export function Entity(): ClassDecorator {
	return () => {};
}

export function PrimaryGeneratedColumn(): PropertyDecorator {
	return () => {};
}

export function Column(type?: any): PropertyDecorator {
	return () => {};
}
export function ViewColumn(type?: any): PropertyDecorator {
	return () => {};
}
