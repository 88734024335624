import React from "react";
import { cn } from "../../lib/utils";

export interface TimelineSegment {
	value: string | number;
	variant: "high" | "medium" | "low" | "new";
}

interface ScoreTimelineProps {
	segments: TimelineSegment[];
	className?: string;
}

const getSegmentColor = (variant: TimelineSegment["variant"]): string => {
	switch (variant) {
		case "high":
			return "bg-[#b3e6e8]";
		case "medium":
			return "bg-yellow-100";
		case "low":
			return "bg-[#FEE4E2]";
		case "new":
			return "bg-blue-300";
		default:
			return "bg-gray-300";
	}
};

const ScoreTimeline: React.FC<ScoreTimelineProps> = ({ segments, className }) => {
	return (
		<div className={cn("w-full flex", className)}>
			{segments.map((segment, index) => (
				<div key={index} className={cn("h-2 flex-1", getSegmentColor(segment.variant))} title={`Score: ${segment.value}`} />
			))}
		</div>
	);
};

export default ScoreTimeline;
