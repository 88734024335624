interface Assistant {
	id: string;
	name: string;
	audio: any;
	description: string;
	voice_provider: string;
	voice_id: string;
}

export const assistants: Assistant[] = [
	{
		id: "1",
		name: "Nina",
		audio: `${process.env.PUBLIC_URL}/audio/nina.mp3`,
		description: "Kind and empathetic",
		voice_provider: "11labs",
		voice_id: "P7x743VjyZEOihNNygQ9"
	},
	{
		id: "2",
		name: "David",
		audio: `${process.env.PUBLIC_URL}/audio/david.mp3`,
		description: "Reserved and serious",
		voice_provider: "11labs",
		voice_id: "iP95p4xoKVk53GoZ742B"
	},
	{
		id: "3",
		name: "Myra",
		audio: `${process.env.PUBLIC_URL}/audio/myra.mp3`,
		description: "Calm and professional",
		voice_provider: "11labs",
		voice_id: "2zRM7PkgwBPiau2jvVXc"
	},
	{
		id: "4",
		name: "John",
		audio: `${process.env.PUBLIC_URL}/audio/john.mp3`,
		description: "Relaxed and friendly",
		voice_provider: "11labs",
		voice_id: "cjVigY5qzO86Huf0OWal"
	},
	{
		id: "5",
		name: "Ava",
		audio: `${process.env.PUBLIC_URL}/audio/ava.mp3`,
		description: "Calm and professional",
		voice_provider: "11labs",
		voice_id: "cgSgspJ2msm6clMCkdW9"
	}
];
