import React from "react";
import Header from "./Header";
import StatusBanner from "../UserPages/StatusBanner";

interface LayoutProps {
	children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
	return (
		<div className="min-h-screen flex flex-col bg-background">
			<Header />
			<main className="flex-grow h-[calc(100vh-4rem)] py-8 overflow-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:none]">
				<div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl min-h-full">{children}</div>
			</main>
		</div>
	);
};

export default Layout;
