import React, { useRef, useEffect } from "react";
import { useAuth } from "@frontegg/react";
import { useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { Button } from "../shadcn-ui/Button";

// import { Input } from "../shadcn-ui/Input";
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "../shadcn-ui/Card";
import UserAssignedSessionCard from "../Utility/UserAssignedSessionCard";
import { AssignedSessionView, ActivityTypes } from "../../types/AssignedSessionView";
import { PhoneCall, Calendar, Video } from "lucide-react"; // Import Lucide icons
import { plainToInstance } from "class-transformer";
import { NavigateService } from "../../services/NavigateService";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../shadcn-ui/Select";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { cn } from "../../lib/utils";
import { buttonVariants } from "../shadcn-ui/Button";
import { useAppContext } from "../../contexts/AppContext";
import { assistants } from "../../constants/Assistants";
import { AssignmentService } from "../../services/AssignmentService";
import posthog from "posthog-js";

const UserEmailLaunch: React.FC = () => {
	const { token } = useParams<{ token: string }>();
	const { user } = useAuth();
	const { activeAssistant, setActiveAssistant } = useAppContext();
	const audioRef = useRef<HTMLAudioElement | null>(null);
	let sessionData: Partial<AssignedSessionView> | null = null;

	if (token) {
		sessionData = plainToInstance(AssignedSessionView, jwtDecode(token)) as Partial<AssignedSessionView>;

		if (user) {
			if (user.email !== sessionData.email) {
				NavigateService.navToLogout();
			}
		}

		console.log(sessionData);
	}

	// Mock data for UserAssignedSessionCard

	// Mock data for UserAssignedSessionCard
	const mockSessionData: Partial<AssignedSessionView> = {
		scheduleId: "456",
		date: new Date(),
		scheduleDescription: "Schedule description",
		scheduleNumber: 3,
		scheduleCount: 4,
		assignmentTitle: "Reflection for Demo development",
		assignmentDescription: "This is a demo assignment for reflection.",
		email: "john.doe@example.com",
		fullName: "John Doe",
		firstName: "John",
		lastName: "Doe",
		courseName: "Demo Course",
		activityType: ActivityTypes.LAST,
		assignmentId: "123",
		userId: "user123",
		sessionId: "session123",
		submittedAt: new Date()
	};
	if (!sessionData) {
		sessionData = mockSessionData;
	}
	useEffect(() => {
		if (sessionData) {
			if (token && sessionData.scheduleId && sessionData.userId) {
				AssignmentService.getUpdatedCallMeNowUrl(token).then((link) => {
					// console.log("updated call me now link", link);

					const tokenPayload = jwtDecode(token);
					const linkPayload = jwtDecode(link);
					delete tokenPayload.iat;
					delete tokenPayload.exp;
					delete linkPayload.iat;
					delete linkPayload.exp;

					if (JSON.stringify(tokenPayload) !== JSON.stringify(linkPayload)) {
						NavigateService.navToUserEmailLaunch(link);
					}
					// window.location.href = link;
				});

				return;
			}
		}
	}, [token, sessionData]);

	const handleStartWebCall = () => {
		console.log("inside handleStartWebCall");
		if (!sessionData) {
			console.log("no session data");
			return;
		}
		if (!sessionData.userId) {
			console.log("no user id");
			return;
		}
		if (!sessionData.assignmentId) {
			console.log("no assignment id");
			return;
		}
		if (!sessionData.scheduleId) {
			console.log("no schedule id");
			return;
		}
		if (!sessionData.assignmentCode) {
			console.log("no assignment code");
			return;
		}
		if (!sessionData.email) {
			console.log("no email");
			return;
		}

		const userAssignmentUrl = NavigateService.getNavToUserAssignmentUrl(sessionData.assignmentId, sessionData.userId, sessionData.scheduleId);

		try {
			posthog.capture("MAJOR_EVENT: Started Web Call | Button Clicked", { userId: sessionData.userId, timestamp: new Date().toISOString() });
		} catch (err) {
			console.error("Error capturing event 'MAJOR_EVENT: Started Web Call | Button Clicked':", err);
		}

		if (sessionData.fronteggId) {
			NavigateService.navToUserAssignment(sessionData.assignmentId, sessionData.userId, sessionData.scheduleId);
		} else {
			NavigateService.navToEmailConfirm(sessionData.assignmentCode, sessionData.email, userAssignmentUrl);
		}
	};

	const getStartWebCallUrl = () => {
		if (!sessionData) {
			console.log("no session data");
			return;
		}
		if (!sessionData.userId) {
			console.log("no user id");
			return;
		}
		if (!sessionData.assignmentId) {
			console.log("no assignment id");
			return;
		}
		if (!sessionData.scheduleId) {
			console.log("no schedule id");
			return;
		}
		if (!sessionData.assignmentCode) {
			console.log("no assignment code");
			return;
		}
		if (!sessionData.email) {
			console.log("no email");
			return;
		}

		const userAssignmentUrl = NavigateService.getNavToUserAssignmentUrl(sessionData.assignmentId, sessionData.userId, sessionData.scheduleId);

		if (sessionData.fronteggId) {
			return userAssignmentUrl;
		} else {
			return NavigateService.getNavToEmailConfirmUrl(sessionData.assignmentCode, sessionData.email, userAssignmentUrl);
		}
	};

	const handleCallMe = () => {
		if (!sessionData) {
			console.log("no session data");
			return;
		}
		if (!sessionData.userId) {
			console.log("no user id");
			return;
		}
		if (!sessionData.assignmentId) {
			console.log("no assignment id");
			return;
		}
		if (!sessionData.scheduleId) {
			console.log("no schedule id");
			return;
		}
		if (!sessionData.assignmentCode) {
			console.log("no assignment code");
			return;
		}
		if (!sessionData.email) {
			console.log("no email");
			return;
		}

		const callMeUrl = NavigateService.getNavToPhoneCallUrl(sessionData.assignmentId, sessionData.scheduleId);

		try {
			posthog.capture("MAJOR_EVENT: Started Call Me Now | Button Clicked", { userId: sessionData.userId, timestamp: new Date().toISOString() });
		} catch (err) {
			console.error("Error capturing event 'MAJOR_EVENT: Started Call Me Now | Button Clicked':", err);
		}

		if (sessionData.fronteggId) {
			NavigateService.navToPhoneCall(sessionData.assignmentId, sessionData.scheduleId);
		} else {
			NavigateService.navToEmailConfirm(sessionData.assignmentCode, sessionData.email, callMeUrl);
		}
	};

	const getCallMeUrl = () => {
		if (!sessionData) {
			console.log("no session data");
			return;
		}
		if (!sessionData.userId) {
			console.log("no user id");
			return;
		}
		if (!sessionData.assignmentId) {
			console.log("no assignment id");
			return;
		}
		if (!sessionData.scheduleId) {
			console.log("no schedule id");
			return;
		}
		if (!sessionData.assignmentCode) {
			console.log("no assignment code");
			return;
		}
		if (!sessionData.email) {
			console.log("no email");
			return;
		}
		const callMeUrl = NavigateService.getNavToPhoneCallUrl(sessionData.assignmentId, sessionData.scheduleId);
		if (sessionData.fronteggId) {
			return callMeUrl;
		} else {
			return NavigateService.getNavToEmailConfirmUrl(sessionData.assignmentCode, sessionData.email, callMeUrl);
		}
	};

	const handleScheduleCall = () => {
		console.log("inside handleScheduleCall");
		if (!sessionData) {
			console.log("no session data");
			return;
		}
		if (!sessionData.assignedSessionId) {
			console.log("no assigned session id");
			return;
		}
		if (!sessionData.assignmentCode) {
			console.log("no assignment code");
			return;
		}
		if (!sessionData.email) {
			console.log("no email");
			return;
		}

		const scheduleCallUrl = NavigateService.getNavToScheduleCallUrl(sessionData.assignedSessionId);

		try {
			posthog.capture("MAJOR_EVENT: Clicked Schedule Call | Button", { userId: sessionData.userId, timestamp: new Date().toISOString() });
		} catch (err) {
			console.error("Error capturing event 'MAJOR_EVENT: Clicked Schedule Call | Button':", err);
		}

		if (sessionData.fronteggId) {
			NavigateService.navToScheduleCall(sessionData.assignedSessionId);
		} else {
			NavigateService.navToEmailConfirm(sessionData.assignmentCode, sessionData.email, scheduleCallUrl);
		}
	};

	const getScheduleCallUrl = () => {
		if (!sessionData) {
			console.log("no session data");
			return;
		}
		if (!sessionData.assignedSessionId) {
			console.log("no assigned session id");
			return;
		}
		if (!sessionData.assignmentCode) {
			console.log("no assignment code");
			return;
		}
		if (!sessionData.email) {
			console.log("no email");
			return;
		}
		const scheduleCallUrl = NavigateService.getNavToScheduleCallUrl(sessionData.assignedSessionId);
		if (sessionData.fronteggId) {
			return scheduleCallUrl;
		} else {
			return NavigateService.getNavToEmailConfirmUrl(sessionData.assignmentCode, sessionData.email, scheduleCallUrl);
		}
	};

	const handleAssistantChange = (value: string) => {
		const assistant = assistants.find((a) => a.id === value);
		if (assistant) {
			setActiveAssistant(assistant);
			localStorage.setItem("selectedAssistantId", assistant.id);

			// Play the assistant's audio
			if (audioRef.current) {
				audioRef.current.src = assistant.audio;
				audioRef.current.play().catch((error) => console.error("Error playing audio:", error));
			}
		}
	};

	return (
		<div className="container mx-auto px-4 py-8">
			<Card className="max-w-2xl mx-auto">
				<CardHeader>
					<CardTitle className="text-3xl font-bold text-center text-[#16013e] font-plus-jakarta">Welcome to Your Assignment</CardTitle>
				</CardHeader>

				<CardContent>
					{/* {sessionData.email} */}
					<div className="[&_button]:hidden">
						<UserAssignedSessionCard stat={sessionData} userId="user123" />
					</div>

					{/* Add Assistant Selection */}
					<div className="w-full mb-4">
						<h3 className="text-lg font-semibold mb-2 text-[#16013e] w-full text-center font-plus-jakarta">Choose Your Assistant</h3>
						<Select onValueChange={handleAssistantChange} defaultValue={activeAssistant.id}>
							<SelectTrigger
								id="assistant-select"
								className={cn(buttonVariants({ variant: "outline", size: "lg" }), "w-full justify-between")}
							>
								<SelectValue placeholder="Select an assistant" />
							</SelectTrigger>
							<SelectContent>
								{assistants.map((assistant) => (
									<SelectItem key={assistant.id} value={assistant.id}>
										<div className="flex items-center justify-center">
											<UserCircleIcon className="mr-2 h-5 w-5" />
											{assistant.name}
										</div>
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					</div>
				</CardContent>
				{sessionData.assignmentId && sessionData.userId && sessionData.scheduleId && (
					<CardFooter className="flex flex-col space-y-4">
						<h3 className="text-lg font-semibold mb-2 text-[#16013e] w-full text-center font-plus-jakarta">Choose an Option</h3>
						<a
							href={getStartWebCallUrl()}
							className="flex items-center justify-center w-full"
							onClick={(e) => {
								e.preventDefault();
								handleStartWebCall();
							}}
						>
							<Button className="w-full" variant="outline" size="lg">
								<Video className="mr-2 h-4 w-4" /> Start web call now
							</Button>
						</a>
						<a
							href={getCallMeUrl()}
							className="flex items-center justify-center w-full"
							onClick={(e) => {
								e.preventDefault();
								console.log("calling handleCallMe");
								handleCallMe();
							}}
						>
							<Button className="w-full" variant="outline" size="lg">
								<PhoneCall className="mr-2 h-4 w-4" /> Call me now
							</Button>
						</a>

						{sessionData.assignedSessionId && (
							<a
								href={getScheduleCallUrl()}
								className="flex items-center justify-center w-full"
								onClick={(e) => {
									e.preventDefault();
									console.log("calling handleScheduleCall");
									handleScheduleCall();
								}}
							>
								<Button className="w-full" variant="outline" size="lg">
									<Calendar className="mr-2 h-4 w-4" /> Schedule a call
								</Button>
							</a>
						)}
					</CardFooter>
				)}
			</Card>

			{/* <div className="mt-8 text-center text-sm text-gray-500 font-dm-sans">
				<p className="mb-2">Already have an account?</p>
				<Input type="email" value={sessionData.email} placeholder="student@university.com" className="max-w-xs mx-auto mb-2 font-dm-sans" />
				<Button variant="link" className="text-sm font-dm-sans">
					Send Code To Login
				</Button>
			</div> */}

			<audio ref={audioRef} style={{ display: "none" }} />
		</div>
	);
};

export default UserEmailLaunch;
