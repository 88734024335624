import React, { useState } from "react";
import { useAppContext } from "../../contexts/AppContext";
import { useAuth } from "@frontegg/react";
import { Link, useNavigate } from "react-router-dom";
import {
	HomeIcon,
	ClipboardDocumentListIcon,
	UserIcon,
	Bars3Icon,
	ArrowLeftEndOnRectangleIcon,
	ArrowTopRightOnSquareIcon,
	DocumentTextIcon,
	UsersIcon,
	EnvelopeIcon,
	RocketLaunchIcon,
	BeakerIcon,
	SignalIcon,
	ChatBubbleLeftRightIcon,
	SparklesIcon,
	CurrencyDollarIcon
} from "@heroicons/react/24/solid";
import { AdminPortal } from "@frontegg/react";
import logo from "../../assets/images/logo.png";
import { Switch } from "../../components/shadcn-ui/Switch";
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator
} from "../../components/shadcn-ui/DropdownMenu";
import { Sheet, SheetContent, SheetTrigger } from "../../components/shadcn-ui/Sheet";
import { Badge } from "../../components/shadcn-ui/Badge"; // Added Badge import
import TokenManager from "../../services/TokenManager";
import { PhoneIcon } from "lucide-react";
import { NavigateService } from "../../services/NavigateService";
import posthog from "posthog-js";
import { PromptService } from "../../services/PromptService";
import { useToast } from "../../hooks/useToast";

const Header: React.FC = () => {
	const { user } = useAuth();
	const { debugMode, setDebugMode } = useAppContext();
	const navigate = useNavigate();
	const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
	const { toast } = useToast();

	const isInstageAdmin = user?.roles?.some((role) => role.key === "InstageAdmin");

	const handleRefreshPrompts = async () => {
		try {
			const success = await PromptService.refreshPrompts();
			if (success) {
				toast({
					title: "Success",
					description: "Prompts refreshed successfully",
					variant: "default"
				});
			} else {
				toast({
					title: "Error",
					description: "Failed to refresh prompts",
					variant: "destructive"
				});
			}
		} catch (error) {
			console.log("error refreshing prompts", error);
			toast({
				title: "Error",
				description: error instanceof Error ? error.message : "An unknown error occurred",
				variant: "destructive"
			});
		}
	};

	const menuItems = [
		{ icon: HomeIcon, label: "Dashboard", onClick: () => navigate("/user"), visible: TokenManager.hasMemberClearance() },
		{ icon: DocumentTextIcon, label: "Assignments", onClick: () => navigate("/admin"), visible: TokenManager.hasFacultyClearance() },
		{
			icon: ClipboardDocumentListIcon,
			label: "Session Log",
			onClick: () => navigate("/admin/session-log"),
			visible: TokenManager.hasFacultyClearance()
		},
		{
			icon: UserIcon,
			label: "Account",
			onClick: () => {
				navigate("#/admin-box/users");
				AdminPortal.show();
			},
			visible: TokenManager.hasMemberClearance()
		},
		{
			icon: ArrowTopRightOnSquareIcon,
			label: "Simulations",
			onClick: () => {
				window.location.href = process.env.REACT_APP_INSTAGE_URL || "";
			},
			visible: TokenManager.hasMemberClearance()
		},
		{
			icon: ChatBubbleLeftRightIcon,
			label: "Live Support Chat",

			onClick: () => navigate("/support"),
			visible: TokenManager.hasMemberClearance()
		},

		{
			icon: UsersIcon, // Changed from UserIcon
			label: "Connected Users",
			onClick: () => navigate("/admin/connected-users"),
			visible: TokenManager.hasInstageAdminClearance() && debugMode
		},
		{
			icon: EnvelopeIcon, // Changed from UserIcon
			label: "Sent Emails",
			onClick: () => navigate("/admin/sent-emails"),
			visible: TokenManager.hasInstageAdminClearance() && debugMode
		},
		{
			icon: RocketLaunchIcon, // Changed from AcademicCapIcon to RocketLaunchIcon
			label: "Onboarding",
			onClick: () => NavigateService.navToOnboarding(),
			visible: TokenManager.hasInstageAdminClearance() && debugMode
		},
		{
			icon: PhoneIcon,
			label: "Phone Call",
			onClick: () => NavigateService.navToPhoneCall(),
			visible: TokenManager.hasInstageAdminClearance() && debugMode
		},
		{
			icon: CurrencyDollarIcon,
			label: "Costs",
			onClick: () => navigate("/admin/costs"),
			visible: TokenManager.hasInstageAdminClearance() && debugMode
		},
		{
			icon: SignalIcon, // Changed from AcademicCapIcon to RocketLaunchIcon
			label: "Provider Status",
			onClick: () => window.open("https://status.instage.io", "_blank"), // Updated to open in a new tab
			visible: TokenManager.hasInstageAdminClearance() && debugMode
		},
		{
			icon: SparklesIcon,
			label: "Refresh Prompts",
			onClick: handleRefreshPrompts,
			visible: TokenManager.hasInstageAdminClearance() && debugMode
		},
		{
			icon: ArrowLeftEndOnRectangleIcon,
			label: user ? "Log Out" : "Log In",

			onClick: () => {
				sessionStorage.clear();
				console.log("MAJOR_EVENT: User Logged Out");
				posthog.capture("MAJOR_EVENT: User Logged Out", {
					timestamp: new Date().toISOString()
				});
				navigate("/account/logout");
			},
			visible: TokenManager.hasMemberClearance()
		}
	];

	const visibleMenuItems = menuItems.filter((item) => item.visible);

	const renderMenuItems = (isMobile = false) => (
		<>
			{menuItems.map(
				(item, index) =>
					item.visible &&
					(!isMobile || !item.label.includes("Debug") || debugMode) && (
						<React.Fragment key={item.label}>
							{isMobile ? (
								<button
									onClick={() => {
										item.onClick();
										setMobileMenuOpen(false);
									}} // Close menu on click
									className="flex items-center space-x-2 text-left py-2"
								>
									<item.icon className="h-5 w-5" />
									<span>{item.label}</span>
								</button>
							) : (
								<DropdownMenuItem
									onSelect={() => {
										item.onClick();
										if (isMobile) setMobileMenuOpen(false);
									}} // Close menu on click
									className="cursor-pointer"
								>
									<item.icon className="h-5 w-5 mr-2" />
									<span>{item.label}</span>
								</DropdownMenuItem>
							)}
							{index === visibleMenuItems.length - 2 &&
								(isMobile ? <div className="h-px bg-gray-200 my-2" /> : <DropdownMenuSeparator />)}
						</React.Fragment>
					)
			)}
		</>
	);

	return (
		<header className="bg-white border-b border-[#eaecf0] h-16">
			<div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl h-full">
				<div className="flex justify-between items-center h-full">
					<div className="flex items-center space-x-4">
						<a href="/user" className="text-[#00a9af] hover:text-gray-200 transition-colors duration-200">
							<img src={logo} alt="InStage Logo" className="h-8 w-auto" />
						</a>
						<Badge variant="secondary" className="text-xs bg-[#ffe8c4] text-[#945e0c] rounded-md px-2 py-1 flex items-center">
							<BeakerIcon className="h-3 w-3 mr-1" />
							Beta
						</Badge>
					</div>
					{user && (
						<div className="flex items-center space-x-4">
							{isInstageAdmin && <Switch id="debug-mode" checked={debugMode} onCheckedChange={setDebugMode} className="mr-2" />}
							{/* Desktop menu */}
							<div className="hidden md:block h-8">
								<DropdownMenu>
									<DropdownMenuTrigger asChild>
										<button className="text-[#00a9af] hover:text-[#008c91] transition-colors duration-200 focus:outline-none">
											<Bars3Icon className="h-8 w-8" />
										</button>
									</DropdownMenuTrigger>
									<DropdownMenuContent align="end" className="w-56">
										{renderMenuItems()}
									</DropdownMenuContent>
								</DropdownMenu>
							</div>

							{/* Mobile menu */}
							<div className="md:hidden h-8">
								<Sheet open={isMobileMenuOpen} onOpenChange={setMobileMenuOpen}>
									{" "}
									{/* Control open state */}
									<SheetTrigger asChild>
										<button className="text-[#00a9af] hover:text-[#008c91] transition-colors duration-200 focus:outline-none">
											<Bars3Icon className="h-8 w-8" />
										</button>
									</SheetTrigger>
									<SheetContent side="right" className="w-[300px] sm:w-[400px] pt-12">
										<nav className="flex flex-col space-y-4">{renderMenuItems(true)}</nav>
									</SheetContent>
								</Sheet>
							</div>
						</div>
					)}
				</div>
			</div>
		</header>
	);
};

export default Header;
