import axios from "axios";
import { plainToInstance } from "class-transformer";
import { LogAxiosError } from "./AxiosUtility";
import { ClientCostSummaryView } from "../types/ClientCostSummaryView";
import { SessionCostSummaryView } from "../types/SessionCostSummaryView";
import { UserCostSummaryView } from "../types/UserCostSummaryView";

export class CostService {
	private static baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/costs`;

	public static async getClientCost(clientId: string): Promise<ClientCostSummaryView> {
		try {
			const response = await axios.get(`${this.baseUrl}/client/${clientId}`);
			return plainToInstance(ClientCostSummaryView, response.data);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get client cost ${clientId}`);
			throw new Error(errorMessage);
		}
	}

	public static async getClientsCost(): Promise<ClientCostSummaryView[]> {
		try {
			const response = await axios.get(`${this.baseUrl}/clients`);
			return plainToInstance(ClientCostSummaryView, response.data as ClientCostSummaryView[]);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get clients cost`);
			throw new Error(errorMessage);
		}
	}

	public static async getTopClientsCost(limit: number): Promise<ClientCostSummaryView[]> {
		try {
			const response = await axios.get(`${this.baseUrl}/clients/top?limit=${limit}`);
			return plainToInstance(ClientCostSummaryView, response.data as ClientCostSummaryView[]);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get top clients cost ${limit}`);
			throw new Error(errorMessage);
		}
	}

	public static async getClientsCostByDateRange(startDate: string, endDate: string): Promise<ClientCostSummaryView[]> {
		try {
			const response = await axios.get(`${this.baseUrl}/clients/date-range?startDate=${startDate}&endDate=${endDate}`);
			return plainToInstance(ClientCostSummaryView, response.data as ClientCostSummaryView[]);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get clients cost by date range ${startDate} ${endDate}`);
			throw new Error(errorMessage);
		}
	}

	public static async getSessionsCosts(): Promise<SessionCostSummaryView[]> {
		try {
			const response = await axios.get(`${this.baseUrl}/sessions`);
			return plainToInstance(SessionCostSummaryView, response.data as SessionCostSummaryView[]);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get sessions cost`);
			throw new Error(errorMessage);
		}
	}

	public static async getUserCosts(): Promise<UserCostSummaryView[]> {
		try {
			const response = await axios.get(`${this.baseUrl}/users`);
			return plainToInstance(UserCostSummaryView, response.data as UserCostSummaryView[]);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get users cost`);
			throw new Error(errorMessage);
		}
	}

	public static async getUserCostById(userId: string): Promise<UserCostSummaryView> {
		try {
			const response = await axios.get(`${this.baseUrl}/user/${userId}`);
			return plainToInstance(UserCostSummaryView, response.data);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get user cost ${userId}`);
			throw new Error(errorMessage);
		}
	}

	public static async getUsersCostByClient(clientId: string): Promise<UserCostSummaryView[]> {
		try {
			const response = await axios.get(`${this.baseUrl}/users/client/${clientId}`);
			return plainToInstance(UserCostSummaryView, response.data as UserCostSummaryView[]);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get users cost by client ${clientId}`);
			throw new Error(errorMessage);
		}
	}

	public static async getUsersCostByAssignment(assignmentId: string): Promise<UserCostSummaryView[]> {
		try {
			const response = await axios.get(`${this.baseUrl}/users/assignment/${assignmentId}`);
			return plainToInstance(UserCostSummaryView, response.data as UserCostSummaryView[]);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get users cost by assignment ${assignmentId}`);
			throw new Error(errorMessage);
		}
	}

	public static async getSessionCost(sessionId: string): Promise<SessionCostSummaryView> {
		try {
			const response = await axios.get(`${this.baseUrl}/session/${sessionId}`);
			return plainToInstance(SessionCostSummaryView, response.data);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get session cost ${sessionId}`);
			throw new Error(errorMessage);
		}
	}

	public static async getSessionsCostByUser(userId: string): Promise<SessionCostSummaryView[]> {
		try {
			const response = await axios.get(`${this.baseUrl}/sessions/user/${userId}`);
			return plainToInstance(SessionCostSummaryView, response.data as SessionCostSummaryView[]);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get sessions cost by user ${userId}`);
			throw new Error(errorMessage);
		}
	}

	public static async getSessionsCostByClient(clientId: string): Promise<SessionCostSummaryView[]> {
		try {
			const response = await axios.get(`${this.baseUrl}/sessions/client/${clientId}`);
			return plainToInstance(SessionCostSummaryView, response.data as SessionCostSummaryView[]);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get sessions cost by client ${clientId}`);
			throw new Error(errorMessage);
		}
	}
}
