import React from "react";
import { Table } from "@tanstack/react-table";
import { Input } from "./Input";
import { cn } from "../../lib/utils";
import { CalendarIcon } from "@radix-ui/react-icons"; // Import the CalendarIcon

interface DataTableInputFilterProps<TData> {
	table: Table<TData>;
	columnId: string;
	placeholder?: string;
	type?: string; // Add type prop to determine if it's a date input
}

export function DataTableInputFilter<TData>({
	table,
	columnId,
	placeholder,
	type = "text" // Default to text input
}: DataTableInputFilterProps<TData>) {
	const column = table.getColumn(columnId);

	return (
		<div className="relative max-w-sm">
			{type === "date" && <CalendarIcon className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-500" />}

			<Input
				type={type}
				placeholder={placeholder || "Search"}
				value={(column?.getFilterValue() as string) ?? ""}
				onChange={(event) => column?.setFilterValue(event.target.value)}
				className={cn(
					"max-w-sm border-none focus:ring-0 focus:outline-none",
					type === "date" && "pl-8" // Add left padding when it's a date input
				)}
			/>
		</div>
	);
}
