import axios from "axios";
import { LogAxiosError } from "./AxiosUtility";

// export interface EventType {
// 	id: number;
// 	lengthInMinutes: number;
// 	title: string;
// 	slug: string;
// 	description: string;
// 	locations: Array<{
// 		type: string;
// 		address: string;
// 		public: boolean;
// 	}>;
// 	bookingFields: Array<{
// 		isDefault: boolean;
// 		slug: string;
// 		type: string;
// 		required: boolean;
// 	}>;
// 	disableGuests: boolean;
// 	slotInterval: number;
// 	minimumBookingNotice: number;
// 	beforeEventBuffer: number;
// 	afterEventBuffer: number;
// 	recurrence: Record<string, unknown>;
// 	metadata: Record<string, unknown>;
// 	price: number;
// 	currency: string;
// 	lockTimeZoneToggleOnBookingPage: boolean;
// 	seatsPerTimeSlot: number;
// 	forwardParamsSuccessRedirect: Record<string, unknown>;
// 	successRedirectUrl: Record<string, unknown>;
// 	isInstantEvent: boolean;
// 	seatsShowAvailabilityCount: Record<string, unknown>;
// 	scheduleId: Record<string, unknown>;
// 	bookingLimitsCount: Record<string, unknown>;
// 	onlyShowFirstAvailableSlot: boolean;
// 	bookingLimitsDuration: Record<string, unknown>;
// 	bookingWindow: Array<{
// 		type: string;
// 		value: number;
// 		rolling: boolean;
// 	}>;
// 	bookerLayouts: {
// 		defaultLayout: string;
// 		enabledLayouts: string[];
// 	};
// 	confirmationPolicy: Record<string, unknown>;
// 	requiresBookerEmailVerification: boolean;
// 	hideCalendarNotes: boolean;
// 	color: {
// 		lightThemeHex: string;
// 		darkThemeHex: string;
// 	};
// 	seats: {
// 		seatsPerTimeSlot: number;
// 		showAttendeeInfo: boolean;
// 		showAvailabilityCount: boolean;
// 	};
// 	offsetStart: number;
// 	customName: string;
// 	destinationCalendar: {
// 		integration: string;
// 		externalId: string;
// 	};
// 	useDestinationCalendarEmail: boolean;
// 	hideCalendarEventDetails: boolean;
// 	ownerId: number;
// 	users: string[];
// }

// export interface EventTypeResponse {
// 	status: string;
// 	data: {
// 		eventType: EventType;
// 		currentUserMembership: any;
// 		destinationCalendar: any;
// 		isUserOrganizationAdmin: boolean;
// 		locationOptions: any[];
// 		team: any;
// 		teamMembers: any[];
// 		// Add other properties as needed
// 	};
// }

export interface Slot {
	time: string;
	attendees?: number;
}

interface BookingData {
	start: string;
	eventTypeId: number;
	attendee: {
		name: string;
		email: string;
		timeZone: string;
	};
	phone: string;
	metadata: {
		assignedSessionId: string;
		provider: string;
		voiceId: string;
		assistantName: string;
	};
}

interface GetBookingResponse {
	status: string;
	data: {
		id: number;
		uid: string;
		title: string;
		description: string | null;
		hosts: Array<{
			id: number;
			name: string;
			timeZone: string;
		}>;
		status: string;
		start: string;
		end: string;
		duration: number;
		eventTypeId: number;
		eventType: {
			id: number;
			slug: string;
		};
		meetingUrl: string;
		location: string;
		absentHost: boolean;
		attendees: Array<{
			name: string;
			timeZone: string;
			language: string;
			absent: boolean;
			seatUid: string;
			bookingFieldsResponses: {
				name: string;
				notes: string;
				phone: string;
				guests: string[];
			};
		}>;
	};
}

interface CancelBookingResponse {
	status: string;
	data: {
		id: number;
		uid: string;
		status: string;
		// ... other fields as needed
	};
}

export class CalComService {
	private static baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api-public/calcom`;

	// public static async getEventType(eventTypeId: string): Promise<EventTypeResponse> {
	// 	const response = await axios.get(`${this.baseUrl}/event-type`, {
	// 		params: {
	// 			eventTypeId
	// 		}
	// 	});
	// 	console.log("EventTypeResponse:", response.data);
	// 	return response.data;
	// }

	public static async getAvailableSlots(startTime: string, endTime: string, eventTypeId: string, eventTypeSlug: string): Promise<Slot[]> {
		try {
			const response = await axios.get(`${this.baseUrl}/available-slots`, {
				params: {
					startTime,
					endTime,
					eventTypeId,
					eventTypeSlug
				}
			});
			return response.data;
		} catch (error) {
			const errorMessage = LogAxiosError(error, `available slots ${startTime} ${endTime} ${eventTypeId} ${eventTypeSlug}`);
			throw new Error(errorMessage);
		}
	}

	public static async createBooking(bookingData: BookingData): Promise<any> {
		try {
			const response = await axios.post(`${this.baseUrl}/create-booking`, bookingData);
			return response.data;
		} catch (error) {
			const errorMessage = LogAxiosError(error, `create booking ${bookingData.eventTypeId} ${bookingData.attendee.email}`);
			throw new Error(errorMessage);
		}
	}

	public static async getBooking(bookingId: string): Promise<GetBookingResponse> {
		try {
			const response = await axios.get(`${this.baseUrl}/get-booking`, {
				params: { bookingId }
			});
			return response.data;
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get booking ${bookingId}`);
			throw new Error(errorMessage);
		}
	}

	public static async cancelBooking(bookingId: string, seatUid?: string): Promise<CancelBookingResponse> {
		try {
			const response = await axios.post(`${this.baseUrl}/cancel-booking`, {
				bookingId,
				seatUid
			});
			return response.data;
		} catch (error) {
			const errorMessage = LogAxiosError(error, `cancel booking ${bookingId} ${seatUid}`);
			throw new Error(errorMessage);
		}
	}
}
