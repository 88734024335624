import React from "react";
import { AssignedSessionView } from "../../types/AssignedSessionView";
import { Card, CardHeader, CardContent, CardFooter } from "../../components/shadcn-ui/Card";
import SessionCount from "./SessionCount";
import { Button } from "../../components/shadcn-ui/Button";
import { NavigateService } from "../../services/NavigateService";

interface CompletedSessionCardProps {
	stat: AssignedSessionView;
	userId: string | null;
}

const CompletedSessionCard: React.FC<CompletedSessionCardProps> = ({ stat, userId }) => {
	const viewSessionSummary = () => {
		if (!userId) return;
		NavigateService.navToSessionSummary(stat.assignmentId, userId, stat.scheduleId);
	};

	return (
		<Card className="mb-4 min-h-[250px] flex flex-col shadow-md">
			<CardHeader>
				<h2 className="text-xl font-semibold text-[#00a9af]">{stat.assignmentTitle}</h2>
				<p className="text-gray-600">{stat.courseName}</p>
			</CardHeader>
			<CardContent className="flex-grow">
				<div className="mb-4 w-full">
					<SessionCount currentCount={stat.scheduleNumber} totalCount={stat.scheduleCount} />
				</div>
				<p className="text-sm text-gray-600">Completed on: {stat.date.toLocaleDateString()}</p>
			</CardContent>
			<CardFooter>
				<Button variant="outlinePrimary" size="sm" onClick={viewSessionSummary}>
					View Summary
				</Button>
			</CardFooter>
		</Card>
	);
};

export default CompletedSessionCard;
