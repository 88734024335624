import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "../shadcn-ui/Button";
import {
	AlertDialog,
	AlertDialogTrigger,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogFooter,
	AlertDialogTitle,
	AlertDialogDescription
} from "../shadcn-ui/AlertDialog";
import { NavigateService } from "../../services/NavigateService";
import { useAppContext, QueueItem, VapiCallList } from "../../contexts/AppContext";
import { DataTable, ExtendedColumnDef } from "../shadcn-ui/DataTable";
import { DataTableActionMenu } from "../../components/shadcn-ui/DataTableActionMenu";
import { DataTableColumnHeader } from "../../components/shadcn-ui/DataTableColumnHeader";

const QueuePage: React.FC = () => {
	const { assignmentId, userId, sessionId } = useParams<{ assignmentId: string; userId: string; sessionId: string }>();
	const navigate = useNavigate();
	const [isInQueue, setIsInQueue] = useState<boolean>(false);
	const [position, setPosition] = useState<number>(0);
	const [timeLeft, setTimeLeft] = useState<number>(0);
	const [joinTimeout, setJoinTimeout] = useState<number>(30);
	const {
		emit,
		on,
		off,
		queueData,
		activeUsers,
		vapiCalls,
		canJoin,
		setCanJoin, // Add this line
		checkIfAvailable,
		joinQueue,
		moveToActive,
		moveDownQueue,
		removeFromQueue,
		removeFromActiveUsers,
		debugMode
	} = useAppContext();

	// 	const [selectedUserId, setSelectedUserId] = useState<string>(userId || "");

	useEffect(() => {
		if (userId) {
			try {
				joinQueue(userId);
			} catch (error) {
				console.error("Error joining queue:", error);
			}
		}
		const handlePositionUpdate = (data: { position: number }) => {
			console.log("positionUpdate", data);
			setPosition(data.position);
		};
		on("positionUpdate", handlePositionUpdate);

		return () => {
			if (userId) {
				removeFromQueue(userId);
			}
			off("positionUpdate", handlePositionUpdate);
		};
	}, [userId, on, off]);

	useEffect(() => {
		queueData.forEach((item) => {
			if (item.userId === userId) {
				setPosition(item.position);
			}
		});
	}, [userId, queueData]);

	useEffect(() => {
		if (canJoin && joinTimeout > 0) {
			const timer = setInterval(() => {
				setJoinTimeout((prev) => prev - 1);
			}, 1000);
			return () => clearInterval(timer);
		}
		if (joinTimeout === 0 && userId) {
			moveDownQueue(userId);
			setCanJoin(false); // Now this line will work correctly
		}
	}, [canJoin, joinTimeout, userId, moveDownQueue, setCanJoin]);

	useEffect(() => {
		const checkIfAvailable = async () => {
			const data = await emit("checkIfAvailable", { userId });
			console.log(data);
		};
		const interval = setInterval(() => {
			checkIfAvailable();
		}, 5000);
		return () => clearInterval(interval);
	}, [emit, userId]);

	const handleStartSession = () => {
		// Placeholder function to handle joining the session
		console.log("Joining session...");
		if (assignmentId && userId && sessionId) {
			moveToActive(userId);
			if (assignmentId === "onboarding") {
				NavigateService.navToOnboardingSession(userId, sessionId);
			} else {
				NavigateService.navToStartSession(assignmentId, userId, sessionId);
			}
		}
	};

	const handleRemoveFromQueue = (userId: string) => {
		console.log("Removing user from queue:", userId);
		// if (selectedUserId === userId) {
		// 	setSelectedUserId("");
		// }
		removeFromQueue(userId);
	};

	const handleRemoveFromActive = (userId: string) => {
		console.log("Removing user from active:", userId);
		// if (selectedUserId === userId) {
		// 	setSelectedUserId("");
		// }
		removeFromActiveUsers(userId);
	};

	const handleTimeout = () => {
		// Placeholder function to handle timeout
		console.log("Timeout, lost spot in queue.");
		navigate(-1);
	};

	// Update the columns for the queue table
	const columnsQueue: ExtendedColumnDef<QueueItem, any>[] = [
		{
			accessorKey: "position",
			header: "Position"
		},
		{
			accessorKey: "userId",
			header: "User ID"
		},
		{
			accessorKey: "joinedAt",
			header: "Joined At",
			cell: ({ row }) => {
				const date = row.getValue("joinedAt") as Date;
				return <div>{date.toLocaleString()}</div>;
			}
		},
		{
			accessorKey: "socketId",
			header: "Socket ID",
			cell: ({ row }) => {
				const socketId = row.getValue("socketId") as string;
				return <div>{socketId}</div>;
			}
		},
		{
			accessorKey: "canMoveToActive",
			header: ({ column }) => <DataTableColumnHeader column={column} title="Can Move to Active" />,
			cell: ({ row }) => {
				const canMove = row.getValue("canMoveToActive") as boolean;
				return <div>{canMove ? "Yes" : "No"}</div>;
			}
		},
		{
			accessorKey: "clearanceLevel",
			header: ({ column }) => <DataTableColumnHeader column={column} title="Clearance Level" />,
			cell: ({ row }) => {
				const clearanceLevel = row.getValue("clearanceLevel") as number;
				return <div>{clearanceLevel}</div>;
			}
		},
		{
			id: "actions",
			cell: ({ row }) => {
				const queueItem = row.original;
				return (
					<DataTableActionMenu
						label="Actions"
						copyId={queueItem.userId}
						menuItems={[
							// { label: "Select User", onClick: () => setSelectedUserId(queueItem.userId) },
							{ label: "Remove from Queue", onClick: () => handleRemoveFromQueue(queueItem.userId) },
							{ label: "Move to Active", onClick: () => moveToActive(queueItem.userId), hidden: !queueItem.canMoveToActive }
						]}
					/>
				);
			}
		}
	];

	// Update the columns for the active users table
	const columnsActive: ExtendedColumnDef<QueueItem, any>[] = [
		{
			accessorKey: "position",
			header: "Position"
		},
		{
			accessorKey: "userId",
			header: "User ID"
		},
		{
			accessorKey: "socketId",
			header: "Socket ID",
			cell: ({ row }) => {
				const socketId = row.getValue("socketId") as string;
				return <div>{socketId}</div>;
			}
		},
		{
			accessorKey: "joinedAt",
			header: "Joined At",
			cell: ({ row }) => {
				const date = row.getValue("joinedAt") as Date;
				return <div>{date.toLocaleString()}</div>;
			}
		},
		{
			accessorKey: "clearanceLevel",
			header: ({ column }) => <DataTableColumnHeader column={column} title="Clearance Level" />,
			cell: ({ row }) => {
				const clearanceLevel = row.getValue("clearanceLevel") as number;
				return <div>{clearanceLevel}</div>;
			}
		},
		{
			id: "actions",
			cell: ({ row }) => {
				const queueItem = row.original;
				return (
					<DataTableActionMenu
						label="Actions"
						copyId={queueItem.userId}
						menuItems={[{ label: "Remove from active", onClick: () => handleRemoveFromActive(queueItem.userId) }]}
					/>
				);
			}
		}
	];

	const vapiCallsColumns: ExtendedColumnDef<VapiCallList, any>[] = [
		{
			accessorKey: "id",
			header: "ID"
		},
		{
			accessorKey: "name",
			header: "Name"
		}
	];
	return (
		<div className="container mx-auto p-4">
			<h1 className="text-2xl font-bold mb-4">Waiting in Queue</h1>
			<p className="mb-2">Please do not refresh the page. You will lose your spot in the queue.</p>
			<p className="mb-2">Your position in the queue: {position}</p>
			<p className="mb-2">Estimated wait time: {position * 5} minutes</p>
			<div className="space-y-4">
				{canJoin && (
					<AlertDialog open={canJoin}>
						{/* <AlertDialogTrigger asChild>
							<Button variant="default">Start Session</Button>
						</AlertDialogTrigger> */}
						<AlertDialogContent>
							<AlertDialogHeader>
								<AlertDialogTitle>Join Session</AlertDialogTitle>
								<AlertDialogDescription>You have {joinTimeout} seconds to join the session.</AlertDialogDescription>
							</AlertDialogHeader>
							<AlertDialogFooter>
								<Button variant="default" onClick={handleStartSession}>
									Join Now
								</Button>
								<Button variant="outline" onClick={handleTimeout}>
									Cancel
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialog>
				)}
			</div>
			{/* <Button variant="outline" onClick={() => navigate(-1)} className="mt-4">
				Go Back
			</Button> */}
			{/* <Button variant="outline" onClick={() => checkIfAvailable(userId || "")} className="mt-4">
				Refresh
			</Button>
			<Button variant="outline" onClick={() => joinQueue(userId || "")} className="mt-4">
				Join
			</Button> */}
			{/* <div className="mt-4">
				<p>Selected User ID: {selectedUserId}</p>
			</div> */}
			{/* Queue Data Table */}

			{debugMode && (
				<>
					<div className="mb-4">
						<h2 className="text-2xl font-bold mb-4">Users in Queue</h2>
						<DataTable columns={columnsQueue} data={queueData} />
					</div>
					<div className="mb-4">
						<h2 className="text-2xl font-bold mb-4">Users in Session</h2>
						<DataTable columns={columnsActive} data={activeUsers} />
					</div>
					<div className="mb-4">
						<h2 className="text-2xl font-bold mb-4">Vapi Calls</h2>
						<DataTable columns={vapiCallsColumns} data={vapiCalls} />
					</div>
				</>
			)}
		</div>
	);
};

export default QueuePage;
