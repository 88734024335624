import { CommentView } from "../types/CommentView";
import { plainToInstance } from "class-transformer";
import axios from "axios";
import { LogAxiosError } from "./AxiosUtility";

export class CommentService {
	private static baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/comments`;

	static async findAll(): Promise<CommentView[]> {
		try {
			const response = await axios.get(`${this.baseUrl}`);
			return response.data.map((comment: object) => plainToInstance(CommentView, comment));
		} catch (error) {
			const errorMessage = LogAxiosError(error, `find all comments`);
			throw new Error(errorMessage);
		}
	}

	static async getCommentById(commentId: string): Promise<CommentView | null> {
		try {
			const response = await axios.get(`${this.baseUrl}/${commentId}`);
			if (response.data) {
				return plainToInstance(CommentView, response.data);
			}
			return null;
		} catch (error) {
			const errorMessage = LogAxiosError(error, `get comment by id ${commentId}`);
			throw new Error(errorMessage);
		}
	}

	static async createComment(label: string, content: string, assignedSessionId: string): Promise<CommentView | null> {
		try {
			const response = await axios.post(`${this.baseUrl}`, {
				label,
				content,

				assignedSessionId
			});
			if (response.data) {
				return plainToInstance(CommentView, response.data);
			}
			return null;
		} catch (error) {
			const errorMessage = LogAxiosError(error, `create comment ${label} ${content} ${assignedSessionId}`);
			throw new Error(errorMessage);
		}
	}

	static async updateComment(commentId: string, label: string, content: string): Promise<void> {
		try {
			await axios.put(`${this.baseUrl}/${commentId}`, {
				label,
				content
			});
		} catch (error) {
			const errorMessage = LogAxiosError(error, `update comment ${commentId} ${label} ${content}`);
			throw new Error(errorMessage);
		}
	}

	static async deleteComment(commentId: string): Promise<void> {
		try {
			await axios.delete(`${this.baseUrl}/${commentId}`);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `delete comment ${commentId}`);
			throw new Error(errorMessage);
		}
	}
}
