import React, { useState, useCallback, useEffect } from "react";
import { Field, ErrorMessage } from "formik";
import { ClipboardIcon, PaperClipIcon, XCircleIcon, CheckIcon } from "@heroicons/react/20/solid";
import { useFormContext } from "../FormContext";
import Papa from "papaparse";
import { Button } from "../../../shadcn-ui/Button";
import { Checkbox } from "../../../shadcn-ui/Checkbox";
import { AssignmentService } from "../../../../services/AssignmentService";
import { InfoIcon } from "lucide-react";
import { StudentNotications } from "../../../../types/AssignmentPayload";
import CustomTooltip from "../../../Utility/CustomTooltip";

// Define props for the AssignmentDistribution component
export interface AssignmentDistributionProps {
	values: any;
	errors: any;
	touched: any;
	setFieldValue: (field: string, value: any) => void;
	onNext: () => void;
	onPrevious: () => void;
	isValid: boolean;
	dirty: boolean;
	isEditMode: boolean;
}

// AssignmentDistribution component for configuring assignment distribution settings
const AssignmentDistribution: React.FC<AssignmentDistributionProps> = ({
	values,
	errors,
	touched,
	setFieldValue,
	onNext,
	onPrevious,
	isValid,
	dirty,
	isEditMode
}) => {
	const { updateFormValues } = useFormContext();
	const [assignmentCode, setAssignmentCode] = useState(values.assignmentCode || "");
	const [emails, setEmails] = useState<string[]>(values.recipientList ? values.recipientList.split(",").map((e: string) => e.trim()) : []);
	const [inputValue, setInputValue] = useState("");
	const [copySuccess, setCopySuccess] = useState(false);

	// Fetch new assignment code if not already set
	useEffect(() => {
		const fetchNewAssignmentCode = async () => {
			const code = await AssignmentService.getNewAssignmentCode();
			setAssignmentCode(code);
			console.log("code", code);
			setFieldValue("assignmentCode", code);
		};

		console.log("values.assignmentCode", values.assignmentCode);
		if (!values.assignmentCode || values.assignmentCode === "") {
			fetchNewAssignmentCode();
		}
	}, [values, setFieldValue]);

	// Set default values for notification checkboxes
	useEffect(() => {
		if (values.notifyAvailabilityWindow === undefined) {
			setFieldValue("notifyAvailabilityWindow", true);
		}
		if (values.notify24HoursBefore === undefined) {
			setFieldValue("notify24HoursBefore", false);
		}
		if (values.notifyDueDate === undefined) {
			setFieldValue("notifyDueDate", true);
		}
	}, [values, setFieldValue]);

	// Copy text to clipboard
	const copyToClipboard = useCallback((text: string) => {
		navigator.clipboard.writeText(text);
		setCopySuccess(true);
		setTimeout(() => {
			setCopySuccess(false);
		}, 2000); // Reset after 2 seconds
	}, []);

	// Handle CSV file upload
	const handleFileUpload = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const file = event.target.files?.[0];
			if (file) {
				if (file.type !== "text/csv") {
					alert("Please upload a CSV file.");
					return;
				}
				Papa.parse(file, {
					complete: (results) => {
						const extractedEmails = new Set<string>();
						results.data.forEach((row: any) => {
							row.forEach((cell: any) => {
								const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
								const matches = cell.match(emailRegex);
								if (matches) {
									matches.forEach((email: string) => extractedEmails.add(email.toLowerCase()));
								}
							});
						});
						const newEmails = Array.from(extractedEmails).filter((email) => !emails.includes(email));
						if (newEmails.length > 500) {
							newEmails.length = 500; // Silently limit to 500 new emails
						}
						const updatedEmails = [...emails, ...newEmails];
						setEmails(updatedEmails);
						setFieldValue("recipientList", updatedEmails.join(","));
						updateFormValues({ ...values, recipientList: updatedEmails.join(",") });
					},
					error: (error) => {
						console.error("Error parsing CSV:", error);
						alert("An error occurred while parsing the CSV file.");
					}
				});
			}
			// Clear the file input
			event.target.value = "";
		},
		[emails, setFieldValue, updateFormValues, values]
	);
	// Handle input change for email field
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
	};

	// Handle key press in email input field
	const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			addEmail();
		}
	};

	// Add email to the list
	const addEmail = () => {
		const trimmedEmail = inputValue.trim();
		if (trimmedEmail && !emails.includes(trimmedEmail)) {
			const updatedEmails = [...emails, trimmedEmail];
			setEmails(updatedEmails);
			setFieldValue("recipientList", updatedEmails.join(","));
			updateFormValues({ ...values, recipientList: updatedEmails.join(",") });
			setInputValue("");
		}
	};

	// Remove email from the list
	const removeEmail = (emailToRemove: string) => {
		const updatedEmails = emails.filter((email) => email !== emailToRemove);
		setEmails(updatedEmails);
		setFieldValue("recipientList", updatedEmails.join(","));
		updateFormValues({ ...values, recipientList: updatedEmails.join(",") });
	};

	return (
		<div className="space-y-8">
			<h2 className="text-2xl font-semibold text-gray-800 mb-6">Assignment Distribution</h2>

			<div className="space-y-6">
				{!isEditMode && (
					<div className="bg-white p-6 rounded-lg border border-[#eaecf0]">
						<h3 className="text-lg font-medium text-gray-900 mb-2">Option 1: Email Distribution</h3>
						<p className="text-sm text-gray-600 mb-4">Send an email invitation to students with assignment details.</p>
						<div className="space-y-4">
							<div>
								<label htmlFor="recipientList" className="block text-sm font-medium text-gray-700 mb-2">
									Recipient List
								</label>
								<div className="flex flex-wrap gap-2 p-2 border rounded-lg focus-within:border-[#00a9af]">
									{emails.slice(0, 10).map((email, index) => (
										<div key={index} className="flex items-center bg-gray-100 rounded-full px-3 py-1">
											<span className="text-sm">{email}</span>
											<button
												type="button"
												onClick={() => removeEmail(email)}
												className="ml-2 text-gray-500 hover:text-gray-700"
											>
												<XCircleIcon className="h-4 w-4" />
											</button>
										</div>
									))}
									{emails.length > 10 && (
										<div className="flex items-center bg-gray-100 rounded-full px-3 py-1">
											<span className="text-sm">... {emails.length - 10} more</span>
										</div>
									)}
									<input
										type="text"
										value={inputValue}
										onChange={handleInputChange}
										onKeyDown={handleInputKeyDown}
										onBlur={addEmail}
										className="flex-grow outline-none"
										placeholder="Enter email addresses"
									/>
								</div>
								<ErrorMessage name="recipientList" component="div" className="mt-1 text-sm text-red-600" />
							</div>
							<div>
								<label htmlFor="csvUpload" className="block text-sm font-medium text-gray-700 mb-2">
									Or upload a CSV file with email addresses
								</label>
								<div className="mt-1 flex items-center">
									<input
										id="csvUpload"
										name="csvUpload"
										type="file"
										accept=".csv"
										onChange={handleFileUpload}
										className="sr-only"
									/>
									<label
										htmlFor="csvUpload"
										className="cursor-pointer bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#00a9af]"
									>
										<PaperClipIcon className="h-5 w-5 inline-block mr-2" />
										Upload CSV with Emails
									</label>
								</div>
							</div>
						</div>
					</div>
				)}

				<div className="bg-white p-6 rounded-lg border border-[#eaecf0]">
					<h3 className="text-lg font-medium text-gray-900 mb-2">Option 2: Share Assignment Link</h3>
					<p className="text-sm text-gray-600 mb-4">Share this link with students (Via LMS, Email)</p>
					<div className="space-y-4">
						{/* <div>
				<label className="block text-sm font-medium text-gray-700 mb-2">Assignment Code</label>
					<div className="flex items-center space-x-2">
						<input
							type="text"
							value={assignmentCode}
							readOnly
							className="flex-grow px-3 py-2 bg-gray-100 rounded-md border border-gray-300 focus:outline-none"
						/>
						<Button variant="outlinePrimary" onClick={() => copyToClipboard(assignmentCode)}>
							<ClipboardIcon className="h-5 w-5 mr-1" />
							Copy
						</Button>
					</div>
				</div> */}
						<div>
							<label className="block text-sm font-medium text-gray-700 mb-2">Assignment Link</label>
							<div className="flex items-center space-x-2">
								<input
									type="text"
									value={`${window.location.origin}/assignment/${assignmentCode}`}
									readOnly
									className="flex-grow px-3 py-2 h-9 bg-gray-100 rounded-md border border-gray-300 focus:outline-none"
								/>
								<Button
									variant="outlinePrimary"
									onClick={() => copyToClipboard(`${window.location.origin}/assignment/${assignmentCode}`)}
									className="w-28"
								>
									{copySuccess ? (
										<>
											<CheckIcon className="h-5 w-5 mr-1" />
											Copied
										</>
									) : (
										<>
											<ClipboardIcon className="h-5 w-5 mr-1" />
											Copy
										</>
									)}
								</Button>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-white p-6 rounded-lg border border-[#eaecf0]">
					<h3 className="text-lg font-medium text-gray-900 mb-2">Student Notifications</h3>
					<div className="mt-2 space-y-3">
						<div className="flex items-center space-x-3">
							<Checkbox id="notifyEnrollment" checked disabled />
							<label htmlFor="notifyEnrollment" className="text-sm text-gray-700">
								Enrollment notification email
							</label>
							<CustomTooltip
								content="This email is sent to students when they are enrolled in an assignment. It will give them a link to the assignment dashboard and include all relevant reflection call dates."
								image="https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/enrollmentEmailPNG.JPG"
								//className="ml-2"
							/>
						</div>
						<div className="flex items-center space-x-3">
							<Checkbox
								id={StudentNotications.notifyAvailabilityWindow}
								checked={values.notifyAvailabilityWindow}
								onCheckedChange={(checked: boolean) => setFieldValue("notifyAvailabilityWindow", checked)}
							/>
							<label htmlFor={StudentNotications.notifyAvailabilityWindow} className="text-sm text-gray-700">
								Call availability window open notification
							</label>
							<CustomTooltip
								content="This email is sent to students when the availability window for the reflection call opens so that students know when they can start completing their reflections."
								image="https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/ReflectionCallNowAvailablePNG.JPG"
								//className="ml-2"
							/>
						</div>
						<div className="flex items-center space-x-3">
							<Checkbox
								id={StudentNotications.notify24HoursBefore}
								checked={values.notify24HoursBefore}
								onCheckedChange={(checked: boolean) => setFieldValue("notify24HoursBefore", checked)}
							/>
							<label htmlFor={StudentNotications.notify24HoursBefore} className="text-sm text-gray-700">
								24-hour reminder email
							</label>
							<CustomTooltip
								content="This email is sent to students 24 hours before the due date of the assignment to remind them that the assignment is due soon."
								image="https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/ReflectionDueTomorrowPNG.JPG"
								//className="ml-2"
							/>
						</div>
						<div className="flex items-center space-x-3">
							<Checkbox
								id={StudentNotications.notifyDueDate}
								checked={values.notifyDueDate}
								onCheckedChange={(checked: boolean) => setFieldValue("notifyDueDate", checked)}
							/>
							<label htmlFor={StudentNotications.notifyDueDate} className="text-sm text-gray-700">
								Due date reminder email
							</label>
							<CustomTooltip
								content="This email is sent to students the day the assignment is due to remind them that the assignment is due soon."
								image="https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/ReflectionDueTodayPNG.JPG"
								//className="ml-2"
							/>
						</div>
						<div className="flex items-center space-x-3">
							<Checkbox id={StudentNotications.notifyReportCopy} checked disabled />
							<label htmlFor={StudentNotications.notifyReportCopy} className="text-sm text-gray-700">
								Session report email
							</label>
							<CustomTooltip
								content="This email is sent to students after each session providing them with a pdf copy of the session report as well as a link to confirm, add comments, and submit."
								image="https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/1017.gif"
								//className="ml-2"
							/>
						</div>
					</div>
				</div>
			</div>

			{/* Navigation buttons - visible only on larger screens */}
			<div className="hidden md:flex justify-end mt-8 gap-2">
				<Button
					type="button"
					variant="secondary"
					onClick={() => {
						updateFormValues(values);
						onPrevious();
					}}
				>
					Previous
				</Button>
				<Button
					onClick={() => {
						updateFormValues(values);
						onNext();
					}}
				>
					Next
				</Button>
			</div>
		</div>
	);
};

export default AssignmentDistribution;
