import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "../shadcn-ui/Button";
import { Card, CardContent, CardHeader, CardTitle } from "../shadcn-ui/Card";
import { CalComService } from "../../services/CalComService";
import { format, isPast } from "date-fns";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle
} from "../shadcn-ui/AlertDialog";

interface BookingDetails {
	title: string;
	start: string;
	end: string;
	attendee?: {
		name: string;
		email: string;
		bookingFieldsResponses: {
			notes: string;
			phone: string;
		};
		timeZone: string;
	};
}

interface BookingNotes {
	assignedSessionId: string;
	sessionId: string;
	attendeeEmail: string;
	provider: string;
	voiceId: string;
	assistantName: string;
}

const ManageBookingPage: React.FC = () => {
	const { bookingId, seatId } = useParams<{ bookingId: string; seatId: string }>();
	const [booking, setBooking] = useState<BookingDetails | null>(null);
	const [loading, setLoading] = useState(true);
	// const [cancellationReason, setCancellationReason] = useState("");
	const [isCancelling, setIsCancelling] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchBooking = async () => {
			try {
				if (!bookingId) return;
				const response = await CalComService.getBooking(bookingId);
				console.log("Booking response", response);
				const attendee = response.data.attendees.find((att: any) => att.seatUid === seatId);

				let notesData: BookingNotes | null = null;
				if (attendee?.bookingFieldsResponses?.notes) {
					try {
						notesData = JSON.parse(attendee.bookingFieldsResponses.notes);
					} catch (e) {
						console.error("Error parsing notes JSON:", e);
					}
				}

				setBooking({
					title: response.data.title,
					start: response.data.start,
					end: response.data.end,
					attendee: attendee
						? {
								name: attendee.name,
								email: notesData?.attendeeEmail || "",
								bookingFieldsResponses: {
									notes: attendee.bookingFieldsResponses.notes,
									phone: attendee.bookingFieldsResponses.phone
								},
								timeZone: attendee.timeZone
						  }
						: undefined
				});
			} catch (error) {
				console.error("Error fetching booking:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchBooking();
	}, [bookingId, seatId]);

	const handleCancelBooking = async () => {
		try {
			setIsCancelling(true);
			await CalComService.cancelBooking(bookingId!, seatId);
			setShowSuccess(true);
		} catch (error) {
			console.error("Error cancelling booking:", error);
		} finally {
			setIsCancelling(false);
		}
	};

	const handleCancelClick = () => {
		setShowConfirmDialog(true);
	};

	if (loading) {
		return <div>Loading...</div>;
	}

	if (!booking) {
		return <div>Booking not found</div>;
	}

	const formatDateTime = (dateString: string) => {
		return format(new Date(dateString), `EEEE, MMMM d, yyyy 'at' h:mm a '(${booking.attendee?.timeZone || "UTC"})'`);
	};

	const isEventInPast = isPast(new Date(booking.start));

	return (
		<div className="container mx-auto max-w-2xl p-4">
			<Card className="bg-white">
				<CardHeader>
					<CardTitle className="text-center text-[#16013e] mb-4">
						{showSuccess ? "Booking Cancelled" : isEventInPast ? "The event is in the past" : "Manage your booking"}
					</CardTitle>
					<p className="text-center text-gray-600">
						{showSuccess
							? "Your booking has been successfully cancelled."
							: !isEventInPast && "Review your booking details or cancel if needed"}
					</p>
				</CardHeader>
				<CardContent className="space-y-6">
					{showSuccess ? (
						<Button className="w-full" onClick={() => navigate("/user")}>
							Return to Dashboard
						</Button>
					) : (
						<>
							<div>
								<div className="space-y-4">
									<div className="grid grid-cols-[80px_1fr] gap-4">
										<div className="text-[#16013e] text-base font-semibold">What</div>
										<div>InStage Guided Reflection</div>
									</div>

									<div className="grid grid-cols-[80px_1fr] gap-4">
										<div className="text-[#16013e] text-base font-semibold">When</div>
										<div>{formatDateTime(booking.start)}</div>
									</div>

									<div className="grid grid-cols-[80px_1fr] gap-4">
										<div className="text-[#16013e] text-base font-semibold">Who</div>
										<div>
											{booking.attendee?.name && (
												<div>
													{booking.attendee.name} ({booking.attendee.email})
												</div>
											)}
										</div>
									</div>

									{booking.attendee?.bookingFieldsResponses?.phone && (
										<div className="grid grid-cols-[80px_1fr] gap-4">
											<div className="text-[#16013e] text-base font-semibold">Phone</div>
											<div>{booking.attendee.bookingFieldsResponses.phone}</div>
										</div>
									)}
								</div>

								{/* {!isEventInPast && (
									<>
										<hr className="my-8 border-t border-gray-200" />

										<div className="space-y-2">
											<div className="text-[#16013e] text-base font-semibold">Reason for cancellation (optional)</div>
											<textarea
												className="w-full p-2 border rounded-md"
												rows={3}
												placeholder="Why are you cancelling?"
												value={cancellationReason}
												onChange={(e) => setCancellationReason(e.target.value)}
											/>
										</div>
									</>
								)} */}
							</div>

							{!isEventInPast && (
								<div className="flex justify-end space-x-4 mt-6">
									<Button variant="outlineWarning" onClick={handleCancelClick} disabled={isCancelling}>
										{isCancelling ? "Cancelling..." : "Cancel event"}
									</Button>
								</div>
							)}
						</>
					)}
				</CardContent>
			</Card>

			<AlertDialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>Cancel Booking</AlertDialogTitle>
						<AlertDialogDescription>Are you sure you want to cancel this booking? This action cannot be undone.</AlertDialogDescription>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel>No, keep booking</AlertDialogCancel>
						<AlertDialogAction onClick={handleCancelBooking} className="bg-red-600 hover:bg-red-700 text-white">
							Yes, cancel booking
						</AlertDialogAction>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</div>
	);
};

export default ManageBookingPage;
