import { Expose } from "class-transformer";
import { ViewColumn } from "./typeorm-mocks";
import { IsUUID, IsString, IsDate, IsOptional, IsEnum, IsBoolean, IsNumber } from "class-validator";

export class ClientCostSummaryView {
	@ViewColumn()
	@Expose()
	@IsUUID()
	clientId!: string;

	@ViewColumn()
	@Expose()
	@IsString()
	clientName!: string;

	@ViewColumn()
	@Expose()
	@IsNumber()
	analysisCost!: number;

	@ViewColumn()
	@Expose()
	@IsNumber()
	vapiCost!: number;

	@ViewColumn()
	@Expose()
	@IsNumber()
	voiceCost!: number;

	@ViewColumn()
	@Expose()
	@IsNumber()
	modelCost!: number;

	@ViewColumn()
	@Expose()
	@IsNumber()
	transcriberCost!: number;

	@ViewColumn()
	@Expose()
	@IsNumber()
	transportCost!: number;

	@ViewColumn()
	@Expose()
	@IsNumber()
	totalCost!: number;

	@ViewColumn()
	@Expose()
	@IsNumber()
	totalMinutes!: number;

	@ViewColumn()
	@Expose()
	@IsNumber()
	totalPromptTokens!: number;

	@ViewColumn()
	@Expose()
	@IsNumber()
	totalCompletionTokens!: number;

	@ViewColumn()
	@Expose()
	@IsNumber()
	totalCharacters!: number;

	@ViewColumn()
	@Expose()
	@IsNumber()
	totalSessions!: number;
}
