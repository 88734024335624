import { Expose } from "class-transformer";
import { ViewColumn } from "./typeorm-mocks";
import { IsUUID, IsString, IsDate, IsOptional, IsEnum, IsBoolean, IsNumber } from "class-validator";

export class UserCostSummaryView {
	@ViewColumn()
	@Expose()
	@IsUUID()
	userId!: string;

	@ViewColumn()
	@Expose()
	@IsUUID()
	clientId!: string;

	@ViewColumn()
	@Expose()
	@IsString()
	clientName!: string;

	@ViewColumn()
	@Expose()
	@IsUUID()
	assignmentId!: string;

	@ViewColumn()
	@Expose()
	@IsString()
	userEmail!: string;

	@ViewColumn()
	@Expose()
	@IsNumber()
	analysisCost!: number;

	@ViewColumn()
	@Expose()
	@IsNumber()
	vapiCost!: number;

	@ViewColumn()
	@Expose()
	@IsNumber()
	voiceCost!: number;

	@ViewColumn()
	@Expose()
	@IsNumber()
	modelCost!: number;

	@ViewColumn()
	@Expose()
	@IsNumber()
	transcriberCost!: number;

	@ViewColumn()
	@Expose()
	@IsNumber()
	transportCost!: number;

	@ViewColumn()
	@Expose()
	@IsNumber()
	totalCost!: number;

	@ViewColumn()
	@Expose()
	@IsNumber()
	totalMinutes!: number;
}
