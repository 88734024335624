import { AssignedSessionStatus } from "../types/AssignedSessionStatus";

interface StatusStyle {
	background: string;
	text: string;
	border?: string;
}

export const getStatusColors = (status: AssignedSessionStatus): StatusStyle => {
	switch (status) {
		case AssignedSessionStatus.COMPLETED:
		case AssignedSessionStatus.SUBMITTED:
		case AssignedSessionStatus.SUBMITTED_LATE:
			return {
				background: "bg-[#00a9af]",
				text: "text-[#fff]"
			};

		case AssignedSessionStatus.NOT_ATTEMPTED:
		case AssignedSessionStatus.PAST_DUE:
		case AssignedSessionStatus.UNSUBMITTED:
			return {
				background: "bg-[#FEF3F2]",
				text: "text-[#B42318]",
				border: "border-[#FEE4E2]"
			};

		case AssignedSessionStatus.PENDING:
			return {
				background: "bg-[#F9FAFB]",
				text: "text-[#344054]",
				border: "border-[#EAECF0]"
			};

		case AssignedSessionStatus.AVAILABLE:
			return {
				background: "bg-[#e6f7f7]",
				text: "text-[#00a9af]",
				border: "border-[#b3e6e8]"
			};

		case AssignedSessionStatus.NOT_CONFIRMED:
		case AssignedSessionStatus.DRAFT:
		case AssignedSessionStatus.NOT_AVAILABLE:
			return {
				background: "bg-gray-100",
				text: "text-gray-800"
			};

		case AssignedSessionStatus.IN_PROGRESS:
		case AssignedSessionStatus.INCOMPLETE:
			return {
				background: "bg-yellow-100",
				text: "text-yellow-600",
				border: "border-yellow-300"
			};

		default:
			return {
				background: "bg-gray-100",
				text: "text-gray-800"
			};
	}
};

// Helper function for card-specific styling
export const getStatusCardStyles = (status: AssignedSessionStatus): string => {
	const styles = getStatusColors(status);
	return `${styles.background} ${styles.text} ${styles.border ? `border ${styles.border}` : ""}`;
};
