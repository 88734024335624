export enum AssignedSessionStatus {
	NOT_CONFIRMED = "not_confirmed",
	BOUNCED = "bounced", // bad email
	PENDING = "pending", // due date in future
	AVAILABLE = "available", // ready to start
	IN_PROGRESS = "in_progress", // user is on call
	INCOMPLETE = "incomplete", // user did not complete the call
	UNSUBMITTED = "unsubmitted", // user did not submit the call

	SUBMITTED = "submitted", // user submitted the call

	SUBMITTED_LATE = "submitted_late", // user submitted the call after the due date
	NOT_ATTEMPTED = "not_attempted", // user did not attempt the call
	PAST_DUE = "pastdue", // user did not attempt the call

	DRAFT = "draft", // assingment is in draft mode
	NOT_AVAILABLE = "not available", // removed

	COMPLETED = "completed" // removed
}

// INCOMPLETE = "incomplete", // user did not complete the call
// UNSUBMITTED = "unsubmitted", // user did not submit the call
