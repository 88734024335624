import React from "react";

// Define props for the FormDataModal component
interface FormDataModalProps {
	isOpen: boolean;
	onClose: () => void;
	formData: string;
}

// FormDataModal component to display form data in a modal
const FormDataModal: React.FC<FormDataModalProps> = ({ isOpen, onClose, formData }) => {
	// If the modal is not open, don't render anything
	if (!isOpen) return null;

	return (
		// Modal overlay
		<div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
			{/* Modal content */}
			<div className="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-1/2 shadow-lg rounded-md bg-white">
				<div className="mt-3 text-center">
					<h3 className="text-lg leading-6 font-medium text-gray-900">Form Data</h3>
					{/* Display form data */}
					<div className="mt-2 px-7 py-3">
						<pre className="text-left whitespace-pre-wrap break-words">{formData}</pre>
					</div>
					{/* Close button */}
					<div className="items-center px-4 py-3">
						<button
							onClick={onClose}
							className="px-4 py-2 bg-[#00a9af] text-white text-base font-medium rounded-md shadow-sm hover:bg-[#008c91] focus:outline-none focus:ring-2 focus:ring-[#00a9af]"
						>
							Close
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FormDataModal;
