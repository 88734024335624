export class SchedulePayload {
	title: string;

	date: string;

	startDate: string | null;

	constructor(data: Partial<SchedulePayload>) {
		this.title = data.title ?? "";
		this.date = data.date ?? "";
		this.startDate = data.startDate ?? null;
	}
}
