import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { PencilIcon, ClipboardIcon } from "@heroicons/react/20/solid";
import { useFormContext } from "../FormContext";
import FormDataModal from "../FormDataModal";
import { AssignmentPayload } from "../../../../types/AssignmentPayload";
import { AssignmentService } from "../../../../services/AssignmentService";
import { Button } from "../../../shadcn-ui/Button";
import { AdminAssignmentView } from "@/src/types/AdminAssignmentView";
import { StudentNotications } from "../../../../types/AssignmentPayload";
import { Loader2 } from "lucide-react";

import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle
} from "../../../shadcn-ui/AlertDialog";
import { useAppContext } from "../../../../contexts/AppContext";

// Define props for the ReviewAssignment component
export interface ReviewAssignmentProps {
	values: any;
	onNext: () => void;
	onPrevious: () => void;
	onEdit: (step: number) => void;
	isEditMode: boolean;
	onSubmit: (assignmentPayload: AdminAssignmentView) => void;
}

// ReviewAssignment component for reviewing and submitting assignment details
const ReviewAssignment: React.FC<ReviewAssignmentProps> = ({ values, onSubmit, onNext, onPrevious, onEdit, isEditMode }) => {
	const { assignmentId } = useParams<{ step?: string; assignmentId?: string }>();
	const { updateFormValues } = useFormContext();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [formattedData, setFormattedData] = useState("");
	const [isDeployDialogOpen, setIsDeployDialogOpen] = useState(false);
	const [isDeploying, setIsDeploying] = useState(false);
	const { instageUser } = useAppContext();

	// Format date to a readable string
	const formatDate = (date: Date | null | undefined) => {
		return date ? format(date, "MMMM d, yyyy") : "Not set";
	};

	// Get availability date text for a session
	const getAvailabilityDateText = (session: any, index: number) => {
		if (session.startDate) {
			return formatDate(session.startDate);
		}
		if (index === 0) {
			return "Anytime";
		}
		return "As soon as previous session is completed";
	};

	// Copy text to clipboard
	const copyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text);
		// Optionally, show a tooltip or notification that the text was copied
	};

	// Get the title for a session
	const getSessionTitle = (session: any, index: number) => {
		if (session.title === "Pre-Reflection") return "Pre-Reflection";
		if (session.title === "Final Reflection") return "Final Reflection";
		return `Reflection ${index}`; // Changed from index + 1 to index
	};

	// Sort sessions for display
	const sortedSessions = React.useMemo(() => {
		if (!values.sessions) return [];
		return [...values.sessions].sort((a, b) => {
			if (a.title === "Pre-Reflection") return -1;
			if (b.title === "Pre-Reflection") return 1;
			if (a.title === "Final Reflection") return 1;
			if (b.title === "Final Reflection") return -1;
			return values.sessions.indexOf(a) - values.sessions.indexOf(b); // Maintain original order for other reflections
		});
	}, [values.sessions]);

	// Format form data for submission
	const formatFormData = (data: any, status: "deployed" | "draft") => {
		const formattedData = {
			title: data.title || null,
			description: data.description || null,
			courseName: data.courseName || null,
			courseCode: data.courseCode || null,
			courseTerm: data.courseTerm || null,
			courseSection: data.courseSection || null,
			estimatedStudents: data.estimatedStudents || null,
			competencies: data.competencies || [], // Add this line to include competencies
			client: "clientX", // Added placeholder client
			adminName: "FacultyName", // Added placeholder admin name
			sessions: data.sessions
				.map((session: any, index: number) => {
					let title;
					if (session.title === "Pre-Reflection") {
						title = "Pre-Reflection";
					} else if (session.title === "Final Reflection") {
						title = "Final Reflection";
					} else {
						const reflectionNumber =
							data.sessions.slice(0, index).filter((s: any) => s.title !== "Pre-Reflection" && s.title !== "Final Reflection").length +
							1;
						title = `Reflection ${reflectionNumber}`;
					}
					return {
						title,
						dueDate: session.date || null,
						startDate: session.startDate || null
					};
				})
				.sort((a: { title: string }, b: { title: string }) => {
					if (a.title === "Pre-Reflection") return -1;
					if (b.title === "Pre-Reflection") return 1;
					if (a.title === "Final Reflection") return 1;
					if (b.title === "Final Reflection") return -1;
					return a.title.localeCompare(b.title);
				}),
			distributionMethod: data.distributionMethod || null,
			recipientList: data.recipientList || null,
			sendReminders: data.notify24HoursBefore || false, // send 24 hours before due date
			sendAvailabilityWindowReminders: data.notifyAvailabilityWindow || false,
			sendReminderOnDueDate: data.notifyDueDate || false,
			assignmentCode: values.assignmentCode,
			signupLink: `${window.location.origin}/signup?code=${values.assignmentCode}`,
			reportFrequency: data.reportFrequency || null,
			includeCSV: data.includeCSV || false,
			includePDF: data.includePDF || false,
			additionalReportRecipients: data.additionalReportRecipients || null,
			assignmentStatus: status // New field for assignment status
		};
		return JSON.stringify(formattedData, null, 2);
	};

	// Handle assignment creation or update
	const handleCreateAssignment = async (status: "deployed" | "draft"): Promise<AdminAssignmentView | null> => {
		if (status === "deployed") {
			setIsDeployDialogOpen(true);
		} else {
			// Proceed with creating draft
			return await createAssignment(status);
		}
		return null;
	};

	// Create or update assignment
	const createAssignment = async (status: "deployed" | "draft"): Promise<AdminAssignmentView | null> => {
		const formattedData = formatFormData(values, status);
		setFormattedData(formattedData);
		console.log("instageUser", instageUser);

		setIsDeploying(true);
		try {
			const formattedData = formatFormData(values, status);
			setFormattedData(formattedData);

			const assignmentPayload = new AssignmentPayload({
				title: values.title,
				adminEmail: instageUser?.email || "admin@instage.io",
				adminFirstName: instageUser?.firstName || "InStage",
				adminLastName: instageUser?.lastName || "",
				additionalReportRecipients: values.additionalReportRecipients,
				emailDeliveryOptions: values.emailDeliveryOptions,
				description: values.description,
				courseName: values.courseName,
				courseCode: values.courseCode,
				courseTerm: values.courseTerm,
				courseSection: values.courseSection,
				estimatedStudents: values.estimatedStudents,
				includeCSV: values.includeCSV,
				includePDF: values.includePDF,
				recipientList: values.recipientList,
				reportFrequency: values.reportFrequency,
				sendReminders: values.notify24HoursBefore,
				experienceType: values.experienceType,
				sessions: values.sessions.map((session: any) => {
					let date = null;
					let startDate = null;
					if (session.date) {
						const localDate = new Date(session.date);
						localDate.setHours(23, 59, 0, 0);
						date = localDate.toISOString();
					}
					if (session.startDate) {
						const localDate = new Date(session.startDate);
						localDate.setHours(0, 1, 0, 0);
						startDate = localDate.toISOString();
					}
					return {
						...session,
						date,
						startDate
					};
				}),
				competencies: values.competencies,
				assignmentCode: values.assignmentCode,
				includeTranscript: values.includeTranscript,
				includeAudio: values.includeAudio,
				studentNotifications: [],
				isPublished: status === "deployed" ? true : false
			});

			assignmentPayload.studentNotifications.push(StudentNotications.notifyEnrollment);

			if (values.notifyAvailabilityWindow) {
				assignmentPayload.studentNotifications.push(StudentNotications.notifyAvailabilityWindow);
			}
			if (values.notify24HoursBefore) {
				assignmentPayload.studentNotifications.push(StudentNotications.notify24HoursBefore);
			}
			if (values.notifyDueDate) {
				assignmentPayload.studentNotifications.push(StudentNotications.notifyDueDate);
			}

			assignmentPayload.studentNotifications.push(StudentNotications.notifyReportCopy);

			console.log("assignmentPayload", assignmentPayload);

			if (isEditMode && assignmentId) {
				const data = await AssignmentService.updateAssignment(assignmentId, assignmentPayload);
				console.log("Assignment updated successfully:", data);
				onSubmit(data);
				return data;
			} else {
				const data = await AssignmentService.createAssignment(assignmentPayload);
				console.log("Assignment created successfully:", data);
				onSubmit(data);
				return data;
			}
		} catch (error) {
			console.error("Error creating assignment:", error);
		} finally {
			setIsDeploying(false);
			setIsDeployDialogOpen(false);
		}
		return null;
	};

	// Extract common button content into a component
	const DeployButton: React.FC<{ className?: string }> = ({ className }) => (
		<Button onClick={() => handleCreateAssignment("deployed")} disabled={isDeploying} className={className}>
			{isDeploying ? (
				<>
					<Loader2 className="mr-2 h-4 w-4 animate-spin" />
					Deploying...
				</>
			) : values.isPublished ? (
				"Update Assignment"
			) : (
				"Deploy Assignment"
			)}
		</Button>
	);

	const PreviousButton: React.FC<{ className?: string }> = ({ className }) => (
		<Button
			variant="secondary"
			onClick={() => {
				updateFormValues(values);
				onPrevious();
			}}
			className={className}
		>
			Previous
		</Button>
	);

	return (
		<div className="space-y-8">
			{/* Render panels for each section of the assignment details */}
			<Panel title="Basic Information" onEdit={() => onEdit(0)}>
				<Detail label="Title" value={values.title} />
				<Detail label="Description" value={values.description} />
				<Detail label="Course Name" value={values.courseName} />
				<Detail label="Course Code" value={values.courseCode} />
				<Detail label="Course Term" value={values.courseTerm} />
				<Detail label="Course Section" value={values.courseSection} />
				<Detail label="Estimated Students" value={values.estimatedStudents} />
				{values.competencies && values.competencies.length > 0 && (
					<div className="flex">
						<span className="font-normal text-gray-600 w-1/3">Selected Competencies:</span>
						<span className="text-gray-800">{values.competencies.join(", ")}</span>
					</div>
				)}
			</Panel>

			<Panel title="Reflection Calls" onEdit={() => onEdit(1)}>
				{sortedSessions.map((session, index) => {
					const sessionTitle = getSessionTitle(session, index);
					const reflectionNumber = sessionTitle.startsWith("Reflection") ? index : null;
					return (
						<div key={index} className="mb-6">
							<h4 className="font-semibold text-gray-800 mb-2">{reflectionNumber ? `Reflection ${reflectionNumber}` : sessionTitle}</h4>
							<Detail label="Due Date" value={formatDate(session.date)} />
							<Detail label="Available From" value={getAvailabilityDateText(session, index)} />
						</div>
					);
				})}
			</Panel>

			<Panel title="Assignment Distribution" onEdit={() => onEdit(2)}>
				<Detail label="Distribution Method" value={values.distributionMethod || "Not set"} />
				{values.recipientList && (
					<div className="mt-4">
						<h4 className="text-md font-normal text-gray-700 mb-2">Added Emails:</h4>
						<ul className="list-disc pl-5 space-y-1">
							{values.recipientList
								.split(",")
								.slice(0, 10)
								.map((email: string, index: number) => (
									<li key={index} className="text-sm text-gray-800">
										{email.trim()}
									</li>
								))}
							{values.recipientList.split(",").length > 10 && (
								<li className="text-sm text-gray-800">... {values.recipientList.split(",").length - 10} more</li>
							)}
						</ul>
					</div>
				)}
				<Detail label="Send 24 Hour Notice Reminders" value={values.notify24HoursBefore ? "Yes" : "No"} />
				<Detail label="Send Availability Reminders" value={values.notifyAvailabilityWindow ? "Yes" : "No"} />
				<Detail label="Send Reminder On Due Date" value={values.notifyDueDate ? "Yes" : "No"} />
				<div className="mt-2">
					<h4 className="text-md font-normal text-gray-700 mb-2">Sign-up Link:</h4>
					<div className="flex items-center space-x-2">
						<span className="text-sm text-gray-800">{`https://instage.io/signup?code=${values.assignmentCode || "ABC123"}`}</span>
						<Button
							variant="outline"
							size="icon"
							onClick={() => copyToClipboard(`https://instage.io/signup?code=${values.assignmentCode || "ABC123"}`)}
						>
							<ClipboardIcon className="h-5 w-5" />
						</Button>
					</div>
				</div>
			</Panel>

			<Panel title="Automated Reports" onEdit={() => onEdit(3)}>
				<Detail
					label="Report Frequency"
					value={
						values.reportFrequency === "afterEachDueDate"
							? "After each due date"
							: values.reportFrequency === "afterFinalDueDate"
							? "After final due date"
							: "No email reports"
					}
				/>
				{values.reportFrequency !== "noReports" && (
					<>
						<Detail label="Include CSV" value={values.includeCSV ? "Yes" : "No"} />
						<Detail label="Include DOCX" value={values.includePDF ? "Yes" : "No"} />
						{values.additionalReportRecipients && (
							<div className="mt-4">
								<h4 className="text-md font-normal text-gray-700 mb-2">Additional Report Recipients:</h4>
								<ul className="list-disc pl-5 space-y-1">
									{values.additionalReportRecipients.split(",").map((email: string, index: number) => (
										<li key={index} className="text-sm text-gray-800">
											{email.trim()}
										</li>
									))}
								</ul>
							</div>
						)}
					</>
				)}
				<p className="mt-2 text-sm text-gray-600 italic">
					Note: You can always log in to the dashboard to view assignment activity at any time.
				</p>
			</Panel>

			{/* Navigation and action buttons - desktop */}
			<div className="hidden md:flex justify-end mt-8 gap-2">
				<PreviousButton />
				<div className="space-x-4">
					{!values.isPublished && false && (
						<Button variant="outlinePrimary" onClick={() => handleCreateAssignment("draft")}>
							Save Draft
						</Button>
					)}
					<DeployButton />
				</div>
			</div>

			{/* Navigation and action buttons - mobile */}
			<div className="flex gap-2 mt-8 md:hidden">
				<PreviousButton />
				<DeployButton />
			</div>

			{/* Deployment confirmation dialog */}
			<AlertDialog open={isDeployDialogOpen} onOpenChange={setIsDeployDialogOpen}>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>Confirm Assignment Deployment</AlertDialogTitle>
						<AlertDialogDescription>
							<ul className="list-disc pl-5 space-y-2">
								<li>Students will receive an email about the upcoming assignment.</li>
								<li>Due dates and competencies cannot be changed after deployment.</li>
								<li>You can add students up until the first session due date.</li>
							</ul>
						</AlertDialogDescription>
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel onClick={() => setIsDeployDialogOpen(false)} disabled={isDeploying}>
							Cancel
						</AlertDialogCancel>
						<AlertDialogAction onClick={() => createAssignment("deployed")} disabled={isDeploying}>
							{isDeploying ? (
								<>
									<Loader2 className="mr-2 h-4 w-4 animate-spin" />
									Deploying...
								</>
							) : (
								"Confirm Deployment"
							)}
						</AlertDialogAction>
						{!values.isPublished && false && (
							<Button variant="outlinePrimary" onClick={() => createAssignment("draft")} disabled={isDeploying}>
								Save as Draft
							</Button>
						)}
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>

			{/* Modal for displaying formatted form data */}
			<FormDataModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} formData={formattedData} />
		</div>
	);
};

// Panel component for grouping related details
const Panel: React.FC<{ title: string; children: React.ReactNode; onEdit: () => void }> = ({ title, children, onEdit }) => (
	<div className="bg-white border border-[#eaecf0] rounded-lg p-6">
		<div className="flex justify-between items-center mb-4">
			<h3 className="text-lg font-medium text-gray-800">{title}</h3>
			<Button variant="outline" onClick={onEdit}>
				<PencilIcon className="h-5 w-5 mr-1" />
				Edit
			</Button>
		</div>
		<div className="space-y-2">{children}</div>
	</div>
);

// Detail component for displaying individual details
const Detail: React.FC<{ label: string; value: string | number }> = ({ label, value }) => (
	<div className="flex">
		<span className="font-normal text-gray-600 w-1/3">{label}:</span>
		<span className="text-gray-800 font-['DM_Sans']">{value}</span>
	</div>
);

export default ReviewAssignment;
