import axios from "axios";
import { AssignedSessionView } from "../types/AssignedSessionView";
import { plainToInstance } from "class-transformer";
import { LogAxiosError } from "./AxiosUtility";

const API_BASE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/customer-portal`;

export class AssignedSessionService {
	static async getViewByAssignedSessionId(assignedSessionId: string): Promise<AssignedSessionView> {
		try {
			const response = await axios.get<AssignedSessionView>(`${API_BASE_URL}/user-assigned-session/${assignedSessionId}`);
			return plainToInstance(AssignedSessionView, response.data);
		} catch (error) {
			const errorMessage = LogAxiosError(error, `assigned session id ${assignedSessionId}`);
			throw new Error(errorMessage);
		}
	}

	static async getByscheduleId(scheduleId: string) {
		try {
			const response = await axios.get<AssignedSessionView[]>(`${API_BASE_URL}/user-assigned-session/schedule/${scheduleId}`);
			return response.data.map((stat: object) => plainToInstance(AssignedSessionView, stat));
		} catch (error) {
			const errorMessage = LogAxiosError(error, `schedule id ${scheduleId}`);
			throw new Error(errorMessage);
		}
	}

	static async getByAssignmentId(assignmentId: string): Promise<AssignedSessionView[]> {
		try {
			const response = await axios.get<AssignedSessionView[]>(`${API_BASE_URL}/user-assigned-session/assignment/${assignmentId}`);
			return response.data.map((stat: object) => plainToInstance(AssignedSessionView, stat));
		} catch (error) {
			const errorMessage = LogAxiosError(error, `assignment id ${assignmentId}`);
			throw new Error(errorMessage);
		}
	}

	static async getByAssignmentIdEmail(authToken: string): Promise<AssignedSessionView[]> {
		console.log("getByAssignmentIdEmail", authToken);
		try {
			const response = await axios.get<AssignedSessionView[]>(`${API_BASE_URL}/user-assigned-session-email`, {
				headers: { Authorization: `Bearer ${authToken}` } // Added authorization header
			});
			return response.data.map((stat: object) => plainToInstance(AssignedSessionView, stat));
		} catch (error) {
			const errorMessage = LogAxiosError(error, `authToken ${authToken}`);
			throw new Error(errorMessage);
		}
	}

	static async getByAssignmentIdAndUserId(assignmentId: string, userId: string): Promise<AssignedSessionView[]> {
		try {
			const response = await axios.get<AssignedSessionView[]>(
				`${API_BASE_URL}/user-assigned-session/assignment/${assignmentId}/user/${userId}`
			);
			return response.data.map((stat: object) => plainToInstance(AssignedSessionView, stat));
		} catch (error) {
			const errorMessage = LogAxiosError(error, `assignment id ${assignmentId} user id ${userId}`);
			throw new Error(errorMessage);
		}
	}

	static async getAvailableByUserId(userId: string): Promise<AssignedSessionView[]> {
		try {
			const response = await axios.get<AssignedSessionView[]>(`${API_BASE_URL}/user-assigned-session/available/user/${userId}`);
			if (Array.isArray(response.data)) {
				return response.data.map((stat: object) => plainToInstance(AssignedSessionView, stat));
			} else {
				console.log((response.data as { message?: string })?.message);
				return [];
			}
		} catch (error) {
			const errorMessage = LogAxiosError(error, `user id ${userId}`);
			throw new Error(errorMessage);
		}
	}

	static async getInProgressByUserId(userId: string): Promise<AssignedSessionView[]> {
		try {
			const response = await axios.get<AssignedSessionView[]>(`${API_BASE_URL}/user-assigned-session/in-progress/user/${userId}`);
			return response.data.map((stat: object) => plainToInstance(AssignedSessionView, stat));
		} catch (error) {
			const errorMessage = LogAxiosError(error, `user id ${userId}`);
			throw new Error(errorMessage);
		}
	}

	static async getPendingByUserId(userId: string): Promise<AssignedSessionView[]> {
		try {
			const response = await axios.get<AssignedSessionView[]>(`${API_BASE_URL}/user-assigned-session/pending/user/${userId}`);
			return response.data.map((stat: object) => plainToInstance(AssignedSessionView, stat));
		} catch (error) {
			const errorMessage = LogAxiosError(error, `user id ${userId}`);
			throw new Error(errorMessage);
		}
	}

	static async getCompletedByUserId(userId: string): Promise<AssignedSessionView[]> {
		try {
			const response = await axios.get<AssignedSessionView[]>(`${API_BASE_URL}/user-assigned-session/completed/user/${userId}`);
			return response.data.map((stat: object) => plainToInstance(AssignedSessionView, stat));
		} catch (error) {
			const errorMessage = LogAxiosError(error, `user id ${userId}`);
			throw new Error(errorMessage);
		}
	}

	static async submitSession(sessionId: string, userId: string, grade: number, userConfirmed: boolean, authToken?: string): Promise<void> {
		try {
			if (authToken) {
				const response = await axios.post(
					`${API_BASE_URL}/user-assigned-session/submit-session`,
					{
						sessionId,
						userId,
						grade,
						userConfirmed
					},
					{ headers: { Authorization: `Bearer ${authToken}` } }
				);

				if (response.status !== 200) {
					throw new Error("Failed to submit session");
				}
			} else {
				const response = await axios.post(`${API_BASE_URL}/user-assigned-session/submit-session`, {
					sessionId,
					userId,
					grade,
					userConfirmed
				});

				if (response.status !== 200) {
					throw new Error("Failed to submit session");
				}
			}

			// If you need to return data from the response, you can uncomment the following line:
			// return response.data;
		} catch (error) {
			const errorMessage = LogAxiosError(error, `session id ${sessionId} user id ${userId}`);
			throw new Error(errorMessage);
		}
	}

	static async unSubmitSession(sessionId: string, userId: string): Promise<void> {
		try {
			const response = await axios.post(`${API_BASE_URL}/user-assigned-session/unsubmit-session`, {
				sessionId,
				userId
			});
			if (response.status !== 200) {
				throw new Error("Failed to unsubmit session");
			}
		} catch (error) {
			const errorMessage = LogAxiosError(error, `session id ${sessionId} user id ${userId}`);
			throw new Error(errorMessage);
		}
	}

	static async addExtension(assignedSessionId: string, assignmentId: string, scheduleId: string, userId: string, daysToAdd: number): Promise<void> {
		try {
			const response = await axios.post(`${API_BASE_URL}/user-assigned-session/add-extension`, {
				assignedSessionId,
				assignmentId,
				scheduleId,
				userId,
				daysToAdd
			});

			if (response.status !== 200) {
				throw new Error("Failed to add extension");
			}
		} catch (error) {
			const errorMessage = LogAxiosError(
				error,
				`assigned session id ${assignedSessionId} assignment id ${assignmentId} schedule id ${scheduleId} user id ${userId} days to add ${daysToAdd}`
			);
			throw new Error(errorMessage);
		}
	}

	static async removeExtension(assignedSessionId: string): Promise<void> {
		try {
			const response = await axios.post(`${API_BASE_URL}/user-assigned-session/remove-extension`, {
				assignedSessionId
			});

			if (response.status !== 200) {
				throw new Error("Failed to remove extension");
			}
		} catch (error) {
			const errorMessage = LogAxiosError(error, `assigned session id ${assignedSessionId}`);
			throw new Error(errorMessage);
		}
	}

	static async generateCitations(sessionId: string): Promise<void> {
		try {
			const response = await axios.post(`${API_BASE_URL}/generate-citations`, {
				sessionId
			});

			if (response.status !== 200) {
				throw new Error("Failed to generate citations");
			}
		} catch (error) {
			const errorMessage = LogAxiosError(error, `session id ${sessionId}`);
			throw new Error(errorMessage);
		}
	}

	static async activityReview(sessionId: string): Promise<void> {
		try {
			const response = await axios.post(`${API_BASE_URL}/activity-review`, {
				sessionId
			});

			if (response.status !== 200) {
				throw new Error("Failed to review activity");
			}
		} catch (error) {
			const errorMessage = LogAxiosError(error, `session id ${sessionId}`);
			throw new Error(errorMessage);
		}
	}
}
