import { SchedulePayload } from "./SchedulePayload";

export enum StudentNotications {
	notifyEnrollment = "notifyEnrollment",
	notifyAvailabilityWindow = "notifyAvailabilityWindow",
	notify24HoursBefore = "notify24HoursBefore",
	notifyDueDate = "notifyDueDate",
	notifyReportCopy = "notifyReportCopy"
}

export enum EmailDeliveryOptions {
	csv = "csv",
	pdf = "pdf"
}

export class AssignmentPayload {
	title: string;

	description: string;

	courseName: string;

	courseCode: string;

	courseTerm: string;

	courseSection: string;

	estimatedStudents: number;

	client: string;

	clientId: string;

	adminFirstName: string;

	adminLastName: string;

	adminId: string;

	adminEmail: string;

	sessions: SchedulePayload[];

	recipientList: string;

	sendReminders: boolean;

	sendAvailabilityWindowReminders: boolean;

	sendReminderOnDueDate: boolean;

	assignmentCode: string;

	signupLink: string;

	reportFrequency: "afterEachDueDate" | "afterFinalDueDate" | "noReports";

	emailDeliveryOptions: string;

	studentNotifications: StudentNotications[];

	includeCSV: boolean;

	includePDF: boolean;

	additionalReportRecipients: string | null;

	isPublished: boolean;

	competencies: string[];

	competencyIds: string[];

	experienceType: "Co-op Experience" | "Internship" | "Work Placement" | "Capstone Project" | "Weekly Sprint" | "Monthly Project" | "Course";

	includeTranscript: boolean;

	includeAudio: boolean;

	constructor(init?: Partial<AssignmentPayload>) {
		Object.assign(this, init);
		this.experienceType = init?.experienceType || "Co-op Experience";
		this.title = init?.title ?? "";
		this.description = init?.description ?? "";
		this.courseName = init?.courseName ?? "";
		this.courseCode = init?.courseCode ?? "";
		this.courseTerm = init?.courseTerm ?? "";
		this.courseSection = init?.courseSection ?? "";
		this.estimatedStudents = init?.estimatedStudents ?? 0;
		this.client = init?.client ?? "";
		this.clientId = init?.clientId ?? "";
		this.adminFirstName = init?.adminFirstName ?? "";
		this.adminLastName = init?.adminLastName ?? "";
		this.adminId = init?.adminId ?? "";
		this.adminEmail = init?.adminEmail ?? "";
		this.sessions = (init?.sessions ?? []).map((session: SchedulePayload) => new SchedulePayload(session));
		// this.distributionMethod = data.distributionMethod ?? null;
		this.recipientList = init?.recipientList ?? "";
		this.sendReminders = init?.sendReminders ?? false;
		this.sendAvailabilityWindowReminders = init?.sendAvailabilityWindowReminders ?? false;
		this.sendReminderOnDueDate = init?.sendReminderOnDueDate ?? false;
		this.assignmentCode = init?.assignmentCode ?? "";
		this.signupLink = init?.signupLink ?? "";
		this.reportFrequency = init?.reportFrequency ?? "noReports";
		this.emailDeliveryOptions = init?.emailDeliveryOptions ?? "";
		this.includeCSV = init?.includeCSV ?? false;
		this.includePDF = init?.includePDF ?? false;
		this.additionalReportRecipients = init?.additionalReportRecipients ?? null;
		this.isPublished = init?.isPublished ?? false;
		this.competencies = init?.competencies ?? [];
		this.competencyIds = init?.competencyIds ?? [];
		this.includeTranscript = init?.includeTranscript ?? false;
		this.includeAudio = init?.includeAudio ?? false;
		this.studentNotifications = init?.studentNotifications ?? [StudentNotications.notifyReportCopy, StudentNotications.notifyEnrollment];
	}
}
