import React, { useState, useEffect } from "react";
import { Field, ErrorMessage } from "formik";
import { useFormContext } from "../FormContext";
import { Button } from "../../../shadcn-ui/Button";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "../../../shadcn-ui/Select";
import { Dialog, DialogContent, DialogTitle, DialogClose } from "../../../shadcn-ui/Dialog";
import { AssignmentService } from "../../../../services/AssignmentService";
import { CompetencyView } from "../../../../types/CompetencyView";
import CustomTooltip from "../../../Utility/CustomTooltip";
import { useAuth } from "@frontegg/react";

// Define a dictionary type for competencies
type CompetencyViewDict = {
	[key: string]: CompetencyView[];
};

// Define props for the BasicInformation component
export interface BasicInformationProps {
	values: any;
	errors: any;
	touched: any;
	setFieldValue: (field: string, value: any) => void;
	onNext: () => void;
	isValid: boolean;
	dirty: boolean;
	isEditMode: boolean;
}

const MAX_COMPETENCIES = 4;

// BasicInformation component for capturing basic assignment details
const BasicInformation: React.FC<BasicInformationProps> = ({ values, errors, touched, setFieldValue, onNext, isValid, dirty, isEditMode }) => {
	const { user } = useAuth();
	const isInstageAdmin = user?.roles?.some((role) => role.key === "InstageAdmin");
	const { updateFormValues } = useFormContext();
	const [selectedCompetencies, setSelectedCompetencies] = useState<string[]>(values.competencies || []);
	const [customCompetency, setCustomCompetency] = useState<{ name: string; description: string; examples: string }>({
		name: "",
		description: "",
		examples: ""
	});
	const [isAddingCustom, setIsAddingCustom] = useState<boolean>(false);
	const [selectedDescription, setSelectedDescription] = useState<{ name: string; description: string; examples: string } | null>(null);
	const [isDescriptionOpen, setIsDescriptionOpen] = useState<boolean>(false);
	const [competenciesV2, setCompetenciesV2] = useState<CompetencyViewDict>({});

	// Fetch competencies from the server
	const fetchCompetencies = async () => {
		const competencies = await AssignmentService.getCompetenciesViewByClientId();
		console.log("competencies", competencies);

		const groupedCompetencies = competencies.reduce((acc: CompetencyViewDict, competency) => {
			const category = competency.category || "Uncategorized";
			if (!acc[category]) {
				acc[category] = [];
			}
			acc[category].push(competency);
			return acc;
		}, {});
		console.log("groupedCompetencies", groupedCompetencies);
		setCompetenciesV2(groupedCompetencies);
	};

	useEffect(() => {
		fetchCompetencies();
	}, []);

	// Handle competency selection
	const handleCompetencyToggle = (competency: string) => {
		setSelectedCompetencies((prev) => {
			let newCompetencies;
			if (prev.includes(competency)) {
				newCompetencies = prev.filter((c) => c !== competency);
			} else if (prev.length < MAX_COMPETENCIES) {
				newCompetencies = [...prev, competency];
			} else {
				newCompetencies = prev;
			}
			setFieldValue("competencies", newCompetencies);
			return newCompetencies;
		});
	};

	// Check if the form is valid
	const isFormValid = () => {
		const requiredFields = ["title", "courseName", "courseCode", "courseTerm"];
		const basicInfoValid = requiredFields.every((field) => values[field] && values[field].trim() !== "");
		const competencyValid = selectedCompetencies.length >= 1 && selectedCompetencies.length <= MAX_COMPETENCIES;
		const descriptionValid = values.description && values.description.trim() !== "";
		return basicInfoValid && competencyValid && descriptionValid;
	};

	// Handle next button click
	const handleNext = () => {
		updateFormValues({ ...values, competencies: selectedCompetencies });
		onNext();
	};

	// Handle custom competency submission
	const handleCustomSubmit = async () => {
		await AssignmentService.createCustomCompetency({
			name: customCompetency.name,
			description: customCompetency.description,
			examples: customCompetency.examples,
			clientId: values.clientId,
			category: "Custom"
		});
		await fetchCompetencies();

		// Reset the custom competency form and hide it
		setCustomCompetency({ name: "", description: "", examples: "" });
		setIsAddingCustom(false);
	};

	// Handle competency info display
	const handleCompetencyInfo = (name: string, description: string, examples: string) => {
		if (description) {
			setSelectedDescription({ name, description, examples });
			setIsDescriptionOpen(true);
		}
	};

	return (
		<div className="space-y-8">
			<Panel title="Information">
				{/* Reflection Assignment Name */}
				<div>
					<label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
						Reflection Assignment Name *
					</label>
					<Field
						type="text"
						name="title"
						id="title"
						className="mt-1 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 text-base p-2 text-gray-900"
						placeholder="Reflection for AI Ethics Program"
					/>
					<ErrorMessage name="title" component="div" className="mt-1 text-sm text-red-600" />
				</div>

				{/* Experience Type Field */}
				<div className="mt-4">
					<label htmlFor="experienceType" className="block text-sm font-medium text-gray-700 mb-1">
						Experience Type *
					</label>
					<div className="relative">
						<Select value={values.experienceType} onValueChange={(value) => setFieldValue("experienceType", value)}>
							<SelectTrigger className="w-full px-4 py-2 text-left bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
								<SelectValue placeholder="Select Experience Type" />
							</SelectTrigger>
							<SelectContent className="w-full bg-white border border-gray-300 rounded-md shadow-lg">
								<SelectItem value="Co-op Experience" className="py-2 px-4 hover:bg-gray-100">
									Co-op Experience
								</SelectItem>
								<SelectItem value="Internship" className="py-2 px-4 hover:bg-gray-100">
									Internship
								</SelectItem>
								<SelectItem value="Work Placement" className="py-2 px-4 hover:bg-gray-100">
									Work Placement
								</SelectItem>
								<SelectItem value="Capstone Project" className="py-2 px-4 hover:bg-gray-100">
									Capstone Project
								</SelectItem>
								{isInstageAdmin && (
									<SelectItem value="Course" className="py-2 px-4 hover:bg-gray-100">
										Course
									</SelectItem>
								)}
								{/* <SelectItem value="Weekly Sprint" className="py-2 px-4 hover:bg-gray-100">
									Weekly Sprint
								</SelectItem> */}
							</SelectContent>
						</Select>
					</div>
					<p className="mt-1 text-sm text-gray-500">Choose the type of experience this assignment pertains to.</p>
				</div>

				{/* Description */}
				<div className="mt-4">
					<label htmlFor="description" className="text-sm font-medium text-gray-700 mb-1 flex items-center">
						Description *
						<CustomTooltip content="This context about the assignment is provided to the AI assistant to help guide the reflection calls." />
					</label>
					<Field
						as="textarea"
						name="description"
						id="description"
						rows={4}
						className="mt-1 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 text-base p-2 text-gray-900"
						placeholder="This reflection assignment is part of our AI Ethics Program. Students will engage in 6 sessions throughout the course, each focusing on a different aspect of AI ethics. These reflections will encourage critical thinking about the ethical implications of AI in various contexts, including healthcare, finance, and social media."
					/>
					<p className="mt-1 text-sm text-gray-500">The contents of this section will appear in the student's initial enrolment email.</p>
					<ErrorMessage name="description" component="div" className="mt-1 text-sm text-red-600" />
				</div>
			</Panel>

			{!isEditMode && (
				<Panel title="Course Information">
					<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
						<div>
							<label htmlFor="courseName" className="text-sm font-medium text-gray-700 mb-1 flex items-center">
								Course Name *
								<CustomTooltip content="This name is used to organize course assignments at scale." />
							</label>
							<Field
								type="text"
								name="courseName"
								id="courseName"
								className="mt-1 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 text-base p-2 text-gray-900"
								placeholder="Introduction to Artificial Intelligence"
							/>
							<ErrorMessage name="courseName" component="div" className="mt-1 text-sm text-red-600" />
						</div>

						<div>
							<label htmlFor="courseCode" className="text-sm font-medium text-gray-700 mb-1 flex items-center">
								Course Code *
								<CustomTooltip content="The unique identifier is used to organize course assignments at scale. The code is not displayed to students." />
							</label>
							<Field
								type="text"
								name="courseCode"
								id="courseCode"
								className="mt-1 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 text-base p-2 text-gray-900"
								placeholder="CS301"
							/>
							<ErrorMessage name="courseCode" component="div" className="mt-1 text-sm text-red-600" />
						</div>

						<div>
							<label htmlFor="courseTerm" className="text-sm font-medium text-gray-700 mb-1 flex items-center">
								Course Term *
								<CustomTooltip content="The academic term or semester when the course is offered. This helps in organizing assignments chronologically and by academic period." />
							</label>
							<Field
								type="text"
								name="courseTerm"
								id="courseTerm"
								className="mt-1 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 text-base p-2 text-gray-900"
								placeholder="Fall 2024"
							/>
							<ErrorMessage name="courseTerm" component="div" className="mt-1 text-sm text-red-600" />
						</div>

						<div>
							<label htmlFor="courseSection" className="text-sm font-medium text-gray-700 mb-1 flex items-center">
								Course Section
								<CustomTooltip content="Optional: The specific section of the course. Useful for courses with multiple sections to differentiate assignments and student groups." />
							</label>
							<Field
								type="text"
								name="courseSection"
								id="courseSection"
								className="mt-1 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 text-base p-2 text-gray-900"
								placeholder="A01"
							/>
							<ErrorMessage name="courseSection" component="div" className="mt-1 text-sm text-red-600" />
						</div>
					</div>

					<div>
						<label htmlFor="estimatedStudents" className="text-sm font-medium text-gray-700 mb-1 flex items-center">
							Estimated Number of Students
							<CustomTooltip content="An approximate count of students expected to enroll. This used to estimate the service hours for this assignment." />
						</label>
						<Field
							type="number"
							name="estimatedStudents"
							id="estimatedStudents"
							className="mt-1 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 text-base p-2 text-gray-900"
							placeholder="30"
						/>
						<ErrorMessage name="estimatedStudents" component="div" className="mt-1 text-sm text-red-600" />
					</div>
				</Panel>
			)}
			<Panel title="Competencies">
				<p className="text-sm text-gray-600 mb-4">
					Select or Create a minimum of 1 and a maximum of {MAX_COMPETENCIES} competencies for the student to focus on
				</p>
				<div className="space-y-4">
					{Object.entries(competenciesV2).map(([category, competencies]) => (
						<div key={category}>
							<h4 className="font-medium text-gray-800 mb-2">{category}</h4>
							<div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
								{competencies
									.sort((a, b) => (a.name === "Custom" ? 1 : b.name === "Custom" ? -1 : 0))
									.map((competency) => (
										<div key={competency.id} className="flex items-center">
											<div
												className={`flex justify-between items-center w-full px-4 py-2 rounded-md text-sm font-medium ${
													selectedCompetencies.includes(competency.name)
														? "bg-[#00a9af] text-white"
														: "bg-gray-100 text-gray-700 hover:bg-gray-200"
												} ${
													selectedCompetencies.length === MAX_COMPETENCIES &&
													!selectedCompetencies.includes(competency.name)
														? "opacity-50 cursor-not-allowed"
														: "cursor-pointer"
												}`}
												onClick={() => {
													if (
														!isEditMode &&
														(selectedCompetencies.length < MAX_COMPETENCIES ||
															selectedCompetencies.includes(competency.name))
													) {
														handleCompetencyToggle(competency.name);
													}
												}}
											>
												<span className="flex-grow">{competency.name}</span>
												<button
													type="button"
													onClick={(e) => {
														e.stopPropagation();
														handleCompetencyInfo(
															competency.name,
															competency.description || "",
															competency.examples || ""
														);
													}}
													className={`ml-2 ${
														selectedCompetencies.includes(competency.name) ? "text-white" : "text-gray-500"
													} hover:text-[#00a9af]`}
												>
													<InformationCircleIcon className="h-4 w-4" />
												</button>
											</div>
										</div>
									))}
							</div>
						</div>
					))}
					{/* Custom Competency Section */}
					{isAddingCustom ? (
						<div className="mt-4 p-4 border rounded-md">
							<label htmlFor="customName" className="block text-sm font-medium text-gray-700 mb-1">
								Custom Competency Name *
							</label>
							<input
								type="text"
								id="customName"
								placeholder="e.g., Team Leadership"
								value={customCompetency.name}
								onChange={(e) => setCustomCompetency({ ...customCompetency, name: e.target.value })}
								className="mb-2 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 p-2"
							/>
							<label htmlFor="customDescription" className="block text-sm font-medium text-gray-700 mb-1">
								Custom Competency Description *
							</label>
							<textarea
								id="customDescription"
								placeholder="e.g., Ability to lead and inspire a team towards common goals."
								value={customCompetency.description}
								onChange={(e) => setCustomCompetency({ ...customCompetency, description: e.target.value })}
								className="mb-2 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 p-2"
							/>
							<label htmlFor="customExamples" className="block text-sm font-medium text-gray-700 mb-1">
								Custom Competency Examples *
							</label>
							<textarea
								id="customExamples"
								placeholder="e.g., - Lead team meetings\n- Delegate tasks effectively\n- Provide constructive feedback"
								value={customCompetency.examples}
								onChange={(e) => setCustomCompetency({ ...customCompetency, examples: e.target.value })}
								className="mb-2 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 p-2"
							/>
							<Button
								type="button"
								onClick={handleCustomSubmit}
								variant="secondary"
								className="mr-2"
								disabled={!customCompetency.name || !customCompetency.description || !customCompetency.examples}
							>
								Add
							</Button>
							<Button type="button" onClick={() => setIsAddingCustom(false)} variant="secondary">
								Cancel
							</Button>
						</div>
					) : (
						<>
							{selectedCompetencies.length < MAX_COMPETENCIES && !isEditMode && (
								<Button type="button" onClick={() => setIsAddingCustom(true)} variant="secondary" size="sm" className="mt-2">
									Add Custom Competency
								</Button>
							)}
							{selectedCompetencies.length >= MAX_COMPETENCIES && (
								<Button
									type="button"
									onClick={() => setIsAddingCustom(true)}
									variant="secondary"
									size="sm"
									className="mt-2 opacity-50 cursor-not-allowed"
									disabled
								>
									Add Custom Competency
								</Button>
							)}
						</>
					)}
				</div>
			</Panel>

			{/* Description Modal */}
			<Dialog open={isDescriptionOpen} onOpenChange={setIsDescriptionOpen}>
				<DialogContent>
					<DialogTitle>{selectedDescription?.name} Description</DialogTitle>
					<div>
						<h4 className="font-semibold">Description</h4>
						<p>{selectedDescription?.description}</p>
						<h4 className="font-semibold mt-4">Examples</h4>
						<div className="max-h-40 overflow-y-auto">
							<ul className="list-disc list-inside">
								{selectedDescription?.examples.split("\n").map((example, index) => (
									<li key={index}>{example.replace("- ", "")}</li>
								))}
							</ul>
						</div>
					</div>
					<DialogClose asChild>
						<Button variant="secondary" className="mt-4">
							Close
						</Button>
					</DialogClose>
				</DialogContent>
			</Dialog>

			{/* Navigation buttons - visible only on larger screens */}
			<div className="hidden md:flex justify-end mt-8">
				<Button type="button" onClick={handleNext} disabled={!isFormValid()}>
					Next
				</Button>
			</div>
		</div>
	);
};

// Panel component for grouping related form fields
const Panel: React.FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => (
	<div className="bg-white border border-[#eaecf0] rounded-lg p-6">
		<h3 className="text-lg font-semibold text-gray-800 mb-4">{title}</h3>
		<div className="space-y-4">{children}</div>
	</div>
);

export default BasicInformation;
