import { useEffect, useState, useRef, useCallback } from "react";
import { io, Socket } from "socket.io-client";
export enum SocketStatus {
	CONNECTED = "connected",
	DISCONNECTED = "disconnected",
	CONNECTING = "connecting",
	ERROR = "error"
}

const useSocket = ({ url }: { url: string }) => {
	const socketRef = useRef<Socket | null>(null);
	const [status, setStatus] = useState<SocketStatus>(SocketStatus.CONNECTING);

	useEffect(() => {
		let isMounted = true;

		const initSocket = () => {
			if (!socketRef.current) {
				const socketInstance = io(url, { transports: ["websocket"] });
				socketRef.current = socketInstance;

				socketInstance.on("connect", () => {
					if (isMounted) {
						setStatus(SocketStatus.CONNECTED);
						console.log("Connected to socket server");
					}
				});

				socketInstance.on("error", (error) => {
					if (isMounted) {
						setStatus(SocketStatus.ERROR);
						console.error("Error connecting to socket server", error);
					}
				});

				socketInstance.on("connect_error", (error) => {
					if (isMounted) {
						setStatus(SocketStatus.ERROR);
						console.error("Connection error:", error);
					}
				});

				socketInstance.on("disconnect", () => {
					if (isMounted) {
						setStatus(SocketStatus.DISCONNECTED);
						console.log("Disconnected from socket server");
					}
				});

				socketInstance.on("incidentStatusUpdate", (data) => {
					console.log("incidentStatusUpdate received", data);
				});
			}
		};

		initSocket();

		return () => {
			isMounted = false;
			if (socketRef.current) {
				console.log("Cleaning up socket connection");
				socketRef.current.removeAllListeners();
				socketRef.current.close();
				socketRef.current = null;
			}
		};
	}, [url]);

	const emit = useCallback(
		(eventName: string, data = {}, timeout = 5000) => {
			return new Promise((resolve, reject) => {
				if (status === SocketStatus.CONNECTED) {
					const timeoutId = setTimeout(() => {
						reject(new Error(`Socket request timed out for event: ${eventName}`));
					}, timeout);
					socketRef.current?.emit(eventName, data, (response: { status: string; error: string; [key: string]: any }) => {
						clearTimeout(timeoutId);
						// fix for arrays
						if (response && response.error) {
							console.error(response.error);
							reject(new Error(response.error));
						} else {
							// console.log("response", response);

							const { status, error, ...rest } = response;
							// console.log("rest", rest);

							let keys = Object.keys(rest);
							// console.log("keys", keys);
							if (keys.length === 1) {
								// console.log("rest is array", Array.isArray(rest[keys[0]]));
								if (Array.isArray(rest[keys[0]])) {
									//console.log("test");
									resolve(rest[keys[0]]);
								} else {
									//console.log("rest[keys[0]]", keys[0], rest[keys[0]]);
									resolve({ ...rest[keys[0]] });
								}
							} else {
								resolve({ ...rest });
							}
						}
					});
				} else {
					reject(new Error("Socket is not connected"));
				}
			});
		},
		[status]
	);

	const on = useCallback(
		(eventName: string, callback: (...args: any[]) => void) => {
			socketRef.current?.on(eventName, callback);
		},
		[status]
	);

	const off = useCallback(
		(eventName: string, callback: (...args: any[]) => void) => {
			socketRef.current?.off(eventName, callback);
		},
		[status]
	);

	const disconnect = useCallback(() => {
		socketRef.current?.disconnect();
	}, []);
	return {
		emit,
		on,
		off,
		disconnect,
		status
	};
};
export default useSocket;
